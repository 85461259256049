import React, {useEffect} from 'react';
import {Card, Dropdown, DropdownButton, Form, ListGroup} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, artApiPostAuthAsync, useAuthenticatedArtApi} from "../../hooks/artapi";
import {toast} from "react-toastify";
import {MdSend} from "react-icons/md";
import LiteGraphProcessor from "./comfyui/graphprocessor";
import {useCookies} from "react-cookie";
import {useEntitlement} from "../../hooks/entitlements";


export default function SDJobSubmitter({onClick, getPrompt, selected, setSubmitCallback, onClickHeader=() =>{}}) {
    const { getAccessTokenSilently, user } = useAuth0();
    const [ settingsExpanded, setSettingsExpanded ] = React.useState(false);
    const { isEntitled: isEntitledToLargeJobs } = useEntitlement("large-jobs");
    const [imageWidth, setImageWidth] = React.useState(512);
    const [imageHeight, setImageHeight] = React.useState(512);
    const [paramClassifier, setParamClassifier] = React.useState(7.5);
    const [numImages, setNumImages] = React.useState(4);
    const [samplingSteps, setSamplingSteps] = React.useState(50);
    const [samplingMethod, setSamplingMethod] = React.useState("k_lms");
    const [upscale, setUpscale] = React.useState(false);
    const [fixFaces, setFixFaces] = React.useState(false);
    const [presets, setPresets] = React.useState([]);
    const [preset, setPreset] = React.useState(undefined);
    const [fields, setFields] = React.useState({});
    const [savedState, setCookie, removeCookie] = useCookies(['sdjobsubmitter-settings']);
    const [checkpoints, setCheckpoints] = React.useState({});

    useEffect(() => {
        if(savedState.preset && presets) {
            // Find preset with name savedState.preset
            const preset = presets.find(p => p.name === savedState.preset);
            setPreset(preset);
        }
    }, ['savedState', presets]);


    useEffect(() => {
        async function loadPresets() {
            try {
                const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
                const response = await artApiFetchAuthAsync(token, "stable-diffusion/list-presets");
                setPresets(response);

                const ckpts = await artApiFetchAuthAsync(token, "comfyui/checkpoints");
                if("error" in ckpts) {
                    console.log("Error loading checkpoints: ", ckpts.error);
                } else {
                    setCheckpoints(ckpts);
                }
            } catch (e) {
                toast.error("Failed to load presets: " + e.message);
            }
        }
        loadPresets();
    }, []);

    useEffect(() => {
        // Grab any dynamic preset fields indicated by $(field:default) or $(field) in the preset
        // and add them to the fields object
        if(preset) {
            const presetJson = preset.preset;
            const presetFields = {};
            const matches = presetJson.match(/\$\((\w+)(?::([^)]+))?\)/g);
            if(matches) {
                for(const match of matches) {
                    const matches = match.match(/\$\((\w+)(?::([^)]+))?\)/);
                    if(matches[2]) {
                        presetFields[matches[1]] = matches[2];
                    } else {
                        presetFields[matches[1]] = "";
                    }
                }
            }
            console.log("Fields: ", presetFields);
            setFields(presetFields);
        }
    }, [preset]);

    if(setSubmitCallback) setSubmitCallback(submitPrompt);

    function replaceVariables(str, fields) {
        // Extract axpect ration from --ar n:m in the string and remove it
        const arMatch = str.match(/--ar\s+(\d+):(\d+)/);
        if(arMatch) {
            const ar = parseFloat(arMatch[1]) / parseFloat(arMatch[2]);
            str = str.replace(/--ar\s+\d+:\d+/, "");
            // If fields have width and height recalculate the width and height based on ar otherwise default to 512

            let width = parseInt(fields.width) || 512
            let height = parseInt(fields.height) || 512;

            width = Math.min(width, height);
            // If ar width is greater than height make the width bigger
            if (width / height > ar) {
                fields.width = width;
                fields.height = Math.round(width / ar);
            } else {
                fields.height = height;
                fields.width = Math.round(height * ar);
            }

            console.log("Resetting aspect ratio: ", fields.width, fields.height);
            console.log(str);
        }

        // Replace the string '$(seed:random)' with a random number
        str = str.replace(/\$\((seed:random)\)/g, Math.floor(Math.random() * 1000000000));

        // Match all variables in format $(name) or $(name:defaultValue)
        const matches = str.match(/\$\(([^)]+)\)/g) || [];

        for (let i = 0; i < matches.length; i++) {
            const match = matches[i];
            let [name, defaultValue] = match.slice(2, -1).split(':');  // Remove $() and split into name and defaultValue

            // If fields contains this name, use its value. Otherwise, use defaultValue.
            const replacement = fields.hasOwnProperty(name) ? fields[name] : defaultValue;

            if(replacement && replacement !== "undefined") {
                // Replace in the original string
                str = str.replace(match, replacement || '');
            } else {
                str = str.replace(match, '');
            }
        }

        return str;
    }

    function preparePrompt(prompt) {
        const promptFields = {...fields};
        const promptVariables = prompt.split("--");
        prompt = promptVariables[0];
        for(let i=1; i<promptVariables.length; i++) {
            const variable = promptVariables[i];
            const [name, value] = variable.split(" ", 2);
            promptFields[name] = value;
        }

        // Split prompt by .001 and use only the value after the .001 if it was there
        const promptSplit = prompt.split("::.001 ");
        if (promptSplit.length > 1) {
            prompt = promptSplit[1];
            // Trim leading space if present
            if (prompt.startsWith(" ")) prompt = prompt.substring(1);
            console.log("Prompt: ", promptSplit[0]);
            const metadata = JSON.parse(promptSplit[0]);

            console.log("Metadata: ", metadata);

            if(!promptFields['set']) promptFields['set'] = metadata.set;
            if(!promptFields['title']) promptFields['title'] = metadata.title;
            if(!promptFields['alt']) promptFields['alt'] = metadata.alt;
            if(!promptFields['caption']) promptFields['caption'] = metadata.caption;
            if(!promptFields['tags']) promptFields['tags'] = metadata.tags.join(",");
            else promptFields['tags'] += "," + metadata.tags.join(",");
        }

        prompt = replaceVariables(prompt, promptFields);

        return {prompt, promptFields};
    }

    async function preparePresetPrompt(prompt, preset) {
        const presetInstance = {...preset};
        let {prompt: preparedPrompt, promptFields} = preparePrompt(prompt);
        prompt = preparedPrompt;
        promptFields['prompt'] = prompt;

        // replace variables in preset.preset with values from fields
        const presetJson = JSON.parse(replaceVariables(presetInstance.preset, promptFields));

        console.log('Submitting preset as ', user)
        presetJson.prompt = prompt;
        presetJson.author = {
            'id': user.email,
            'display_name': user.nickname,
            'mention': '',
            'avatar': {
                'url': user.picture
            }
        };
        try {
            presetJson.workflow = presetJson.data;
            presetJson.data = presetJson.compiledPrompt ? presetJson.compiledPrompt :
                await new LiteGraphProcessor(presetJson.data).graphToPrompt();

            let rawJson = JSON.stringify(presetJson);

            for (const [key, value] of Object.entries(promptFields)) {
                let v = key === "prompt" ? prompt : value;
                v = JSON.stringify({'v': v}).substring(6).slice(0, -2);
                // Trim leading quotes if present
                if (v.startsWith('"')) v = v.substring(1);
                // Trim trailing quotes if present
                if (v.endsWith('"')) v = v.slice(0, -1);

                rawJson = rawJson.replaceAll("$(" + key + ")", v);
            }

            // Replace any remaining $(field) with empty string
            return JSON.parse(rawJson.replace(/\$\(\w+\)/g, ""));
        } catch (e) {
            return undefined;
        }
    }

    async function submitPrompt(prompt=undefined, showToast=true) {
        if (!prompt || prompt.length == 0) {
            toast.error("Please enter a prompt.", {autoClose: true, error: true});
            return;
        }

        try {
            if (preset) {
                let prompts = [];

                if(Array.isArray(prompt)) {
                    // Iterate over all prompts and prepare them
                    for (const p of prompt) {
                        console.log("Preparing prompt: ", p);
                        var preppedPrompt = await preparePresetPrompt(p, preset);
                        prompts.push(preppedPrompt);
                    }
                } else {
                    console.log("Submitting prompt: ", prompt);
                    var preppedPrompt = await preparePresetPrompt(prompt, preset);
                    prompts.push(preppedPrompt);
                }

                let toasthandle;
                if(prompts.length === 0) {
                    toast.error("Please enter a prompt.", {autoClose: true, error: true});
                    return;
                } else if (prompts.length === 1) {
                    if (showToast) toasthandle = toast(`Submitting stable diffusion job: ${prompt}`);
                } else {
                    if (showToast) toasthandle = toast(`Submitting ${prompts.length} stable diffusion jobs...`);
                }

                const rawJson = JSON.stringify(prompts);

                try {
                    const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
                    const job = await artApiPostAuthAsync(token, "jobs-v2/submit", rawJson,
                        "request=" + encodeURIComponent("/txt2img"),
                        "type=" + preset.type);
                    if (showToast && prompts.length === 1) {

                        toast.update(toasthandle, {
                            type: toast.TYPE.SUCCESS,
                            autoClose: true,
                            render: "Submitted stable diffusion job: " + prompt
                        });
                    } else {
                        toast.update(toasthandle, {
                            type: toast.TYPE.SUCCESS,
                            autoClose: true,
                            render: "Submitted " + prompts.length + " stable diffusion jobs."
                        });
                    }
                    return job;
                } catch (e) {
                    console.error(e);
                    if (showToast) toast.update(toasthandle, {
                        type: toast.TYPE.ERROR,
                        autoClose: true,
                        render: "Error submitting job: " + e
                    });
                }
            } else {
                // If the prompt is an array of prompts, submit each one
                if(Array.isArray(prompt)) {
                    // If prompt lenghth is 1 just submit it
                    if(prompt.length === 1) {
                        prompt = prompt[0];
                    } else {
                        for (const p of prompt) {
                            await submitPrompt(p, false);
                        }
                        toast.success("Submitted all prompts.", {autoClose: true});
                        return;
                    }
                }

                let toasthandle;
                if (showToast) toasthandle = toast(`Submitting stable diffusion job: ${prompt}`);

                const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});

                let {prompt: preparedPrompt, promptFields} = preparePrompt(prompt);
                prompt = preparedPrompt;
                if (!prompt || prompt.length == 0) {
                    toast.error("Please enter a prompt.", {autoClose: true, error: true});
                    return;
                }

                console.log("Submitting ", prompt);
                try {
                    await artApiFetchAuthAsync(token, "txt2img",
                        "prompt=" + encodeURI(prompt),
                        "width=" + imageWidth,
                        "height=" + imageHeight,
                        "cfg_scale=" + paramClassifier,
                        "n_iter=" + numImages,
                        "ddim_steps=" + samplingSteps,
                        "sampler_name=" + samplingMethod,
                        "upscale=" + upscale,
                        "fix_faces=" + fixFaces);
                    if (showToast) toast.update(toasthandle, {
                        type: toast.TYPE.SUCCESS,
                        autoClose: true,
                        render: "Submitted stable diffusion job: " + prompt
                    })
                } catch (e) {
                    console.error(e);
                    if (showToast) toast.update(toasthandle, {
                        type: toast.TYPE.ERROR,
                        autoClose: true,
                        render: "Error submitting job: " + e
                    });
                }
            }
        } catch (e) {
            console.error(e);
            toast.error("Error submitting job: " + e, {autoClose: true, error: true});
        }
    }


    function submitJob() {
        var prompt = getPrompt();
        if(!prompt || prompt.length == 0) {
            toast("Please enter a prompt.", {autoClose: true, error: true});
            return;
        }

        console.log("Submit job: ", prompt);
        submitPrompt(prompt);
    }

    function applyPreset(preset) {
        console.log("Applying preset: ", preset);
        setPreset(preset);
        setCookie("preset", preset.name);
    }

    function handleCheckpointSelect(fieldName, key) {
        const newFields = {...fields};
        newFields[fieldName] = key;
        setFields(newFields);
    }

    return (
        <Card  >
            <Card.Header onClick={() => {
                console.log("Clicked on SD");
                onClickHeader();
            }} >
                <div className={"create-card-flex-between-container"}>
                    <div >
                        <span style={{cursor: "pointer", fontWeight: selected ? "bold" : "normal"}} onClick={() => {
                            setSettingsExpanded(!settingsExpanded);
                        }}>Stable Diffusion</span>
                        <br/>
                        {!settingsExpanded && preset && <span style={{fontSize: 8}}>{preset.name}</span>}
                    </div>
                    <MdSend className={"mdicon"} onClick={() => onClick ? onClick() : submitJob()}  style={{cursor: "pointer"}} />
                </div>
            </Card.Header>
            {settingsExpanded && <div>
                <div className={"create-card-flex-between-container"}>
                    <Dropdown className={"create-card-flex-between-items-grow"}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {preset?.name ?? "Automatic 1111"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="default" onClick={() => applyPreset(null)}>Automatic 1111</Dropdown.Item>
                            {presets.map(preset => (<Dropdown.Item
                                eventKey={preset.id}
                                key={preset.id}
                                onClick={(e) => {
                                    applyPreset(preset);
                                }}
                            >{preset.name}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                {preset &&<div>
                    <ListGroup variant="flush">
                        {Object.keys(fields).map(fieldName => {
                            switch(fieldName) {
                                case "prompt":
                                    return null;
                                case "checkpoint":
                                    return (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                {fields[fieldName] || "Select Model"}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {Object.keys(checkpoints).map(key => (
                                                    <Dropdown.Item
                                                        key={key}
                                                        onClick={() => handleCheckpointSelect(fieldName, key)}
                                                        active={fields[fieldName] === key}
                                                    >
                                                        {key}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )
                                default:
                                    return (
                                        <ListGroup.Item>
                                            {fieldName}: <input type={"text"} value={fields[fieldName]} onChange={(e) => {
                                            const newFields = {...fields};
                                            newFields[fieldName] = e.target.value;
                                            setFields(newFields);
                                        }}/>
                                        </ListGroup.Item>
                                    );
                            }
                        })}
                    </ListGroup>
                </div>}

                {!preset && <ListGroup variant="flush">
                <ListGroup.Item>
                    <p>Images: {numImages}</p>
                    {isEntitledToLargeJobs ?
                        <input type={"range"} min={1} max={100} value={numImages} step={1} onChange={(e) => setNumImages(e.target.value)} /> :
                        <input type={"range"} min={1} max={10} value={numImages} step={1} onChange={(e) => setNumImages(e.target.value)} />}

                </ListGroup.Item>
                <ListGroup.Item>
                    <p>Width: {imageWidth}</p>
                    <input type={"range"} min={64} max={1024} value={imageWidth} step={64} onChange={(e) => setImageWidth(e.target.value)} />
                </ListGroup.Item>
                <ListGroup.Item>
                    <p>Height: {imageHeight}</p>
                    <input type={"range"} min={64} max={1024} value={imageHeight} step={64} onChange={(e) => setImageHeight(e.target.value)} />
                </ListGroup.Item>
                <ListGroup.Item>
                    <p>Classifier Free Guidance Scale: {paramClassifier}</p>
                    <input type={"range"} min={-30} max={40} value={paramClassifier} step={.5} onChange={(e) => setParamClassifier(e.target.value)} />
                </ListGroup.Item>
                <ListGroup.Item>
                    <p>Sampling Steps: {samplingSteps}</p>
                    <input type={"range"} min={1} max={250} value={samplingSteps} step={1} onChange={(e) => setSamplingSteps(e.target.value)} />
                </ListGroup.Item>
                <ListGroup.Item>
                    <p>Sampling Method: {samplingMethod}</p>
                    <DropdownButton id="dropdown-item-button" title={samplingMethod}>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_heun')}>k_lms</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('DDIM')}>DDIM</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('PLMS')}>PLMS</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_lms')}>k_lms</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_dpm_2_a')}>k_dpm_2_a</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_dpm_2')}>k_dpm_2</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_euler_a')}>k_euler_a</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_euler')}>k_euler</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setSamplingMethod('k_heun')}>k_heun</Dropdown.Item>
                    </DropdownButton>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Form>
                        <Form.Check onChange={(e) => setFixFaces(e.target.checked)} checked={fixFaces} type="checkbox" label="Fix Faces" />
                        <Form.Check onChange={(e) => setUpscale(e.target.checked)} checked={upscale} type="checkbox" label="Upscale" />
                    </Form>
                </ListGroup.Item>
            </ListGroup>}
            </div>}
        </Card>)
}