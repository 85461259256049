import React from 'react';
import {Handle, Position} from 'reactflow';

import './comfyui.css';

const ComfyUINode = ({ data, isConnectable }) => {
    const nodeSpace = 20;
    //console.log("ComfyUINode", data);

    function fields() {
        const inputs = data.node.inputs ? data.node.inputs : [];
        const outputs = data.node.outputs ? data.node.outputs : [];
        const widgets = data.node.widgets_values ? data.node.widgets_values : [];
        const rows = [];
        const count = Math.max(inputs.length, outputs.length, widgets.length);
        for (let i = 0; i < count; i++) {
            rows.push(
                <tr key={i}>
                    <td className={"comfy-input"}>
                        {i < inputs.length && <span>{inputs[i].name}</span>}</td>
                    <td className={"comfy-data"}>
                        {i < widgets.length && <span>{widgets[i]}</span>}</td>
                    <td className={"comfy-output"} style={{ textAlign: 'right' }}>
                        {i < outputs.length && <span>{outputs[i].name}</span>}
                    </td>
                </tr>
            );
        }

        return (
            <table className={"comfy-table"}>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }


    return (
        <>
            {data.node.inputs && data.node.inputs.map((input, index) => {
                return null != input.link ?
                    <Handle
                        type="target"
                        key={index}
                        id={`input-${index}`}
                        position={Position.Left}
                        style={{ top: (nodeSpace - 2) * index  + 28, background: '#555' }}
                        onConnect={(params) => console.log('handle onConnect', params)}
                        isConnectable={null !== input.link}
                    /> :
                    <div
                        style={{ top: (nodeSpace - 2) * index  + 28, background: '#555' }}/>
            })}
            <div style={{textAlign: "center"}}>
                {data.node.properties['Node name for S&R']}
            </div>

            {fields()}

            {data.node.outputs && data.node.outputs.map((output, index) => {
                return <Handle
                    type="source"
                    position={Position.Right}
                    id={`output-${index}`}
                    style={{ top: (nodeSpace - 2) * index  + 28, background: '#555', textAlign: "right" }}
                    isConnectable={isConnectable}
                />
            })}
        </>
    );
}

export default ComfyUINode;
