import React, { useState, useEffect, useRef } from 'react';
import { LiteGraph, LGraphCanvas, LGraph } from "litegraph.js";
import "./litegraph.css";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import DataNodes from "./nodes/datanodes";
import { artApiFetchAuthAsync } from "../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import {ComfyWidgets as widgets} from "./widgets/comfywidgets";
import RerouteNode from "./nodes/reroutenode";
import NoteNode from "./nodes/notenode";

function LiteGraphComponent({ width, height, data }) {
    const { getAccessTokenSilently, user } = useAuth0();
    const containerRef = useRef(null);
    const [graph, setGraph] = useState(null);
    const [canvas, setCanvas] = useState(null);


    async function registerNodesFromDefs(defs) {
        // Register a node for each definition
        for (const nodeId in defs) {
            const nodeData = defs[nodeId];
            const node = Object.assign(
                function ComfyNode() {
                    const app = {graph: graph, canvas: canvas};
                    var inputs = nodeData["input"]["required"];
                    if (nodeData["input"]["optional"] != undefined){
                        inputs = Object.assign({}, nodeData["input"]["required"], nodeData["input"]["optional"])
                    }
                    const config = { minWidth: 1, minHeight: 1 };
                    for (const inputName in inputs) {
                        const inputData = inputs[inputName];
                        const type = inputData[0];

                        let widgetCreated = true;
                        if (Array.isArray(type)) {
                            // Enums
                            Object.assign(config, widgets.COMBO(this, inputName, inputData, app) || {});
                        } else if (`${type}:${inputName}` in widgets) {
                            // Support custom widgets by Type:Name
                            Object.assign(config, widgets[`${type}:${inputName}`](this, inputName, inputData, app) || {});
                        } else if (type in widgets) {
                            // Standard type widgets
                            Object.assign(config, widgets[type](this, inputName, inputData, app) || {});
                        } else {
                            // Node connection inputs
                            this.addInput(inputName, type);
                            widgetCreated = false;
                        }

                        if(widgetCreated && inputData[1]?.forceInput && config?.widget) {
                            if (!config.widget.options) config.widget.options = {};
                            config.widget.options.forceInput = inputData[1].forceInput;
                        }
                    }

                    for (const o in nodeData["output"]) {
                        const output = nodeData["output"][o];
                        const outputName = nodeData["output_name"][o] || output;
                        const outputShape = nodeData["output_is_list"][o] ? LiteGraph.GRID_SHAPE : LiteGraph.CIRCLE_SHAPE ;
                        this.addOutput(outputName, output, { shape: outputShape });
                    }

                    const s = this.computeSize();
                    s[0] = Math.max(config.minWidth, s[0] * 1.5);
                    s[1] = Math.max(config.minHeight, s[1]);
                    this.size = s;
                    this.serialize_widgets = true;
                },
                {
                    title: nodeData.display_name || nodeData.name,
                    comfyClass: nodeData.name,
                }
            );
            node.prototype.comfyClass = nodeData.name;

            LiteGraph.registerNodeType(nodeId, node);
            node.category = nodeData.category;
        }
    }

    const loadData = async () => {
        if (graph) {
            const token = await getAccessTokenSilently();
            const graphData = await artApiFetchAuthAsync(token, "/node/data", "type=comfyui://object_info");
            console.log("Graph data: ", graphData);
            // If we don't have an error
            if (graphData && !graphData.error) {
                await registerNodesFromDefs(graphData);
            }

            const app = {graph: graph, canvas: canvas};
            RerouteNode.registerCustomNodes(app);
            NoteNode.registerCustomNodes(app);

            if (data) {
                graph.configure(data);
            }
        }
    };

    useEffect(() => {
        // Ensure LiteGraph is available
        const localGraph = new LGraph();
        const localCanvas = new LGraphCanvas(containerRef.current, localGraph);
        setGraph(localGraph);
        setCanvas(localCanvas);

        // ... rest of your setup code ...

        console.log("Configuring data: ", data);
        loadData();
        // Cleanup on unmount
        return () => {
            if (localCanvas) {
                localCanvas.unbindEvents();  // Hypothetical method; check actual API
            }
        };
    }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount

    useEffect(() => {
        console.log("Configuring data: ", data);
        loadData();
    }, [data]);  // This useEffect runs whenever data prop changes, similar to componentDidUpdate

    return (
        <div style={{ width, height }} className={"litegraph"}>
            <canvas width={width} height={height} ref={containerRef}>
                {/* This div will serve as the container for the LiteGraph instance */}
            </canvas>
        </div>
    );
}

export default LiteGraphComponent;
