import React, {useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {FaChevronDown, FaChevronUp} from "react-icons/all";

const CollapsibleCard = ({ title, subtitle, children, className, style, expand }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeKey, setActiveKey] = useState(expand ? '0' : null);
    const icon = activeKey === "0" ? <FaChevronUp/> : <FaChevronDown/>;

    function getFirstCollapsibleCardTitle(children) {
        const childArray = React.Children.toArray(children);

        for (let child of childArray) {
            if (React.isValidElement(child) && child.type === CollapsibleCardTitle) {
                return child;
            }
        }
        return null; // or undefined, based on your requirements
    }

    function getFirstCollapsibleCardContent(children) {
        const childArray = React.Children.toArray(children);

        for (let child of childArray) {
            if (React.isValidElement(child) && child.type === CollapsibleCardContent) {
                return child;
            }
        }
        return null; // or undefined, based on your requirements
    }

    const titleControl = getFirstCollapsibleCardTitle(children);
    const contentControl = getFirstCollapsibleCardContent(children);

    const handleToggle = () => {
        if (activeKey === '0') {
            setActiveKey(null);
        } else {
            setActiveKey('0');
        }
    };

    function handleSelect(eventKey) {
        setActiveKey(eventKey)
    }

    return (
        <div className={`card ${className}`} style={style}>
            <Accordion activeKey={activeKey} onSelect={handleSelect}>
                <Accordion.Item eventKey={"0"}>
                    <div className="card-header" id="headingOne" style={{"cursor": "pointer"}} onClick={() => handleToggle()}>
                        {titleControl ? titleControl : <>
                                <div className={"create-card-flex-between-container"}>
                                    <span style={{marginRight: '10px'}}>{title}</span>
                                    {icon}
                                </div>
                                {subtitle && <div className={"collapsable-card-subtitle"}>{subtitle}</div>}
                            </>
                        }
                    </div>

                    <Accordion.Body className="card-body">
                        {contentControl ? contentControl : children}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

const CollapsibleCardTitle = ({ children }) => {
    return (
        <Card.Title>
            {children}
        </Card.Title>
    );
};

const CollapsibleCardContent = ({ children }) => {
    return (
        <Card.Body>
            {children}
        </Card.Body>
    );
};

export { CollapsibleCard, CollapsibleCardTitle, CollapsibleCardContent };
