import React, { useState, useEffect, useCallback } from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAsync, artApiFetchAuthAsync, artApiPostAsync, artApiPostAuthAsync} from "../../../hooks/artapi";
import {useIndexDB} from "../../providers/indexdb";

function useAgentChat({agent = null, agentKey = null, sessionKey = undefined}) {
    const [ messages, setMessages ] = React.useState([]);
    const { getAccessTokenSilently, user } = useAuth0();
    const [ messageCount, setMessageCount ] = React.useState(0);
    const { getValue, setValue } = useIndexDB();
    const [ currentAgent, setAgent ] = React.useState(agent);
    const [ currentAgentKey, setAgentKey ] = React.useState(agentKey);

    function getStorageKey() {
        let key = "agent-chat::" + currentAgent.name;
        if(sessionKey) key += "::" + sessionKey;
        console.log("Got storage key: ", key);
        return key;
    }

    useEffect(() => {}, [messages]);

    useEffect( () => {
        async function getMessages() {
            const messages = await getValue(getStorageKey(), []);
            if (messages) {
                setMessages(messages);
                setMessageCount(messages.length);
            }
        }

        getMessages();
    }, [currentAgent, currentAgentKey, sessionKey]);

    function submitPrompt(promptValue) {
        messages.push({
            content: promptValue,
            sentTime: new Date().toUTCString(),
            sender: "You",
            direction: "outgoing"
        });
        setMessages(messages);
        setMessageCount(messageCount + 1);
        //saveCookie();

        console.log('Prompt:', promptValue,messages);
        async function fetchResponse() {
            await setValue(getStorageKey(), messages);
            if(!currentAgent) return;
            if(!promptValue) return;

            console.log("Prompt: ", promptValue);

            let messageResponse;
            if(currentAgentKey) {
                messageResponse = await artApiPostAsync("gpt/agent", JSON.stringify({"prompt": promptValue}),"key=" + currentAgentKey, "agent=" + currentAgent.name, "namespace=" + currentAgent.namespace);
            } else {
                let token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
                // url component encode the prompt value
                messageResponse = await artApiPostAuthAsync(token, "gpt/agent", JSON.stringify({"prompt": promptValue}), "agent=" + currentAgent.name, "namespace=" + currentAgent.namespace);
            }
            if(messageResponse) {
                //setResponseValue(messageResponse.content);
                messages.push({
                    content: messageResponse.content,
                    error: messageResponse.error,
                    sentTime: new Date().toUTCString(),
                    sender: currentAgent.name,
                    direction: "incoming"
                });
                setMessages(messages);
                setMessageCount(messageCount + 2);
                //saveCookie();
                await setValue(getStorageKey(), messages);
            }
        }

        fetchResponse();
    }

    return { submitPrompt, setAgent, setAgentKey, messages, messageCount };
}

export default useAgentChat;