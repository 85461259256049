import React, { createContext, useContext, useEffect, useState } from 'react';

const IndexDBContext = createContext();

export const useIndexDB = () => {
    return useContext(IndexDBContext);
};

export const IndexDBProvider = ({ children }) => {
    const [db, setDb] = useState(null);
    const [dbPromise, setDbPromise] = useState(new Promise((resolve, reject) => {
        const request = indexedDB.open("doubtech.ai", 1);

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('items')) {
                db.createObjectStore('items');
            }
        };

        request.onsuccess = function(event) {
            setDb(event.target.result);
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            console.error("IndexDB error:", event.target.errorCode);
            reject(event.target.errorCode);
        };
    }));

    useEffect(() => {
        async function fetchData() {
            const db = await dbPromise;
            setDb(db);
        }

        fetchData();
    }, []);

    const getValue = async (key, defaultValue=undefined) => {
        const currentDb = db || await dbPromise;

        return new Promise((resolve, reject) => {
            const transaction = currentDb.transaction(["items"]);
            const objectStore = transaction.objectStore("items");
            const request = objectStore.get(key);

            request.onsuccess = function(event) {
                resolve(request.result ? request.result : defaultValue);
            };

            request.onerror = function(event) {
                reject("Unable to retrieve data from database!");
            };
        });
    };

    const setValue = async (key, value) => {
        const currentDb = db || await dbPromise;

        return new Promise((resolve, reject) => {
            const transaction = currentDb.transaction(["items"], "readwrite");
            const objectStore = transaction.objectStore("items");
            const request = objectStore.put(value, key);

            request.onsuccess = function(event) {
                resolve(true);
            };

            request.onerror = function(event) {
                reject("Unable to add data to database!");
            };
        });
    };

    const contextValue = {
        getValue,
        setValue
    };

    return (
        <IndexDBContext.Provider value={contextValue}>
            {children}
        </IndexDBContext.Provider>
    );
};
