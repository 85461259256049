import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';

const AutoScrollingDiv = forwardRef(({ children, height, autoscroll=true }, ref) => {
    const divRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(true);

    const triggerScrollToBottom = () => {
        setShouldScroll(true);
    };

    // Expose the triggerScrollToBottom function to parent through the ref
    useImperativeHandle(ref, () => ({
        scrollToBottom: triggerScrollToBottom
    }));

    useEffect(() => {
        if(!autoscroll) {
            // Set a timer to trigger after 1 second to set scroll to false
            // This is to prevent the scroll from resetting when the user is scrolling
            // and the div is updated
            setTimeout(() => {
                setShouldScroll(false);
            }, 1000);
        }
    }, [autoscroll, shouldScroll]);

    useEffect(() => {
        if (shouldScroll) {
            const divElement = divRef.current;
            divElement.scrollTop = divElement.scrollHeight;
        }
    }, [children]);

    return (
        <div
            ref={divRef}
            style={{ overflow: 'auto', height: height }}
        >
            {children}
        </div>
    );
});

export default AutoScrollingDiv;
