import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {CollapsibleCard, CollapsibleCardContent} from "../CollapsableCard";

const initialData = {
    prompt: ["{cast[2]} is a {cast[0]} named {cast[1]} from Dungeons and dragons in the style of John Francis Daley and Jonathan Goldstein, cinematic, 35mm {aspect-ratio} --v 4 "],
    fields: { cast: "DND Cast", "aspect-ratio": ["--ar 3:4", "--ar 1:2"] },
    metadata: {
        set: "timestamp()",
        tags: ["dungeons and dragons", "dnd", "{cast[0]}", "{cast[1]}", "{cast[2]}"],
        title: "{cast[1]}",
        caption: "{cast[0]} {cast[1]}",
        alt: "A {cast[0]} from Dungeons and Dragons",
    },
};

export const LabelDataForm = ({data, setData}) => {
    return (<CollapsibleCard title={"Labels"}>
        <CollapsibleCardContent>
            <Form.Group controlId="title">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                    value={data?.metadata?.title ?? ""}
                    onChange={e => {data.metadata.title = e.target.value; setData(data);}}
                />
            </Form.Group>
            <Form.Group controlId="caption">
                <Form.Label>Caption:</Form.Label>
                <Form.Control
                    value={data?.metadata?.caption ?? ""}
                    onChange={e => {data.metadata.caption = e.target.value; setData(data);}}
                />
            </Form.Group>
            <Form.Group controlId="alt">
                <Form.Label>Alt:</Form.Label>
                <Form.Control
                    value={data?.metadata?.alt ?? ""}
                    onChange={e => {data.metadata.alt = e.target.value; setData(data);}}
                />
            </Form.Group>
            <Form.Group controlId="set">
                <Form.Label>Set:</Form.Label>
                <Form.Control
                    value={data?.metadata?.set ?? ""}
                    onChange={e => {data.metadata.set = e.target.value; setData(data);}}
                />
            </Form.Group>
        </CollapsibleCardContent>
    </CollapsibleCard>);
}

const EditDataForm = ({data, setData}) => {
    const [prompt, setPrompt] = useState(data && data.prompt && data.prompt.length > 0 ? data.prompt[0] : "");

    useEffect(() => {
        if(data && data.prompt && data.prompt.length > 0) {
            setPrompt(data.prompt[0]);
        }
    }, [data]);

    return (
        <div>
            <Form.Group controlId="prompt">
                <h6>Prompt</h6>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={prompt}
                    onChange={e => {
                        setPrompt(e.target.value);
                        data.prompt[0] = e.target.value;
                        console.log("Updating to ", data.prompt[0]);
                        setData(data);
                    }}
                />
            </Form.Group>

            <h6>Tags</h6>
            <span>
                {Array.isArray(prompt?.metadata?.tags)  && prompt.metadata.tags.map((tag, index) => (<span key={index} className={"tag"}>{tag}</span> ))}
            </span>

            <CollapsibleCard title={"Labels"}>
                <CollapsibleCardContent>
                    <Form.Group controlId="title">
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                            value={data?.metadata?.title ?? ""}
                            onChange={e => {data.metadata.title = e.target.value; setData(data);}}
                        />
                    </Form.Group>
                    <Form.Group controlId="caption">
                        <Form.Label>Caption:</Form.Label>
                        <Form.Control
                            value={data?.metadata?.caption ?? ""}
                            onChange={e => {data.metadata.caption = e.target.value; setData(data);}}
                        />
                    </Form.Group>
                    <Form.Group controlId="alt">
                        <Form.Label>Alt:</Form.Label>
                        <Form.Control
                            value={data?.metadata?.alt ?? ""}
                            onChange={e => {data.metadata.alt = e.target.value; setData(data);}}
                        />
                    </Form.Group>
                </CollapsibleCardContent>
            </CollapsibleCard>
        </div>
    );
}

export default EditDataForm;
