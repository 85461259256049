import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useEndpoint} from "./api";

export const useGptAgent = (namespace, agent, showToast=true,
    generatingText="Generating...",
    createGenerationSuccess = (content) => "Success!",
    createErrorMessage = (response) => "Error: " + response.error) => {
    const { postAuth } = useEndpoint('gpt/agent');

    async function postPrompt(promptText,
                              generatingTextOverride= undefined,
                              createGenerationSuccessOverride = undefined,
                              createErrorMessageOverride = undefined,
                              showToastOverride= undefined) {
        showToast = showToastOverride === undefined ? showToast : showToastOverride;
        generatingText = generatingTextOverride === undefined ? generatingText : generatingTextOverride;
        createGenerationSuccess = createGenerationSuccessOverride === undefined ? createGenerationSuccess : createGenerationSuccessOverride;
        createErrorMessage = createErrorMessageOverride === undefined ? createErrorMessage : createErrorMessageOverride;

        let toastHandle = null;
        if(showToast) {
            toastHandle = toast(generatingText, {type: "info"});
            toast.update(toastHandle, {autoClose: false, closeButton: true, closeOnClick: false, draggable: false, hideProgressBar: true, pauseOnHover: false, progress: undefined, type: "info"});
        }

        const messageResponse = await postAuth({
            agent,
            namespace,
            "prompt": promptText
        });
        if("error" in messageResponse)
        {
            toast.update(toastHandle, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "error", render: createErrorMessage(messageResponse)});
            return promptText;
        }

        const successText = createGenerationSuccess(messageResponse.content);
        if(showToast) {
            toast.update(toastHandle, {
                render: successText,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
                draggable: true,
                hideProgressBar: false,
                pauseOnHover: true,
                progress: undefined,
                type: "success"
            });
        }
        return messageResponse.content;
    }

    return {postPrompt}
}