import React, {Component} from 'react';
import {toast} from "react-toastify";


export function getPngMetadata(file) {
    return new Promise((r) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            // Get the PNG data as a Uint8Array
            const pngData = new Uint8Array(event.target.result);
            const dataView = new DataView(pngData.buffer);

            // Check that the PNG signature is present
            if (dataView.getUint32(0) !== 0x89504e47) {
                console.error("Not a valid PNG file");
                r();
                return;
            }

            // Start searching for chunks after the PNG signature
            let offset = 8;
            let txt_chunks = {};
            // Loop through the chunks in the PNG file
            while (offset < pngData.length) {
                // Get the length of the chunk
                const length = dataView.getUint32(offset);
                // Get the chunk type
                const type = String.fromCharCode(...pngData.slice(offset + 4, offset + 8));
                if (type === "tEXt") {
                    // Get the keyword
                    let keyword_end = offset + 8;
                    while (pngData[keyword_end] !== 0) {
                        keyword_end++;
                    }
                    const keyword = String.fromCharCode(...pngData.slice(offset + 8, keyword_end));
                    // Get the text
                    const contentArraySegment = pngData.slice(keyword_end + 1, offset + 8 + length);
                    const contentJson = Array.from(contentArraySegment).map(s=>String.fromCharCode(s)).join('')
                    txt_chunks[keyword] = contentJson;
                }

                offset += 12 + length;
            }

            r(txt_chunks);
        };

        reader.readAsArrayBuffer(file);
    });
}

class PresetLoader extends Component {
    fileInputRef = React.createRef();

    handleFileChange = (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();

        reader.onload = (e) => {
            try {
                let json = JSON.parse(e.target.result);
                this.props.onFileLoaded(json);
            } catch (error) {
                // if this.props.onError is defined and a function, call it with the error
                if (typeof this.props.onError === "function") this.props.onError(error);
                else toast.error(`Failed to load preset.\n${error}`);
            }
        };

        if(file.name.toLowerCase().endsWith(".png")) {
            getPngMetadata(file).then((txt_chunks) => {
                console.log(txt_chunks);
                this.props.onPngMetadataLoaded(txt_chunks);
            });
        } else {
            reader.readAsText(file);
        }
    }

    loadJson = () => {
        this.fileInputRef.current.click();
    }

    render() {
        return (<input
                    type="file"
                    accept=".json,.png"
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                    style={{ display: 'none' }} // hide the input
                />);
    }
}

export default PresetLoader;
