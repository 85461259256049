import React, {useEffect} from 'react';
import ReactWordcloud from "react-wordcloud";
import useArtApi, {artApiFetchAsync} from "../../hooks/artapi";
import TagList from "../components/taglist";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import ArtWordCloud from "../components/artgallery/ArtWordCloud";
import {useIndexDB} from "../providers/indexdb";
import {useNavigate} from "react-router-dom";


const TagsHome = () => {
    const headerImage = '/img/headers/tags.png'

    const { getValue, setValue } = useIndexDB();
    const [tags, setTags] = React.useState([]);
    const [words, setWords] = React.useState([]);
    const [tagCheck, setTagCheck] = React.useState("");
    const navigate = useNavigate();

    useEffect(() => {
        function update(tags) {
            const tagStr = JSON.stringify(tags);
            if(tagStr === tagCheck) return;
            setTagCheck(tagStr);

            const words = tags?.map((tag) => ({text: tag?.name, value: tag?.count}));
            // Use a maximum of 500 tags
            words.sort((a, b) => b.value - a.value);
            words.splice(500);
            setTags(tags);
            setWords(words);
        }
        async function fetch() {
            const tags = await getValue("tags", []);
            console.log("Tags: ", tags);
            update(tags);
            const response = await artApiFetchAsync("tags");
            if (response.error) {
                console.log("Error: ", response.error);
            } else {
                update(response);
                setValue("tags", response);
            }
        }

        fetch();
    }, []);

    return (
  <PageHeader image={headerImage} title={"Tags"}
              description={"A collection of AI Art grouped by popular tags."}
              breadcrumb={[
                  ["Home", "/"],
                  ["Tags", "/tags"]
              ]}
  >
      <ArtWordCloud data={words ? words : []}
                    onWordClick={(word) => {
                        navigate(`/tags/${word}`)
                    }}
      />
      <div className='col-12' style={{textAlign: "justify"}}>
          <TagList/>
      </div>

  </PageHeader>
);
}

export default TagsHome;