import React from 'react';
import PageHeader from "../components/PageHeader/pageheadercontrol";


const AdminPage = () => {
    const headerImage = '/img/headers/admin.png'

    return (<PageHeader image={headerImage} title={"Admin"}
                        description={"Perform administrative tasks"}
                        breadcrumb={[
                            ["Home", "/"],
                            ["Admin", "/admin"]
                        ]} >
        <section>

        </section>
    </PageHeader>);
}

export default AdminPage;