import styled from 'styled-components';
import SideNav, {
    Toggle,
    Nav,
    NavItem,
    NavIcon,
    NavText
} from '@trendmicro/react-sidenav';

// SideNav
const StyledSideNav = styled(SideNav)`
  background-color: #1b1b1b;
  border-right: 1px solid #ddd;
  position: fixed;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;

// Toggle
const StyledToggle = styled(Toggle)`
    background-color: #1b1b1b;
`;
StyledToggle.defaultProps = Toggle.defaultProps;

// Nav
const StyledNav = styled(Nav)`
    background-color: #1b1b1b;

    &&[class*="expanded--"] {
      [class*="sidenav-subnav--"] {
        > [class*="sidenav-subnavitem--"],
        > [class*="sidenav-subnavitem--"]:hover {
          > [class*="navitem--"] {
            color: #222;
          }
        }

        > [class*="sidenav-subnavitem--"]:hover {
          > [class*="navitem--"] {
            background: #1b1b1b !important;
          }
        }

        > [class*="sidenav-subnavitem--"][class*="selected--"] {
          > [class*="navitem--"] {
            color: #db3d44;
          }

          > [class*="navitem--"]::before {
            border-left: 2px solid #db3d44;
          }
        }
      }
    }

    && > [class*="sidenav-navitem--"] {
      > [class*="navitem--"] {
        background-color: #1b1b1b;
        color: #222;
      }
    }

    && > [class*="sidenav-navitem--"]:hover {
      > [class*="navitem--"] {
        background-color: #1b1b1b !important;
      }
    }

    && > [class*="sidenav-navitem--"],
    && > [class*="sidenav-navitem--"]:hover {
      > [class*="navitem--"] {
        [class*="navicon--"] {
          &, > * {
            color: #666;
          }
        }

        [class*="sidenav-nav-text--"] {
          &, > * {
            color: #222;
          }
        }
      }
    }

    && > [class*="sidenav-navitem--"][class*="highlighted--"],
    && > [class*="sidenav-navitem--"][class*="highlighted--"]:hover {
      > [class*="navitem--"] {
        [class*="navicon--"],
        [class*="navtext--"] {
          &, > * {
            color: #db3d44;
          }
        }

        [class*="sidenav-nav-text--"] {
          font-weight: 700;
        }
      }
    }
`;
StyledNav.defaultProps = Nav.defaultProps;

// NavItem
const StyledNavItem = styled(NavItem)`
    &&&:hover {
        [class*="navtext--"] {
            color: #ccc;
        }
    }
`;
StyledNavItem.defaultProps = NavItem.defaultProps;

// NavIcon
const StyledNavIcon = styled(NavIcon)`
    color: #222;
`;
StyledNavIcon.defaultProps = NavIcon.defaultProps;

// NavText
const StyledNavText = styled(NavText)`
  color: #ccc;
`;
StyledNavText.defaultProps = NavText.defaultProps;

export {
    StyledToggle as Toggle,
    StyledNav as Nav,
    StyledNavItem as NavItem,
    StyledNavIcon as NavIcon,
    StyledNavText as NavText
};
export default StyledSideNav;