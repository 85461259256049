import React from 'react';

import './comfyui.css';

const ComfyUIGroupNode = ({ data, isConnectable }) => {
    return (
        <div style={{
            position: 'relative',
            zIndex: -1,
            width: data.bounding[2] - data.bounding[1],
            height: data.bounding[3] - data.bounding[2]
        }}>
            <span>{data.title}</span>
        </div>
    );
}

export default ComfyUIGroupNode;
