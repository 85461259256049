import {getCacheValue, getLayer, getTemplate} from "../../../hooks/ccg/ccgdata";

let pendingImages = 0;

async function drawLine(context, line, x, y, line_height, card) {
    const matches = line.match(/\{([0-9a-zA-Z-_]+)\}/g);

    if(matches) {
        const spl = line.split(/(\{[0-9a-zA-Z-_]+\})/g);
        for(let i = 0; i < spl.length; i++) {
            let entry = spl[i];
            if (entry.startsWith('{') && entry.endsWith('}')) {
                entry = entry.substring(1, entry.length - 1).toLowerCase();
                const layer = getLayer(card.collection, entry);
                if (layer) {
                    if (isNaN(entry)) {
                        await drawLayerCtx(context, card, entry, card.cardWidth, card.cardHeight, x, y - line_height + 8, line_height, line_height);
                    }
                } else {
                    var font = context.font;
                    var fill = context.fillStyle;
                    context.font = line_height * .75 + "px";
                    context.fillStyle = 'black';
                    var metrics = context.measureText(entry);
                    var offset = (line_height - metrics.width) / 2;
                    await drawLayerCtx(context, card, 'c', card.cardWidth, card.cardHeight, x, y - line_height + 8, line_height, line_height);
                    context.fillText(entry, x + offset, y);
                    context.font = font;
                    context.fillStyle = fill;
                }
                x += line_height + 8;
            } else {
                var metrics = context.measureText(entry);
                context.fillText(entry, x, y);
                x += metrics.width;
            }
        }
    } else {
        context.fillText(line, x, y);
    }
}

export async function wrapText(context, text, x, y, line_width, line_height, card)
{
    var line = '';
    var paragraphs = text.split('\n');
    for (var i = 0; i < paragraphs.length; i++)
    {
        var words = paragraphs[i].split(' ');
        for (var n = 0; n < words.length; n++)
        {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > line_width && n > 0)
            {
                await drawLine(context, line, x, y, line_height, card);
                line = words[n] + ' ';
                y += line_height;
            }
            else
            {
                line = testLine;
            }
        }
        await drawLine(context, line, x, y, line_height, card);
        y += line_height;
        line = '';
    }
}

async function loadFont(font) {
    if(!window.loadedFonts) window.loadedFonts = {};
    if(window.loadedFonts[font]) {
        return window.loadedFonts[font];
    }

    var myFont = new FontFace(font, 'url(/fonts/' + font + '.ttf)');
    await myFont.load();
    document.fonts.add(myFont);
    window.loadedFonts[font] = myFont;
    return myFont;
}

export async function drawText(ctx, layer, text, color, x, y, width, card)
{
    var font = layer['font-family'];
    await loadFont(font);
    const fontSize = layer['font-size'] / 2.0;
    ctx.font = fontSize + "px " + font;
    ctx.fillStyle = color;
    if(layer['alignment'] && layer['alignment'] !== '') {
        ctx.textAlign = layer['alignment'];
    } else {
        ctx.textAlign = "left";
    }

    await wrapText(ctx, text, x, y, width, fontSize, card);
}

export async function loadImage(image, proxy=true) {
    return new Promise((resolve, reject) => {
        if(!window.loadedImages) window.loadedImages = {};
        if(!window.loadedImages[image]) {
            pendingImages++;
            const img = new Image();
            if(image.startsWith('http') && proxy) {
                img.crossOrigin = "anonymous";
                img.src = 'https://api.aiart.doubtech.com/image.php?url=' + encodeURIComponent(image);
            } else {
                img.src = image;
            }
            img.onload = () => {
                window.loadedImages[image] = img;
                if(img.width === 0 || img.height === 0) {
                    console.warn("Image failed to load with dimensions: ", image);
                }
                pendingImages--;
                resolve(img);
            }
            img.onerror = (e) => {
                console.error("Failed to load iamge: ", image, e);
                pendingImages--;
                reject("Failed to load " + image);
            }
            //img.src = image;
        } else {
            resolve(window.loadedImages[image]);
        }
    });
}

function getLayerImage(layer, card, user) {
    let image = layer.url;
    if(image === 'image') image = card?.image;
    if(layer.name === 'user') image = `https://api.aiart.doubtech.com/profile-image?user=${user.email}`;
    if(image == "{userpicture}") {
        image = user?.picture;
    }
    return image;
}

const isEmpty = (str) => !str || str.length === 0;

export async function drawLayer(canvas, card, layerName, cardWidth, cardHeight, xOverride = undefined, yOverride = undefined, widthOverride = undefined, heightOverride = undefined) {
    if(!canvas.current) {
        console.warn("Canvas isn't ready yet.", card, layerName);
        return;
    }
    const ctx = canvas.current.getContext("2d");
    await drawLayerCtx(ctx, card, layerName, cardWidth, cardHeight, xOverride, yOverride, widthOverride, heightOverride);
}

export async function drawLayerCtx(ctx, card, layerName, cardWidth, cardHeight, xOverride = undefined, yOverride = undefined, widthOverride = undefined, heightOverride = undefined) {
    if(!layerName) return;
    const template = getTemplate(card.collection, card.template);
    const layer = getLayer(card.collection, layerName);
    const user = getCacheValue("profile", {});
    if(!layer) {
        console.log("No layer found for: " + layerName);
        return {width: 0, height: 0};
    }

    let image = await getLayerImage(layer, card, user);

    var width = -1 === layer.width ? cardWidth : layer.width;
    var height = -1 === layer.height ? cardHeight : layer.height;
    if(widthOverride) width = widthOverride;
    if(heightOverride) height = heightOverride;

    const x = xOverride ?? layer.x ?? 0;
    const y = yOverride ?? layer.y ?? 0;

    let text = card?.properties ? (card?.properties[layer.name] ?? "") : "";
    if(layer.name === 'disclaimer' && !text && template?.disclaimer) text = template.disclaimer;

    if(layer['text']) {
        let formatText = layer['text'];
        var profile = getCacheValue("profile", {displayname: user.nickname});
        const name = profile?.info?.displayname ?? user.nickname;
        formatText = formatText.replace("${username}", name);
        if(card?.properties[layer.name]) {
            formatText = formatText.replace("${" + layerName + "}", card?.properties[layer.name]);
            if (layer.aliases) {
                layer.aliases.map(alias => {
                    formatText = formatText.replace("${" + alias + "}", card?.properties[layer.name]);
                });
            }
        }
        formatText = formatText.replace("{disclaimer}", template?.disclaimer);


        // Process variables
        const matches = formatText.match(/\$\{([a-zA-Z-_]+)\}/g);
        if(matches) {
            matches.forEach(match => {
                const variable = match.replace("${", "").replace("}", "");
                const value = card?.variables[variable];
                if(value) {
                    formatText = formatText.replace('${' + variable + '}', value);
                } else {
                    formatText = formatText.replace('${' + variable + '}', "");
                }
            });
        }

        text = formatText;
    }

    const allowOverride = 'allow-overridden-color' in layer ? layer['allow-overridden-color'] : true;

    var color = layer.color;
    if (allowOverride === true && template['override-color']) color = template['override-color'];

    var layerVisible = true;

    function propertyValue(card, layerName) {
        return card?.properties ? card.properties[layerName] : undefined;
    }

    function hasProperty(card, layerName) {
        return propertyValue(card, layerName) ? true : false;
    }

    if(layer['property-must-have-text']) {
        const mustHavePropertyTarget = layer['property-must-have-text'];
        if(isEmpty(propertyValue(card, mustHavePropertyTarget))) {
            layerVisible = false;
        }
    }

    if(layer['property-must-not-have-text']) {
        const mustHavePropertyTarget = layer['property-must-not-have-text'];
        if(!isEmpty(propertyValue(card, mustHavePropertyTarget))) {
            layerVisible = false;
        }
    }

    if(layer['variable-must-have-text']) {
        const mustHavePropertyTarget = layer['variable-must-have-text'];
        if(isEmpty(card?.variables[mustHavePropertyTarget])) {
            layerVisible = false;
        }
    }

    if(layer['variable-must-not-have-text']) {
        const mustHavePropertyTarget = layer['variable-must-not-have-text'];
        if(!isEmpty(card?.variables[mustHavePropertyTarget])) {
            layerVisible = false;
        }
    }

    var top = isNaN(y) ? 0 : y;
    var left = isNaN(x) ? 0 : x;

    if (layerVisible) {

        if(image) {
            const img = await loadImage(image);

            // Center and crop image to fit drawImage
            const imageWidth  = img.width;
            const imageHeight = img.height;

            if(!width) {
                width = imageWidth;
            }

            if(!height) {
                height = imageHeight;
            }

            function getLayerValue(layer, key) {
                var formatText = layer[key];
                if(!formatText) return formatText;
                const matches = formatText.match(/\$\{([:a-zA-Z-_]+)\}/g);
                if(matches) {
                    matches.forEach(match => {
                        const v = match.replace("${", "").replace("}", "");
                        const spl = v.split(":");
                        const variable = spl[0];
                        const defaultValue = spl.length > 1 ? spl[1] : '';
                        const value = card?.variables[variable];
                        if(value) {
                            formatText = formatText.replace(match, value);
                        } else {
                            formatText = formatText.replace(match, defaultValue);
                        }
                    });
                }
                return formatText;
            }

            if(layer['preserve-aspect-ratio'] || layer.name === "image") {
                var imageXOffset = 0;
                var imageYOffset = 0;
                if(layer['preserve-aspect-ratio']) {
                    const aspectRatio = imageWidth / imageHeight;
                    const targetAspectRatio = width / height;
                    var targetWidth = width;
                    var targetHeight = height;
                    if (targetAspectRatio < aspectRatio) {
                        targetWidth = height * aspectRatio;
                        targetHeight = targetWidth / aspectRatio;
                    } else {
                        targetHeight = width / aspectRatio;
                        targetWidth = targetHeight * aspectRatio;
                    }

                    var align = getLayerValue(layer, 'image-alignment');
                    var valign = getLayerValue(layer, 'image-vertical-alignment');

                    if(align === 'center') {
                        imageXOffset = width < targetWidth ? (targetWidth - width) / 2 : 0;
                    }
                    if(align === 'right') {
                        imageXOffset = width < targetWidth ? targetWidth - width : 0;
                    }

                    if(valign === 'center') {
                        imageYOffset = height < targetHeight ? (targetHeight - height) / 2 : 0;
                    }
                    if(valign === 'bottom') {
                        imageYOffset = height < targetHeight ? targetHeight - height : 0;
                    }

                    width = targetWidth;
                    height = targetHeight;
                }

            }


            if(layer['mask']) {
                ctx.save();
                ctx.globalCompositeOperation = "destination-in";
                ctx.drawImage(img, left, top, width, height);
                ctx.restore();
            } else if(layer['layer-mask']) {
                const mask = layer['layer-mask'];
                var layerMaskLayer = getLayer(card.collection, mask['layer']);
                var layerMaskImage = getLayerImage(layerMaskLayer, card, user);

                const maskImg = await loadImage(layerMaskImage);
                const maskCanvas = document.getElementById('mask-canvas');
                const maskCtx = maskCanvas.getContext("2d");

                maskCtx.width = ctx.width;
                maskCtx.height = ctx.height;
                maskCanvas.width = ctx.width;
                maskCanvas.height = ctx.height;

                maskCtx.clearRect(0, 0, maskCtx.width, maskCtx.height);

                maskCtx.save();
                maskCtx.scale(card.workingScaleX, card.workingScaleY);

                const maskedImageLeft = left - (imageXOffset ?? 0);
                const maskedImageTop = top - (imageYOffset ?? 0);
                maskCtx.drawImage(img, maskedImageLeft, maskedImageTop, width, height);
                const absolute = getLayerValue(mask, 'position') === 'absolute';
                const maskX = absolute ? mask.x : left + mask.x;
                const maskY = absolute ? mask.y : top + mask.y;
                if (!mask['preview']) maskCtx.globalCompositeOperation ="destination-in";
                maskCtx.drawImage(maskImg, maskX, maskY, mask.width, mask.height);
                ctx.drawImage(maskCanvas, 0, 0, cardWidth, cardHeight);
                maskCtx.width = 0;
                maskCtx.height = 0;
                maskCtx.restore();

                maskCtx.clearRect(0, 0, maskCtx.width, maskCtx.height);
            } else {
                ctx.drawImage(img, left, top, width, height);
            }
        }
        if(layer.name == 'cost' || layer['is-cost'] === true) {
            var offsetX = 0;
            var offsetY = 0;
            text = text.toLowerCase();
            const matches = text.match(/\{([0-9a-zA-Z-_]+)\}/g);
            if(matches) {
                if(layer['reverse'] === true) matches.reverse();
                for (var i = 0; i < matches.length; i++) {
                    let match = matches[i];
                    const costLayerName = match.replace("{", "").replace("}", "").toLowerCase();
                    const childX = left + offsetX;
                    const {width, height} = await drawCostLayer(ctx, costLayerName, card,
                        childX, top - 25,
                        left + offsetX + 15, top);
                    offsetX -= layer['child-spacing'] + width;
                }
            }
        } else if(text) {
            await drawText(ctx, layer, text, color, left, top, width, card);
        }
    }
    return {width, height}
}

async function drawCostLayer(ctx, costLayerName, card, x, y, textX, textY) {
    let dimensions = {width: 0, height: 0};
    if(isNaN(costLayerName)) {
        const costLayer = getLayer(card.collection, costLayerName);
        if(costLayer) {
            dimensions = await drawLayerCtx(ctx, card, costLayerName, card.cardWidth, card.cardHeight, x, y);
        }
    } else {
        const costLayer = getLayer(card.collection, 'c');
        const textOffsetX = costLayer['text-offset-x'] ?? 0;
        const textOffsetY = costLayer['text-offset-y'] ?? 0;
        dimensions = await drawLayerCtx(ctx, card, 'c', card.cardWidth, card.cardHeight, x, y);
        await drawText(ctx, costLayer, costLayerName, costLayer.color, textX + textOffsetX, textY + textOffsetY, 32, card);
    }
    return dimensions;
}
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function sleep(duration) {
    await timeout(duration);
}

export default async function drawCard(canvas, card, width, height)
{
    if(!card) return;
    if(!card.template) return;

    async function draw() {
        if(!canvas.current) {
            return;
        }
        const ctx = canvas.current.getContext("2d");
        canvas.current.width = width;
        canvas.current.height = height;
        ctx.width = width;
        ctx.height = height;
        ctx.save();

        const template = getTemplate(card.collection, card.template);
        if (template && card) {
            var cardWidth = template.width;
            var cardHeight = template.height;
            card.cardWidth = cardWidth;
            card.cardHeight = cardHeight;

            card.workingScaleX = width / cardWidth;
            card.workingScaleY = height / cardHeight;
            ctx.scale(width / cardWidth, height / cardHeight);
            ctx.beginPath();
            ctx.roundRect(0, 0, cardWidth, cardHeight, 128)
            ctx.fill();
            ctx.stroke();
            for (var i = 0; i < template.layers.length; i++) {
                const layer = template.layers[i];
                await drawLayer(canvas, card, layer, cardWidth, cardHeight);
            }
        }

        ctx.restore();
    }

    await draw();
    if(pendingImages > 0) {
        while (pendingImages > 0) {
            await sleep(250);
        }
        await draw();
    }
}