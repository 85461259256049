import React, {useCallback, useEffect} from 'react';
import {JsonEditor as Editor} from "jsoneditor-react18";
import {json, Link, useNavigate, useParams} from "react-router-dom";
import {Card, FormControl, Tab, Tabs} from "react-bootstrap";
import {MdApps, MdContentPaste, MdDelete, MdDownload, MdExpand, MdOpenInNew} from "react-icons/md";
import {MdAdd, MdCopyAll, MdSave} from "react-icons/all";
import {toast} from "react-toastify";
import {artApiFetchAuthAsync, artApiPostAuthAsync} from "../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import StyledButton from "../components/styledbutton";
import PresetLoader from "../components/PresetLoader";
import ReactFlow, {
    addEdge,
    Background,
    ControlButton,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import ComfyUINode from "../components/comfyui/comfyuinode";
import ComfyUIGroupNode from "../components/comfyui/groupnode";
import useWindowDimensions from "../../hooks/WindowDimensions";
import LiteGraphProcessor, {fetchObjectInfo} from "../components/comfyui/graphprocessor";
import LiteGraphComponent from "../components/litegraph/litegraphcomponent";
import PresetGrid from "../components/presetgrid/presetgrid";
import ArtApiKeyList from "../components/artapi/keylist";
import Preset from "./preset/preset";

const nodeTypes = {
    comfyui: ComfyUINode,
    group: ComfyUIGroupNode
};
const Presets = () => {
    const navigate = useNavigate();
    let { id, tab } = useParams();
    const { getAccessTokenSilently, user } = useAuth0();
    const editorRef = React.createRef();
    const [headerImage, setHeaderImage] = React.useState('/img/headers/dreamscape.png');
    const [presetId, setPresetId] = React.useState();
    const [presetName, setPresetName] = React.useState("");
    const [presetData, setPresetData] = React.useState({});
    const [presetList, setPresetList] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState(tab ?? "preset");
    const [type, setType] = React.useState("comfyui");
    const presetLoaderRef = React.createRef();
    const [visualizationMaximized, setVisualizationMaximized] = React.useState(false);
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const [objectInfo, setObjectInfo] = React.useState();
    const [presetDropdownList, setPresetDropdownList] = React.useState([]);

    const initialNodes = [
        { id: '1', position: { x: 0, y: 0 }, data: { label: '1 aasdf' },
            type: 'input',
            sourcePosition: 'right',
            targetPosition: 'left', },
        { id: '2', position: { x: 0, y: 100 }, data: { label: '2' },
            sourcePosition: 'right',
            targetPosition: 'left', },
    ];
    const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);


    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    useEffect(() => {
        try {
            var list = presetList?.map(preset => {
                return {
                    label: preset.name,
                    title: preset.name,
                    description: preset.description,
                    link: "/presets/" + preset.id
                };
            })
            setPresetDropdownList(list);
        } catch (e) {
            console.error(e, presetList);
        }
    }, [presetList]);

    useEffect(() => {
        if(activeTab === "editor") {
            editorRef.current.jsonEditor.set(presetData);
        }
    }, [activeTab]);

    useEffect(() => {
        console.log("Object info changed: ", objectInfo);
        refreshGraph(presetData, objectInfo);
    }, [objectInfo]);

    async function fetchPresets() {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const presets = await artApiPostAuthAsync(token, "stable-diffusion/list-presets");
        setPresetList(presets ?? []);
    }

    useEffect(() => {setPresetId(id)}, [id]);

    useEffect(() => {
        fetchPresets();
    }, []);

    async function fetchPreset() {
        if (presetId) {
            const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const preset = await artApiFetchAuthAsync(token, "stable-diffusion/preset", "id=" + encodeURIComponent(presetId));
            if ("error" in preset) {
                toast.error("Failed to fetch preset.\n" + preset.error);
            } else {
                setPresetName(preset.name);
                console.log("Preset: ", preset);
                setType(preset.type);
                const presetData = JSON.parse(preset.preset);
                // if presetData.data is a string, json parse it
                if (typeof presetData.data === "string") {
                    presetData.data = JSON.parse(presetData.data);
                }
                if(editorRef.current) editorRef.current.jsonEditor.set(presetData);
                setPresetData(presetData);

                if(!activeTab) {
                    if (preset.type === "comfyui") {
                        setActiveTab("comfyui");
                    } else {
                        setActiveTab("editor");
                    }
                }

                if(preset.type === "comfyui") {
                    refreshGraph(presetData, objectInfo);
                }
            }
        }
    }
    useEffect(() => {
        fetchPreset();
    }, [presetId]);

    useEffect(() => {
        if(editorRef.current) editorRef.current.jsonEditor.set(presetData);
    }, [editorRef.current]);

    function getGroups(presetData) {
        if(!presetData.data) return [];
        if (!presetData.data.groups) return [];
        return presetData.data.groups.map(((group, index) => {
            return {
                id: `group-${index}`,
                type: "group",
                position: {
                    x: group.bounding[0],
                    y: group.bounding[2]
                },
                data: group
            }
        }));
    }

    async function getNodes(presetData) {
        if(!presetData.data) return [];
        if (!presetData.data.nodes) return [];

        let info = objectInfo;
        if(!info) {
            info = await fetchObjectInfo(setObjectInfo);
            setObjectInfo(info);
        }
        if(info) {
            let updated = false;
            presetData.data.nodes.forEach((node, index) => {
                if ('widgets_values' in node && !('widgets' in node) && info) {
                    if (node.type in info) {
                        const widgets = [];
                        const linked = [];
                        const inputNames = node.inputs ? node.inputs.map((input) => {
                            console.log("Input: ", input);
                            return input.name;
                        }) : [];
                        if(info[node.type].input.required) {
                            Object.keys(info[node.type].input.required).forEach((widgetName) => {
                                // Check if widgetName is in the list of strings in inputNames
                                if (inputNames.includes(widgetName)) {
                                    linked.push(widgetName);
                                } else {
                                    widgets.push(widgetName);
                                    if(widgetName === "seed") {
                                        widgets.push("control_after_generate");
                                    }
                                }
                            });
                        }
                        if(info[node.type].input.optional) {
                            Object.keys(info[node.type].input.optional).forEach((widgetName) => {
                                // Check if widgetName is in the list of strings in inputNames
                                if (inputNames.includes(widgetName)) {
                                    linked.push(widgetName);
                                } else {
                                    widgets.push(widgetName);
                                    if(widgetName === "seed") {
                                        widgets.push("control_after_generate");
                                    }
                                }
                            });
                        }
                        node.widgets = [...widgets, ...linked];
                    }
                    updated = true;
                }
            });
            if (updated) {
                setPresetData(presetData);
                if (editorRef.current) editorRef.current.jsonEditor.set(presetData);
            }
        }

        return presetData.data.nodes.map((node => {

            return {
                id: `${node.id}`,
                type: "comfyui",
                sourcePosition: 'right',
                targetPosition: 'left',
                position: {
                    x: node.pos[0],
                    y: node.pos[1]
                },
                data: {
                    label: node.properties['Node name for S&R'],
                    node: node
                }
            }
        }));
    }

    async function refreshGraph(presetData) {
        if(!presetData.data) return;

        const edges = [];
        const nodes = [
            //...getGroups(presetData),
            ...(await getNodes(presetData))
        ];
        if(presetData.data.links) {
            presetData.data.links.forEach((link, index) => {
                if(!link) return;
                edges.push({
                    id: `e${link[1]}-${link[3]}-${link[2]}-${link[4]}}`,
                    source: `${link[1]}`,
                    sourceHandle: `output-${link[2]}`,
                    target: `${link[3]}`,
                    targetHandle: `input-${link[4]}`,
                });
            });
        }

        console.log("Refreshing graph with nodes: ", nodes, edges);
        setNodes(nodes);
        setEdges(edges);
    }

    function onImport(json) {
        const data = editorRef.current.jsonEditor.get();
        data.data = json;
        editorRef.current.jsonEditor.set(data);
        refreshGraph(data);
    }


    function pasteVariables() {
        navigator.clipboard.readText().then(text => {
            try {
                setPresetData(text);
            } catch (e) {
                console.error(e);
            }
            try {
                editorRef.current.jsonEditor.set(JSON.parse(text));
            } catch (e) {
                toast.error("Invalid JSON\n" + e, {autoClose: 5000});
            }
        });
    }
    function copyVariables() {
        navigator.clipboard.writeText(JSON.stringify(presetData));
    }

    async function savePreset() {
        if (presetName === "") {
            toast.error("Please enter a preset name.", {autoClose: 5000});
            return;
        }

        const progress = toast.info("Saving preset...", {autoClose: false});
        const presetJson = editorRef.current.jsonEditor.get();
        if(presetJson.generate_prompt !== false) {
            const output = await new LiteGraphProcessor(presetJson.data).graphToPrompt();
            presetJson.compiledPrompt = output;
        }
        const data = JSON.stringify(presetJson);
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const response = await artApiPostAuthAsync(token, type + "/save-preset", data, "name=" + encodeURIComponent(presetName), `id=${presetId}`);
        if ("error" in response) {
            toast.update(progress, {render: "Failed to save preset.\n" + response.error, type: toast.TYPE.ERROR, autoClose: 5000});
        } else {
            toast.update(progress, {render: "Saved preset.", type: toast.TYPE.SUCCESS, autoClose: 5000});
            await fetchPreset();
        }
    }

    async function createPreset() {
        if (presetName === "") {
            toast.error("Please enter a preset name.", {autoClose: 5000});
            return;
        }

        const progress = toast.info("Creating preset...", {autoClose: false});
        const data = JSON.stringify({
            "name": presetName,
            "type": type,
            "data": {}
        });

        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const response = await artApiPostAuthAsync(token, type + "/save-preset", data, "name=" + encodeURIComponent(presetName), `id=${presetId}`);
        if ("error" in response) {
            toast.done(progress);
            toast.error("Failed to save preset.\n" + response.error, {autoClose: 5000});
        } else {
            toast.update(progress, {render: "Created preset.", type: toast.TYPE.SUCCESS, autoClose: 5000});
            navigate("/presets/" + response.id);
        }
    }

    function newPreset() {
        navigate("/presets");
        setPresetId(null);
        setPresetName("");
        setPresetData({});
        if(editorRef.current) editorRef.current.jsonEditor.set({});
    }

    function deletePreset() {
        async function deletePreset() {
            if (presetId) {
                const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
                const preset = await artApiFetchAuthAsync(token, "stable-diffusion/delete", "id=" + encodeURIComponent(presetId));
                if ("error" in preset) {
                    toast.error("Failed to delete preset.\n" + preset.error);
                } else {
                    toast.success("Deleted preset.", {autoClose: 5000});
                    setPresetId(null);
                    await fetchPresets();
                }
            }
        }

        deletePreset();
    }

    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175"}}>
                <div style={{backgroundColor: "#282828", padding: 16, border: "1px white"}}>
                    {content}
                </div>
            </Tab>
        );
    }

    function renderEditor() {
        return renderTab("editor", "Preset Data", <Editor
                ref={editorRef}
            />);
    }

    function renderComfy(style = { height: 400 }) {
        return <div style={style}>
            {/*<ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
            >
                <Controls>
                    <ControlButton onClick={() => setVisualizationMaximized(!visualizationMaximized)} title="Maximize">
                        <div><MdExpand/></div>
                    </ControlButton>
                </Controls>
                <MiniMap
                    zoomable
                    pannable
                    style={{border: "1px solid white", backgroundColor: "#282828", color: "black"}}
                />
                <Background variant="dots" gap={12} size={1} />
            </ReactFlow>*/}
                <LiteGraphComponent
                    width={style.width}
                    height={style.height}
                    data={presetData.data}
                />
        </div>
    }

    function setPrivatePreset(checked) {

    }

    function renderProperties() {
        return <div>
            <div>
                <span>Preset Name</span>
                <FormControl
                    className={"create-card-flex-between-items-grow"}
                    type="text"
                    value={presetName}
                    style={{fontSize: 12, maxWidth: 200}}
                    onChange={(e) => {
                        setPresetName(e.target.value);
                    }}
                />
            </div>
            <div>
                Private: <input type={"checkbox"} checked={presetData.private} onChange={(e) => {    setPrivatePreset(e.target.checked);}}/>
            </div>
            <div>
                Type: {type}
            </div>
        </div>;
    }

    function renderNewPresetContent() {
        return <div>
            <div>
                <h3>Presets</h3>
                <p>Stable diffusion presets are a way to save and share your favorite settings. These settings can then be used with the prompt designer, dream, and other image generation based tools to easily reproduce workflows.</p>
            </div>

            <div className={"mt-3"}>
                <h3>Create a New Preset</h3>
                <div className={"create-card-flex-between-container"}>
                    <span>Preset Name</span>
                    <FormControl
                        className={"create-card-flex-between-items-grow"}
                        type="text"
                        value={presetName}
                        style={{fontSize: 12, maxWidth: 200}}
                        onChange={(e) => {
                            setPresetName(e.target.value);
                        }}
                    />
                </div>
                <div className={"create-card-flex-between-container"}>
                    <div className={"create-card-flex-between-items-grow"}/>
                    <StyledButton label={"Create"} onClick={() => createPreset()} />
                </div>
            </div>
        </div>
    }

    function renderAPI() {
        return <ArtApiKeyList preset={{id: presetId, ...presetData}} />;
    }

    function renderPreset() {
        return <Preset id={id} showTitle={false} />
    }

    function content() {
        if(presetId) {
            return (
                <Tabs activeKey={activeTab} defaultActiveKey="variables" id="uncontrolled-tab-example"
                      onSelect={key => setActiveTab(key)}
                >
                    {renderTab("preset", "Preset", renderPreset())}
                    {type === "comfyui" && renderTab("comfyui", "Workflow Visualization", renderComfy({width: windowWidth - 175, height: Math.max(windowHeight - 300, 500)}))}
                    {renderEditor()}
                    {renderTab("properties", "Properties", renderProperties())}
                    {renderTab("api", "API", renderAPI())}
                </Tabs>
            );
        } else {
            return (
                <div>
                    {renderNewPresetContent()}
                    <PresetGrid presets={presetDropdownList} />
                </div>
            );
        }

        return renderNewPresetContent();


        /*
        return <div>
            <div className={"create-card-flex-between-container"}>
                <div onClick={() => newPreset()}><span style={{fontSize: 24}}><MdNewLabel/></span></div>
                <div onClick={() => copyVariables()}><span style={{fontSize: 24}}><MdContentCopy/></span></div>
                <div onClick={() => pasteVariables()}><span style={{fontSize: 24}}><MdContentPaste/></span></div>
                <div className={"create-card-flex-between-items-grow"}/>
                <FormControl
                    className={"create-card-flex-between-items-grow"}
                    type="text"
                    value={presetName}
                    style={{fontSize: 12, maxWidth: 200}}
                    onChange={(e) => {
                        setPresetName(e.target.value);
                    }}
                />
                <div onClick={() => deletePreset()}><span style={{fontSize: 24}}><MdDelete/></span></div>
                <div className={"create-card-flex-between-items-grow"}/>
                <div onClick={() => savePreset()}><span style={{fontSize: 24}}><MdSave/></span></div>
            </div>
        <Editor
            ref={editorRef}
        />
        </div>*/
    }

    function rightColumn() {
        return (
            <Card>
                <Card.Header>
                    Presets
                </Card.Header>
                <Card.Body style={{overflow: "hidden"}}>
                    <ul className='services-list' style={{margin: -24}}>
                        {presetList?.map((preset) => {
                            return <li key={preset.id}>
                                <Link to={"/presets/" + preset.id}>{preset.name}</Link>
                            </li>
                        })}
                    </ul>
                </Card.Body>
            </Card>
        );

        /*<div>
            <CollapsibleCard title={"Presets"} expand={true}>
                <CollapsibleCardContent>
                    {presetList.map((preset) => {
                        return <div key={preset.id} className={"link"} style={{cursor: "pointer"}} onClick={() => setPresetId(preset.id)}>
                            {preset.name}
                        </div>
                    })}
                </CollapsibleCardContent>
            </CollapsibleCard>
        </div>;*/
    }

    let onPngImport = (data) => {
        console.log("OnPngImport: ", data);
        try {
            const preset = {...presetData};
            preset.data = JSON.parse(data.workflow);
            if (data.prompt) {
                preset.generate_prompt = false;
                preset.compiledPrompt = JSON.parse(data.prompt);
            }

            setPresetData(preset);
            editorRef.current.jsonEditor.set(preset);
            refreshGraph(preset);
        } catch (e) {
            toast.error("Error importing preset");
            console.log(e);
        }
    };

    function downloadPresetData() {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(presetData, null, 2)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${presetName}.json`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return (
        <PageHeader image={headerImage}
                    title={presetId ? presetName : "Stable Diffusion Presets"}
                    description={"A collection of presets for Stable Diffusion. You can also create your own presets and share them with others."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Presets", "/presets"]
                    ]}
                    minimum={true}
                    menuleft={presetId ? [
                        {icon: MdApps, label: "Presets", dropdown: presetDropdownList},
                        {icon: MdAdd, label: "New Preset", onClick: newPreset},
                        {icon: MdContentPaste, label: "Paste Preset Data", onClick: pasteVariables},
                        {icon: MdCopyAll, label: "Copy Preset Data", onClick: copyVariables},
                        {icon: MdOpenInNew, label: "Import", onClick: () => presetLoaderRef.current.loadJson()},
                        {icon: MdDownload, label: "Export", onClick: () => downloadPresetData()},
                    ] : [
                        {icon: MdAdd, label: "New Preset", onClick: newPreset},
                    ]}

                    menuright={presetId ? [
                        {icon: MdDelete, onClick: deletePreset},
                        {icon: MdSave, onClick: savePreset},
                    ] : []}>

            {content()}
            <PresetLoader ref={presetLoaderRef} onFileLoaded={onImport} onPngMetadataLoaded={onPngImport} />

            {visualizationMaximized && <div className={"comfy-overlay"}>{renderComfy({
                height: "100%",
                width: "100%",
                backgroundColor: "#333",
            })}</div>}
        </PageHeader>)
}

export default Presets