import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import useCCGData, {
    ccgDataReady,
    getLayer,
    getTemplate,
    saveLayer,
    saveTemplate,
    updateLayer,
    updateTemplate
} from "../../../hooks/ccg/ccgdata";
import {Dropdown, FormControl, InputGroup, ProgressBar, Tab, Tabs} from 'react-bootstrap';
import CCGTemplate from "../../components/ccg/ccgtemplate";
import CCGTemplateChooser from "./templatechooser";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {useCookies} from 'react-cookie';
import {JsonEditor as Editor} from "jsoneditor-react18";
import ArtworkMiniBrowser from "./artworkminibrowser";
import {artApiFetchAuthAsync, artApiPostAuthAsync} from "../../../hooks/artapi";
import CCGCollectionChooser from "./collectionchooser";
import StyledButton from "../../components/styledbutton";
import RightAlign from "../../components/rightalign";
import {MdContentCopy, MdContentPaste, MdOpenInNew} from "react-icons/md";
import {toast} from "react-toastify";
import {IoCreate, MdImageSearch} from "react-icons/all";
import {useLoading} from "../../components/loading";
import {copyImageDescription, createPrompt} from "../../components/ccg/carddata";
import SDJobSubmitter from "../../components/sdjobsubmitter";
import MJJobSubmitter from "../../components/mjjobsubmitter";
import PageHeader from "../../components/PageHeader/pageheadercontrol";


const CCGTemplates = () => {
    const newCardTemplate = {
        "name": "",
        "manaCost": "",
        "colors": [],
        "type": "",
        "text": "",
        "flavor": "",
        "power": "",
        "toughness": ""
    };
    const [collection, setCollection] = React.useState("mtg");
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const {user, getAccessTokenSilently} = useAuth0();
    const [template, setTemplate] = React.useState("");
    const [activeTemplate, setActiveTemplate] = React.useState({});
    const [activeLayer, setActiveLayer] = React.useState({});
    const {templates, layers} = useCCGData(collection);
    const [cardConfig, setCardConfig] = React.useState({properties: {}});
    const [savedState, setCookie, removeCookie] = useCookies(['card']);
    const [loaded, setLoaded] = React.useState(false);
    const [canvas, setCanvas] = React.useState(null);
    const templateEditorRef = React.useRef(null);
    const variableEditorRef = React.useRef({});
    const templateLayerEditorRef = React.useRef(null);
    const card = {};
    const [dirtyTemplates, setDirtyTemplates] = React.useState([]);
    const [dirtyLayers, setDirtyLayers] = React.useState([]);
    const [useTemplateSize, setUseTemplateSize] = React.useState(false);
    const [generationPrompt, setGenerationPrompt] = React.useState("");
    const [activeTab, setActiveTab] = React.useState("variables");
    const [generating, setGenerating] = React.useState(false);
    const {loading, setLoading} = useLoading();
    const [generateArt, setGenerateArt] = React.useState(false);
    const [activeService, setActiveService] = React.useState("sd");
    const [aspectRatio, setAspectRatio] = React.useState("all");
    const headerImage = '/img/headers/ccg.png';

    const services = {

    }

    var path = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    if(isNaN(path)) path = "";
    const [id, setId] = React.useState(path);

    function newCard() {
        const card = {...newCardTemplate};
        variableEditorRef.current.jsonEditor.set(card);
        onVariablesChanged(card);
    }

    async function saveCard() {
        toast("Saving card...");
        setLoading(true);

        const fullResImage = canvas.toDataURL();
        canvas.width = activeTemplate.width;
        canvas.height = activeTemplate.height;
        card.redraw(cardConfig, activeTemplate.width, activeTemplate.height);

        var aspectRatio = canvas.width / canvas.height;
        canvas.width = 300 * aspectRatio;
        canvas.height = 300;
        const token = await getAccessTokenSilently();
        card.redraw(cardConfig, 300 * aspectRatio, 300);
        const thumbImage = canvas.toDataURL();
        canvas.width = getActiveWidth();
        canvas.height = getActiveHeight();
        card.redraw(cardConfig, getActiveWidth(), getActiveHeight());

        const data = {
            template: cardConfig.template,
            sourceImage: cardConfig.image,
            properties: cardConfig.properties,
            //image: fullResImage,
            thumb: fullResImage,
            width: activeTemplate.width,
            height: activeTemplate.height,
        };
        await artApiPostAuthAsync(token,"create-card", JSON.stringify(data),
            "title=" + encodeURIComponent(cardConfig.properties.title ?? ""),
            "id=" + encodeURIComponent(id)
        ).then(data => {
            console.log("Card created: ", data);
            window.location.href = "/ccg/create-card/" + data.id;
        });
        setLoading(false);
    }


    function applyCardImage(image) {
        console.log("Applying image: ", image);
        setCardConfig({...cardConfig, image: image});
        setCookie("currentCardImage", image, {path: '/'});
        if(card.redraw) card.redraw(cardConfig);
    }

    useEffect(() => {
        if(!loaded && ccgDataReady({templates, layers}) && savedState) {
            setLoaded(true);
            cardConfig.properties = savedState?.currentCardProperties ?? {};
            cardConfig.image = savedState?.currentCardImage ?? "";
            cardConfig.template = savedState?.currentCardTemplate ?? "";
            cardConfig.collection = savedState?.currentCollection ?? collection;
            cardConfig.variables = savedState?.currentCardVariables ?? {...newCardTemplate};
            setCollection(cardConfig.collection);
            setCardConfig(cardConfig);

            if(cardConfig.template && cardConfig.template.length > 0) {
                setActiveTemplate(templates[cardConfig.template]);
            }
        }
    }, [savedState, templates, layers]);

    useEffect(() => {
        console.log("Card config changed.", cardConfig);
        if(card.redraw) card.redraw(cardConfig);
    }, [template, cardConfig, windowHeight, windowWidth, activeTemplate]);

    function onTemplateContentChanged(modifiedTemplate) {
        updateTemplate(collection, modifiedTemplate);
        if(card.redraw) card.redraw(cardConfig);
        if(!dirtyTemplates.includes(modifiedTemplate.name)) {
            setDirtyTemplates([...dirtyTemplates, modifiedTemplate.name]);
        }
    }

    function onLayerChanged(layer) {
        updateLayer(collection, layer);
        card.redraw(cardConfig);
        if(!dirtyLayers.includes(layer.name)) {
            setDirtyLayers([...dirtyLayers, layer.name]);
        }
    }

    async function saveTemplateAsync(templateName) {
        const token = await getAccessTokenSilently();
        await saveTemplate(token, templateName, collection, getTemplate(collection, templateName));
    }

    async function saveLayerAsync(layerName) {
        const token = await getAccessTokenSilently();
        await saveLayer(token, layerName, collection, getLayer(collection, layerName));
    }

    async function saveModifications() {
        setLoading(true);
        dirtyTemplates.map(async (t) => {
            await saveTemplateAsync(t);
        });
        dirtyLayers.map(async (l) =>{
            await saveLayerAsync(l);
        });
        setDirtyTemplates([]);
        setDirtyLayers([]);
        setLoading(false);
    }

    function changeTemplate(templateName) {
        cardConfig.template = templateName;
        setTemplate(templateName);
        setCardConfig(cardConfig);
        setActiveTemplate(templates[templateName]);
        console.log("Changed template to ", templateName);
        templateEditorRef.current.jsonEditor.set(templates[templateName]);
        setCookie("currentCardTemplate", templateName, {path: '/'});
    }

    function handleLayerSelection(layer) {
        console.log("Selected layer: ", layer);
        setActiveLayer(layers[layer]);
        templateLayerEditorRef.current.jsonEditor.set(layers[layer]);
    }

    function changeCollection(collection) {
        setCollection(collection);
        setCookie("currentCollection", collection, {path: '/'});
    }

    function getDefaultWidth() { return (windowHeight - 50) * 2.5/3.5; }

    function getActiveWidth() {
        return activeTemplate && useTemplateSize ? activeTemplate.width : getDefaultWidth();
    }

    function getDefaultHeight() { return windowHeight; }
    function getActiveHeight() {
        return activeTemplate && useTemplateSize ? activeTemplate.height :  (windowHeight);
    }

    function onVariablesChanged(variables) {
        cardConfig.variables = variables;
        setCookie("currentCardVariables", cardConfig.variables, {path: '/'});
        card.redraw(cardConfig);
    }

    async function generateCard() {
        toast("Generating card...");
        setGenerating(true);
        console.log("Prompt: ", generationPrompt);
        const token = await getAccessTokenSilently();
        try {
            const response = await artApiFetchAuthAsync(token, "gpt", `collection=${cardConfig.collection}`, "prompt=" + encodeURIComponent(generationPrompt));
            variableEditorRef.current.jsonEditor.set(response);
            onVariablesChanged(response);
        } catch (e) {
            console.error("Error: ", e);
        }
        setGenerating(false);
        setActiveTab("variables");
        if(generateArt) {
            var prompt = createPrompt(cardConfig);
            if(activeService === "mj") {
                if(aspectRatio == "all") {
                    submitJob(prompt + " --ar 3:4");
                    submitJob(prompt + " --ar 4:3");
                    submitJob(prompt + " --ar 1:1");
                } else {
                    prompt += " --ar " + aspectRatio;
                    submitJob(prompt);
                }
            } else {
                submitJob(prompt);
            }
        }
    }

    async function submitJob(query) {
        services[activeService](query);
    }

    async function copyTemplate() {
        toast("Template copied.");
        await navigator.clipboard.writeText(JSON.stringify(activeTemplate, null, 2));
    }

    function pasteTemplate() {
        navigator.clipboard.readText().then(text => {
            try {
                const template = JSON.parse(text);
                template.name = activeTemplate.name;
                templateEditorRef.current.jsonEditor.set(template);
                onTemplateContentChanged(template);
                toast("Template pasted.");
            } catch (e) {
                toast.error("Invalid template.\n" + e);
            }
        });
    }

    useEffect(() => {}, [activeService]);

    function updateActiveService(service) {
        setActiveService(service);
        setCookie("lastService", service);
    }

    async function copyVariables() {
        toast("Variables copied.");
        await navigator.clipboard.writeText(JSON.stringify(cardConfig.variables, null, 2));
    }

    async function pasteVariables(createNew = false) {
        try {
            const variables = JSON.parse(await navigator.clipboard.readText());
            variableEditorRef.current.jsonEditor.set(variables);
            onVariablesChanged(variables);
            if (createNew) {
                window.location.href = "/ccg/create-card";
            }
            toast("Variables pasted.");
        } catch (e) {
            toast.error("Error: " + e);
        }
    }

    function renderPrompt() {
        function drawAspectRatios() {
            return (<span>Aspect Ratio: <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{flexGrow: 1}}>
                    <span>{aspectRatio}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className='scrollable-menu' style={{maxHeight: "200px", overflowY: "auto"}}>
                    <Dropdown.Item onClick={() => setAspectRatio("all")}>All</Dropdown.Item>
                    <Dropdown.Item onClick={() => setAspectRatio("4:3")}>4:3</Dropdown.Item>
                    <Dropdown.Item onClick={() => setAspectRatio("3:4")}>3:4</Dropdown.Item>
                    <Dropdown.Item onClick={() => setAspectRatio("1:1")}>1:1</Dropdown.Item>
                    <Dropdown.Item onClick={() => setAspectRatio("16:9")}>16:9</Dropdown.Item>
                    <Dropdown.Item onClick={() => setAspectRatio("9:16")}>9:16</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown></span>);
        }

        return renderTab("prompt", "Prompt", <>
                {!generating && <InputGroup className="mt-3" size="lg">
                    <FormControl
                        style={{flexGrow: 1, backgroundColor: "#ffffff"}}
                        onChange={(e) => {setGenerationPrompt(e.target.value)}}
                        size="lg"
                        as="textarea"
                        placeholder="Prompt"
                        aria-label="Prompt"
                        aria-describedby="basic-addon2"
                    />
                </InputGroup>}
                Generate Art <input type="checkbox" checked={generateArt} onChange={e => setGenerateArt(e.target.checked)} />
                {generateArt && activeService === "mj" && <div>{drawAspectRatios()}</div>}
                {generateArt && <span>
                      <SDJobSubmitter
                          onClick={() => updateActiveService('sd')}
                          selected={activeService === "sd"}
                          setSubmitCallback={c => services['sd'] = c} />
                      <MJJobSubmitter
                          onClick={() => updateActiveService('mj')}
                          selected={activeService === "mj"}
                          setSubmitCallback={c => services['mj'] = c}/>
                </span>}
                {!generating && <RightAlign>
                    <StyledButton label="Generate" onClick={() => {generateCard()}} style={{ display: 'block', marginLeft: 'auto', marginRight: '0' }} />
                </RightAlign>}
                {generating && <ProgressBar label="Generating" style={{width: windowWidth - 200, marginLeft: "auto", marginRight: "auto", bottom:10, position: "absolute"}} />}
            </>
        );
    }

    function renderSelectionDropdowns() {
        return (<div className="create-card-flex-between-container">
                <div className="create-card-flex-between-items">
                    <CCGCollectionChooser selected={cardConfig.collection} onSelected={(collectionName) => changeCollection(collectionName)}/>
                </div>
            <div className="create-card-flex-between-items">
                <CCGTemplateChooser collection={collection} selected={cardConfig.template} onSelected={(templateName) => changeTemplate(templateName)}/>
            </div>
            <div className="create-card-flex-between-items-grow"/>
            <div className="create-card-flex-between-items">
                {(dirtyTemplates.length > 0 || dirtyLayers.length > 0) && !loading &&
                    <StyledButton label="Save Modifications" onClick={() => saveModifications()} />}
            </div>
            <div className="create-card-flex-between-items">
                {!loading && <StyledButton label="Save Card" onClick={() => saveCard()} />}
            </div>
        </div>);
    }

    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175"}}>
                <div  style={{height: windowHeight - 80 - 25, overflow: "auto", paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
                    {content}
                </div>
            </Tab>
        );
    }

    function renderVariables() {
        return renderTab("variables", "Variables", <>
            <Editor ref={variableEditorRef}
                    value={cardConfig.variables}
                    style={{marginTop: 50}}
                    theme="ace/theme/github" onChange={onVariablesChanged} />
            <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-between-items-grow"}/>
                <div onClick={() => newCard()}><span style={{fontSize: 42}}><IoCreate/></span></div>
                <div onClick={() => copyImageDescription(cardConfig)}><span style={{fontSize: 42}}><MdImageSearch/></span></div>
                <div onClick={() => copyVariables()}><span style={{fontSize: 42}}><MdContentCopy/></span></div>
                <div onClick={() => pasteVariables()}><span style={{fontSize: 42}}><MdContentPaste/></span></div>
                <div onClick={() => pasteVariables(true)}><span style={{fontSize: 42}}><MdOpenInNew/></span></div>
                <div className={"create-card-flex-between-items-grow"}/>
            </div>
        </>);
    }

    function renderArt() {
        return renderTab("art", "Art", <>
            <div className="custom-scroll" style={{overflowY: "auto", flexGrow: 1, maxHeight: windowHeight - 200}}>
                <ArtworkMiniBrowser onArtworkSelected={image => applyCardImage(image.src)} />
            </div>
        </>);
    }

    function renderTemplates() {
        return renderTab("templates", "Templates", <>
            <CCGTemplateChooser collection={collection} selected={cardConfig.template} onTemplateSelected={(templateName) => changeTemplate(templateName)}/>
            <Editor ref={templateEditorRef}
                    value={activeTemplate}
                    style={{marginTop: 50}}
                    theme="ace/theme/github" onChange={onTemplateContentChanged} />
            <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-between-items-grow"}/>
                <div onClick={() => copyTemplate()}><span style={{fontSize: 42}}><MdContentCopy/></span></div>
                <div onClick={() => pasteTemplate()}><span style={{fontSize: 42}}><MdContentPaste/></span></div>
                <div className={"create-card-flex-between-items-grow"}/>
            </div>
        </>);
    }

    function renderLayers() {
        return renderTab("layers", "Layers", <>
            <div className={"create-card-flex-between-items-grow"}>
                <div>
                    <span>Active Layers</span>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span>{activeLayer && activeLayer.name ? activeLayer.name.toUpperCase() : "Select a layer"}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='scrollable-menu' style={{maxHeight: "200px", overflowY: "auto"}}>
                            {activeTemplate && activeTemplate.layers && activeTemplate.layers.map(layer => (<Dropdown.Item key={layer} onClick={() => handleLayerSelection(layer)}>{layer.toUpperCase()}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    <span>All Layers</span>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span>{activeLayer && activeLayer.name ? activeLayer.name.toUpperCase() : "Select a layer"}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='scrollable-menu' style={{maxHeight: "200px", overflowY: "auto"}}>
                            {layers && Object.keys(layers).map(layer => (<Dropdown.Item key={layer} onClick={() => handleLayerSelection(layer)}>{layer.toUpperCase()}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <Editor ref={templateLayerEditorRef}
                    value={activeLayer}
                    style={{marginTop: 50}}
                    theme="ace/theme/github" onChange={onLayerChanged} />
        </>);
    }

    const current = loaded && (

        <div className="flex-container" style={{marginTop: -32, marginRight: -32}}>
            <div style={{width: getDefaultWidth(), height: getDefaultHeight(), padding: 25}}>
                <CCGTemplate cardData={cardConfig}
                             setCardUrl={setCanvas}
                             width={getActiveWidth() - 50}
                             height={getActiveHeight() - 50}
                             exposedMethods={card}
                             collection={collection}
                             onDrawCard={canvas => canvas && setCanvas(canvas)}
                />
            </div>
            <div className="create-card-flex-column" style={{height: windowHeight, backgroundColor: "#0d0d0d", flexGrow: 1, paddingTop: 25}}>
                <div className="create-card-flex-items">
                        <Tabs activeKey={activeTab} defaultActiveKey="variables" id="uncontrolled-tab-example" onSelect={key => setActiveTab(key)}>
                            {renderPrompt()}
                            {renderVariables()}
                            {renderArt()}
                            {renderTemplates()}
                            {renderLayers()}
                        </Tabs>
                </div>
                <div className="create-card-flex-items">{renderSelectionDropdowns()}</div>
            </div>
        </div>
    )

    return (<PageHeader
                minimum={true}
                image={headerImage}
                title={"Create Card"}
                description={"Design and create a new card for your collection."}
                breadcrumb={[
                    ["Home", "/"],
                    ["CCG", "/ccg"],
                    ["Create Card", "/ccg/create-card"]
                ]} >{current}</PageHeader>);
}

export default CCGTemplates;