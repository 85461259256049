import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import {useIndexDB} from "./indexdb";
import {MdOutlineClose} from "react-icons/all";
import {Button} from "react-bootstrap";

const ImageSelectionContext = createContext();

export const useImageSelection = () => {
    return useContext(ImageSelectionContext);
};

export const ImageSelection = ({ children }) => {
    const { getValue, setValue } = useIndexDB();
    const [images, setImages] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            try {
                const storedImages = await getValue('selectedImages', []);
                setImages(storedImages);
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        }

        fetchImages();
    }, [getValue]);

    const addSelectedImage = useCallback(async (imageSrc) => {
        const updatedImages = [...images, imageSrc];
        await setValue('selectedImages', updatedImages);
        setImages(updatedImages);
    }, [images, setValue]);

    const addSelectedImages = useCallback(async (imageSrcs) => {
        const updatedImages = [...images, ...imageSrcs];
        await setValue('selectedImages', updatedImages);
        setImages(updatedImages);
    }, [images, setValue]);

    const removeSelectedImage = useCallback(async (imageSrc) => {
        const updatedImages = images.filter(src => src !== imageSrc);
        await setValue('selectedImages', updatedImages);
        setImages(updatedImages);
    }, [images, setValue]);

    const updateSelectedImages = useCallback(async (updatedImages) => {
        await setValue('selectedImages', updatedImages);
        setImages(updatedImages);
    }, [images, setValue]);

    const contextValue = {
        images,
        addSelectedImage,
        removeSelectedImage,
        updateSelectedImages,
        addSelectedImages
    }

    // Check if the url for the page contains /training
    // If it does, then render the image selection bar
    // Otherwise, don't render the image selection bar
    const showBar = !window.location.href.includes("/training");

    return (
        <ImageSelectionContext.Provider value={contextValue}>
            {children}
            {showBar && images && images.length > 0 && <div style={{
                display: 'flex',
                overflowX: 'scroll',
                position: 'fixed',
                bottom: 0,
                marginLeft: 64,
                width: '100%',
                background: 'linear-gradient(to top, black, transparent)'
            }}>
                {images.map((src, index) => (
                    <div key={index} style={{ position: 'relative', marginRight: 10 }}>
                        <img src={src.src} alt={`${src.alt}`} style={{ height: 100 }} />
                        <MdOutlineClose
                            style={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                cursor: 'pointer'
                            }}
                            onClick={() => removeSelectedImage(src)}
                        />
                    </div>
                ))}
            </div>}
            {showBar && images && images.length > 0 && <Button
                variant="danger"
                size="sm"
                style={{
                    borderRadius: '12',
                    position: 'fixed',
                    bottom: 10,
                    right: 10
                }}
                onClick={() => updateSelectedImages([])}
            >
                Clear
            </Button>}
        </ImageSelectionContext.Provider>
    );
};

export default ImageSelection;