import React, {forwardRef, useImperativeHandle, useEffect, useState} from 'react';
import {Form, Button, Table} from 'react-bootstrap';
import {MdAdd, MdDelete} from 'react-icons/md';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import ObfuscatedKey from "../obfuscatedkey";
import {useEndpoint} from "../../../hooks/api";

const KeyList = forwardRef(( props, ref ) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const [keys, setKeys] = useState([]);
    const [keyTypes, setKeyTypes] = useState([]);
    const { fetchAuth: listKeys } = useEndpoint(props.listEndpoint ?? "keys/list");
    const { fetchAuth: listKeyTypes } = useEndpoint(props.keyTypesEndpoint ?? "keys/key-types");
    const { fetchAuth: deleteKey } = useEndpoint(props.deleteEndpoint ?? "keys/delete");
    const { fetchAuth: enableKey } = useEndpoint(props.enableEndpoint ?? "keys/enable-key");
    const { fetchAuth: disableKey } = useEndpoint(props.disableEndpoint ?? "keys/disable-key");

    useImperativeHandle(ref, () => ({
        refresh() {
            fetchKeys();
        }
    }) );

    async function fetchKeys() {
        if (userInfo) {
            const keys = await listKeys();
            setKeys(keys);
        }
    }

    async function fetchKeyTypes() {
        if (userInfo) {
            const keyTypes = await listKeyTypes();
            setKeyTypes(keyTypes);
        }
    }

    useEffect(() => {
        fetchKeyTypes();
        fetchKeys();
    }, [userInfo]);
    async function refreshAccess() {
        await fetch("https://server.doubtech.com/server-to-server/refresh-access", {mode: 'no-cors'});
    }
    function handleDelete(key) {
        async function executeDeleteKey(key) {
            const result = await deleteKey({"key": key});
            if('error' in result) {
                toast.error(result.error);
            } else {
                fetchKeys();
            }
            await refreshAccess();
        }

        executeDeleteKey(key);
    }

    function handleEnabledChange(key) {
        console.log("Updating key to " + !key.enabled);
        key.enabled = !key.enabled;
        async function updateKey() {
            const token = await getAccessTokenSilently();
            const result = key.enabled ?
                await enableKey({"key": key.apikey}) :
                await disableKey({"key": key.apikey});
            if('error' in result) {
                toast.error(result.error);
            } else {
                fetchKeys();
            }
            await refreshAccess();
        }

        updateKey();
        setKeys(keys);
    }

    return (
        <Table striped bordered hover variant="dark">
            <thead>
            <tr>
                <th>Name</th>
                <th>Key Type</th>
                <th>Enabled</th>
                <th>Key</th>
            </tr>
            </thead>
            <tbody>
            {keys && keys.map(key => {
                return (<tr key={key.apikey}>
                    <td>{key.name}</td>
                    <td>{key.entitlement}</td>
                    <td style={{display: 'block', margin: "0 auto", textAlign: "center"}}>
                        <Form.Check type={"checkbox"} checked={key.enabled} onClick={() => handleEnabledChange(key)} />
                        {!key.enabled && <div>
                            <MdDelete onClick={() => handleDelete(key.apikey)}/>
                        </div>}
                    </td>
                    <td><ObfuscatedKey keyString={key.apikey} /></td>
                </tr>);
            })}
            </tbody>
        </Table>
    );
});

export default KeyList;
