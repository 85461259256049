import {Button, Modal} from "react-bootstrap";
import React from "react";

function ModalDialog({ title, acceptLabel = "OK", cancelLabel = "Cancel", onAccept, onCancel, show, onClose, children }) {
    const handleAccept = () => {
        if (onAccept) {
            onAccept();
        }
        onClose();
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>{cancelLabel}</Button>
                <Button variant="success" onClick={handleAccept}>{acceptLabel}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalDialog;
