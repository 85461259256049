import React, {useEffect, useState} from 'react';
import './chat-style-overrides.scss';
import {ConfigProvider, theme, TreeSelect} from "antd";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import {artApiFetchAsync, artApiFetchAuthAsync, useAuthenticatedArtApi, useLocalData} from "../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import AgentChooser from "../../components/chat/agentchooser";
import AgentChatWindow from "../../components/chat/agentChatWindow";
import {useIndexDB} from "../../providers/indexdb";
import {MdApps, MdContentPaste, MdNewLabel} from "react-icons/md";
import {MdCopyAll, MdShare} from "react-icons/all";
import useAgents from "./agenthook";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import ChatGrid from "../../components/chat/chatgrid/chatgrid";


const AgentChat = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const { getValue, setValue } = useIndexDB();
    const [ agent, setAgent ] = useState(undefined);
    // Get entitlements and check for gpt
    const params = new URLSearchParams(window.location.search);
    const agentKey = params.get('key');
    const [isEntitled, setIsEntitled] = useState(undefined);
    const { agents, namespaceList, agentCount } = useAgents(getAccessTokenSilently, artApiFetchAuthAsync);
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();

    useEffect(() => {

    }, [agentCount]);

    useEffect(() => {
        async function init() {
            if(agentKey) {
                console.log("Loading agent from key: ", agentKey);
                const agentResponse = await artApiFetchAsync("gpt/agent-info", "key=" + agentKey);
                if(agentResponse && !agentResponse.error) {
                    setAgent(agentResponse);
                } else {
                    setAgent(undefined);
                }
                return;
            } else {
                const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
                const entitlements = await artApiFetchAuthAsync(token, "entitlements-list");
                if(entitlements) {
                    setIsEntitled(entitlements.includes("gpt"));
                }
            }

            const agent = await getValue("agent-chat::selected-agent", null);
            if(agent) {
                setAgent(agent);
            }
        }

        init();
    }, []);

    useEffect(() => {}, [agent]);

    function onAgentSelected(agent) {
        setValue("agent-chat::selected-agent", agent);
        setAgent(agent);
    }

    function rightColumn() {
        return <>
        </>;
    }

    function content() {
        return <></>
    }

    const leftMenu = [
        {icon: MdApps, label: "Agents", dropdown: namespaceList.map((namespace) => {
                return {
                    label: namespace,
                    link: "/agent/" + namespace,
                    submenu: agents[namespace].map((agent) => {
                        return {
                            label: agent.name,
                            onClick: () => onAgentSelected(agent)
                        }
                    })
                }
            })}
    ];

    return (
        <PageHeader image={"/img/headers/agent-banner-wide.png"}
                    title={agent ? "Agent Chat - " + agent.name : "Agent Chat"}
                    description={"Chat with an agent that has already been built"}
                    minimum={true}
                    microfooter={true}
                    stickyfooter={true}
                    breadcrumb={[
                        ["Home", "/"],
                        ["GPT", "/gptinfo"],
                        ["Agent Chat", "/gpt/chat"]
                    ]}
                    menuleft={leftMenu}
        >
            {isEntitled !== undefined && !isEntitled && <div className={"alert alert-danger"}>You are not entitled to use this feature.</div>}
            {(!agentKey && isEntitled || agentKey && agent) && <>
                <div className={"mt-5"}>
                    {agent ? (
                        <div>
                            <AgentChatWindow agent={agent} agentKey={agentKey} scroll={true} height={windowHeight - 400} />
                        </div>
                    ) : (
                        <ChatGrid agents={agents} />
                    )}
                </div>
            </>}
            {agentKey && agent === undefined && <div className={"alert alert-danger"}>Agent not found or is no longer accessible.</div>}

        </PageHeader>);
}

export default AgentChat;