import React, {useState} from 'react';
import {FaCopy, FaEye, FaEyeSlash, FaPaste, FaTrash} from 'react-icons/fa';
import {toast} from "react-toastify";

const ObfuscatedKey = ({ keyString, showCopy=true, showPaste=false, onTokenSet=undefined, onDelete=undefined }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    const handleToggleReveal = () => {
        setIsRevealed(!isRevealed);
    };

    const handleDeleteKey = () => {
        if(onDelete) onDelete();
    }

    const handleCopyKey = () => {
        navigator.clipboard.writeText(keyString);
        toast.success('Copied to clipboard');
    };

    const handlePasteKey = () => {
        navigator.clipboard.readText().then(text => {
            if(onTokenSet) {
                // Limit text length to 512 characters max
                text = text.substring(0, Math.min(text.length, 512));
                onTokenSet(text);
            }
        });
    }

    const obfuscatedKey = keyString.replace(/[a-zA-Z0-9]/g, '*');

    return (
        <div className="obfuscated-key">
            <span className="obfuscated-key-content">
                {isRevealed ? keyString : obfuscatedKey}
                &nbsp;
                {keyString && <span className="obfuscated-key-icon" onClick={handleToggleReveal}>
                    {isRevealed ? <FaEyeSlash /> : <FaEye />}
                </span>}
                {onDelete && <span className="obfuscated-key-icon" onClick={handleDeleteKey}>
                    &nbsp;
                    <FaTrash />
                </span>}
                {showCopy && <span className="obfuscated-key-icon" onClick={handleCopyKey}>
                    &nbsp;
                    <FaCopy />
                </span>}
                {showPaste && <span className="obfuscated-key-icon" onClick={handlePasteKey}>
                    &nbsp;
                    <FaPaste />
                </span>}
            </span>
        </div>
    );
};

export default ObfuscatedKey;
