import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useJobStatusData} from "../../hooks/JobListData";
import ServerActiveJobImage from "../components/serveractivejobimage";
import {artApiFetchAsync, artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../hooks/artapi";
import useRandomImage from "../../hooks/RandomImage";
import {useAuth0} from "@auth0/auth0-react";
import {toast} from "react-toastify";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import StyledButton, {StyledLinkButton} from "../components/styledbutton";
import {useLoading} from "../components/loading";
import {isArray} from "jsoneditor/src/js/util";
import {useEntitlement} from "../../hooks/entitlements";
import {useEndpoint} from "../../hooks/api";


export default () => {
  const {isEntitled: isEntitledToAdmin}  = useEntitlement("admin");
  const [token, setToken] = React.useState(null);
  const [servers, setServers] = React.useState([]);
  const { jobs } = useJobStatusData();
  const [images, setTotalImages] = React.useState(0);
  const [queueStatus, setQueueStatus] = React.useState({'queued-assigned': 0, 'queued-unassigned': 0});
  const {loading, setLoading, setLoadingImage} = useLoading();
  let active = 0;
  let busy = 0;
  let serverCount = 0;
  const [headerImage] = useRandomImage("stable-diffusion");
  const [processing, setProcessing] = React.useState({'total': 0});
  const [nodeInfo, setNodeInfo] = React.useState({});
  const { fetchAuth: requestClearQueue } = useEndpoint("jobs-v2/clear-queue");
  const { fetchAuth: fetchServerStatus } = useEndpoint("server/status");

  useEffect(() => {
    if(headerImage) setLoadingImage(headerImage);
  }, [headerImage]);

  async function fetchImages() {
    const json = await artApiFetchAsync("art-info/count");
    if(json.error) {
      console.log("Error: ", json.error);
    } else {
      setTotalImages(json.total);
    }
  }
  async function fetchQueued() {
    const json = await artApiFetchAsync("jobs-v2/queue-count");
    if(json.error) {
      console.log("Error: ", json.error);
    } else {
      console.log("Queue Status: ", json);
      setQueueStatus(json);
    }
  }
  async function fetchProcessing() {
    const json = await artApiFetchAsync("jobs-v2/processing");
    if(json.error) {
      console.log("Error: ", json.error);
    } else {
      console.log("Processing: ", json);
      setProcessing(json);
    }
  }
  async function fetchNodeInfo() {
    const json = await artApiFetchAsync("jobs-v2/nodes");
    if(json.error) {
      console.log("Error: ", json.error);
    } else {
      console.log("Node Info: ", json);
      setNodeInfo(json);
    }
  }

  async function getServerStatus() {
    const json = await fetchServerStatus();

    if(json.error) {
      toast.error("Error: " + json.error, {autoClose: true});
      console.log("Error: ", json.error);
      return;
    } else {
      console.log("Status: ", json);
      setServers(json);
    }
  }

  async function initialize() {
    setLoading(true);
    await fetchImages();
    await fetchQueued();
    await fetchProcessing();
    await getServerStatus();
    await fetchNodeInfo();
    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);

  async function clearQueue() {
    const toastHandle = toast.info("Clearing Queue...", {autoClose: false});
    const json = await requestClearQueue();
    if(json.error) {
      toast.update(toastHandle, {render: "Error: " + json.error, type: toast.TYPE.ERROR, autoClose: true})
      console.log("Error: ", json.error);
      return;
    } else {
      await fetchQueued();
      toast.update(toastHandle, {render: "Queue Cleared, " + json.count + " jobs removed.", type: toast.TYPE.SUCCESS, autoClose: true});
      console.log("Status: ", json);
      setServers(json);
    }
  }

  console.log("Jobs: ", jobs);

  return (
<PageHeader image={headerImage}
            title={"Job Queue"}
            description={"View the current job queue and server status."}
            breadcrumb={[
              ["Home", "/"],
              ["Job Queue", "/jobs"]
            ]} >

  <section className='container-fluid black_more'>
        <div className='row m-10-hor'>

        <div className='col-md-5'>
            <div className='heading'>
              What's<span className='br'></span> Happening<span className='br'></span> Now
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
              Check out ongoing, recently completed, and upcoming jobs here.
            </div>
            <div className='content'>
              {queueStatus['queued-unassigned']} Jobs Queued (Unassigned)
              &nbsp;&nbsp;{isEntitledToAdmin && <StyledButton onClick={clearQueue} label={"Clear Queue"} /> }
              <span className='br'/>
              {queueStatus['queued-assigned']} Jobs Assigned<span className='br'/>
              {processing['total']} Jobs Processing<span className='br'/>
              {images} Total Images Generated
            </div>
          </div>

        </div>
  </section>

  <section className='container-fluid p-0'>
    <div className='row'>

      {servers && isArray(servers) && servers.map((job, index) => {
        console.log("Job: ", job);
          return index % 2 == 0 ? (
              <>
                <div className="col-md-6 px-0">
                  <ServerActiveJobImage node={job.name} />
                </div>

                <div className="col-md-6 centered p-md-5 pt-5">
                  <div className="p-md-5">
                    <div className="heading">
                      {job.name}
                    </div>
                    <div className="subheading">{job.status}</div>
                    {processing && job && job.node in processing.nodes
                        && processing.nodes[job.name].jobs
                        && processing.nodes[job.name].jobs.length > 0 && <div className="breadcumb">Jobs: {processing.nodes[job.name].jobs.length}</div>}

                    {nodeInfo && job && job.name in nodeInfo && nodeInfo[job.name].devices &&
                        <div className="breadcumb">{nodeInfo[job.name].devices[0].name.replace("cuda:0 ","")}</div>}

                    <br/>
                    <Link className='btn' to={"/job/current/" + job.name}>
                      <span className="shine"></span>
                      <span>More Detail</span>
                    </Link>
                  </div>
                </div>
              </>
          ) : (
              <>
                <div className="col-md-6 centered p-md-5 pt-5 pb-5">
                  <div className="p-md-5">
                    <div className="heading">
                      {job.name}
                    </div>
                    <div className="subheading">{job.status}</div>
                    {processing && job && job.node in processing.nodes
                        && processing.nodes[job.name].jobs
                        && processing.nodes[job.name].jobs.length > 0 && <div className="breadcumb">Jobs: {processing.nodes[job.name].jobs.length}</div>}

                    {nodeInfo && job && job.name in nodeInfo && nodeInfo[job.name].devices &&
                        <div className="breadcumb">{nodeInfo[job.name].devices[0].name.replace("cuda:0 ", "")}</div>}

                    <br/>
                    <StyledLinkButton to={"/job/current/" + job.name} label={"More Detail"} />
                  </div>
                </div>

                <div className="col-md-6 px-0">
                  <ServerActiveJobImage node={job.name} />
                </div>
              </>
          );
      })}

    </div>
  </section>
</PageHeader>

);
}
