import React, {useEffect} from 'react';

import {useAuth0} from '@auth0/auth0-react';
import {artApiFetchAsync, artApiFetchAuthAsync} from "./artapi";
import {getCacheValue, setCacheValue} from "./ccg/ccgdata";

export async function getMyProfileInfoAsync(token, refresh = false) {
    var profile = getCacheValue("profile", undefined);
    if(!profile || refresh) {
        profile = await artApiFetchAuthAsync(token, "profile");
        setCacheValue("profile", profile);
    }
    return profile;
}

export const checkEntitlement = (entitlement) => {
    const profile = getCacheValue("profile", undefined);
    return profile && profile.entitlements
        && profile.entitlements.indexOf(entitlement) >= 0;
}

export async function getProfile(token, user) {
    var profile = getCacheValue("profile/" + user, undefined);
    if(!profile) {
        profile = await artApiFetchAuthAsync(token, "profile", "user=" + user);
        setCacheValue("profile/" + user, profile);
    }
    return profile;
}

export default function useProfileInfo(user = undefined, refresh = false) {
    const { user: authUser, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [profileInfo, setProfileInfo] = React.useState(undefined);

    useEffect(() => {
        async function getProfileInfo() {
            if (user === undefined) {
                var info = getCacheValue("profile", undefined);
                if(!info || refresh) {
                    const token = await getAccessTokenSilently();
                    const info = await artApiFetchAuthAsync(token, "profile");
                    setCacheValue("profile", info);
                    setProfileInfo(info);
                } else {
                    setProfileInfo(info);
                }
            } else {
                const info = await artApiFetchAsync("profile", `user=${user}`);
                setProfileInfo(info);
            }
        }

        if(isAuthenticated && authUser) getProfileInfo();
    }, user === undefined ? [authUser] : [user, authUser]);

    return {profileInfo, setProfileInfo};
}