import React, {useEffect, useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {MdClose, MdDelete, MdEdit, MdSave} from 'react-icons/md';

const Message = ({ message, onDeleteMessage, onContentChange }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isContentChanged, setIsContentChanged] = useState(false);
    const [editedContent, setEditedContent] = useState(message.content ?? "");
    const cardBodyClass = isExpanded ? 'message-body-expanded' : 'message-body-collapsed';
    const textAreaRef = React.useRef(null);

    const preview = message.content.split('\n')[0]; // Display only the first line of the content as preview

    useEffect(() => {
        if (isEditMode && textAreaRef.current) {
            const textArea = textAreaRef.current;
            textArea.rows = 1;
            textArea.rows = Math.ceil(textArea.scrollHeight / 19);
        }
    }, [isEditMode]);

    useEffect(() => {
        setIsEditMode(false);
        setEditedContent(message.content);
    }, [message]);

    const handleToggleMessage = () => {
        setIsExpanded(!isExpanded);
        if(!isExpanded) setIsEditMode(false);
        setEditedContent(message.content);
    };

    const handleToggleEditMode = () => {
        setIsEditMode(!isEditMode);
        if(isEditMode) setEditedContent(message.content);
    };

    const handleDeleteMessage = () => {
        onDeleteMessage(message.id);
    };

    const handleContentChange = (event) => {
        setIsContentChanged(true);
        setEditedContent(event.target.value);
    };

    const handleSaveContent = (event) => {
        message.content = editedContent;
        onContentChange(message.id, editedContent);
        setIsEditMode(false);
        setIsContentChanged(false);
    }

    const handleContentRevert = (event) => {
        setIsEditMode(false);
        setEditedContent(message.content);
    }

    const renderCardBody = () => {

        if (isEditMode) {
            return (
                <div className={cardBodyClass}>
                    <textarea
                        ref={textAreaRef}
                        className="message-content-editable"
                        value={editedContent}
                        onChange={handleContentChange}
                        onInput={() => {
                            const textArea = textAreaRef.current;
                            textArea.rows = 1;
                            textArea.rows = Math.ceil(textArea.scrollHeight / 19);
                        }}
                        style={{ backgroundColor: 'transparent', color: 'white' }}
                        rows={1}
                    />
                    <div className="message-preview-wrapper mt-5 fadein">
                        <div className={"create-card-flex-between-container"}>
                            <MdSave onClick={handleSaveContent} />
                            <div className="create-card-flex-between-items-grow"/>
                            <MdDelete onClick={handleDeleteMessage} />
                            <div className="create-card-flex-between-items-grow"/>
                            <MdClose onClick={() => setIsEditMode(false)} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Accordion.Body>
                    <Card.Body className={cardBodyClass}>
                        <div className="message-content"><pre className={"wrapped-pre"}>{message.content}</pre></div>
                        {isExpanded && <div className={"create-card-flex-between-container mt-5 fadein"}>
                            {isContentChanged ? <MdSave onClick={handleSaveContent} /> : <MdEdit onClick={handleToggleEditMode} />}
                            <div className={"create-card-flex-between-items-grow"} />
                            <MdDelete onClick={handleDeleteMessage} />
                            <div className={"create-card-flex-between-items-grow"} />
                        </div>}
                    </Card.Body>
                </Accordion.Body>
            );
        }
    };

    function renderDefaultHeader() {
        return (
            <div>
                <div className={`message-header ${message.role}`}>
                    {message.role}
                </div>
                <div className="message-preview-wrapper">
                    {isExpanded ?
                        <div className={`message-preview fade-in`} style={{color: "#444444"}}>{preview}</div> :
                        <div className={`message-preview`}>{preview}</div>
                    }
                </div>
            </div>
        );
    }

    function renderEditHeader() {
        return (
            <div>
                <div className={`message-header ${message.role}`}>
                    {message.role}
                </div>
                <div className="message-preview-wrapper">
                    {isExpanded ?
                        <div className={`message-preview fade-in`} style={{color: "#444444"}}>{preview}</div> :
                        <div className={`message-preview`}>{preview}</div>
                    }
                </div>
            </div>
        );
    }


    return (
        <div key={message.id} className={`card`}>
            <Accordion activeKey={isExpanded ? message.id : ""} onSelect={handleToggleMessage}>
                <Accordion.Item eventKey={message.id}>
                    <div className="card-header" id="headingOne" style={{"cursor": "pointer"}} onClick={() => handleToggleMessage()}>
                        {isEditMode ? renderEditHeader() : renderDefaultHeader()}
                    </div>

                    <Accordion.Body className="card-body">
                        {renderCardBody()}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default Message;
