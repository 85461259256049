import React, {useEffect} from "react";
import {artApiFetch, artApiFetchAsync} from "./artapi";

export async function getRating(id, user) {
    const data = await artApiFetchAsync("get-rating", "id=" + id, "user=" + (user?.email ?? ""));
    return data['rating'] ?? 0;
}

export async function setRating(id, user, rating) {
    await artApiFetchAsync("rate", "id=" + id, "rating=" + rating, "user=" + (user?.email ?? ""));
    return getRating(id, user);
}

const useImageRating = (id, user) => {
    const [thumbsUp, setThumbsUp] = React.useState(false);
    const [thumbsDown, setThumbsDown] = React.useState(false);
    const [rating, setRating] = React.useState(0);

    useEffect(() => {
        artApiFetch("get-rating", "id=" + id, "user=" + (user ?? "")).then((data) => {
            const r = data['rating'];
            setRating(r);
            setThumbsUp(r > 0);
            setThumbsDown(r < 0);
            console.log("Rating: ", r)
        });
    }, [id, user])

    return { thumbsUp, thumbsDown, rating };
}

export default useImageRating;