import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import CCGTemplateChooser from "./templatechooser";
import {JsonEditor as Editor} from "jsoneditor-react18";
import {MdContentCopy, MdContentPaste} from "react-icons/md";
import ArtworkMiniBrowser from "./artworkminibrowser";
import RightColumnImageGrid from "./rightcolumngallery";
import useGalleryRequest from "../../../hooks/galleryapi";

const CardEditPane = ({card, advancedEdit=false, activeTab, windowWidth, windowHeight, onImageSelected, onVariablesChanged, onTemplateSelected}) => {
    const variableEditorRef = React.useRef({});
    const [currentTab, setActiveTab] = React.useState(activeTab ?? "variables");
    const [variableEditorWidth, setVariableEditorWidth] = React.useState(0);
    const isLarge = windowWidth > 1000;

    const {
        images: searchImages
    } = useGalleryRequest({maxWidth: 25, maxHeight: 25}, "art", "sort=timestamp", "order=desc", "sm[]=" + encodeURI(card.variables.name), "sm[]=" + encodeURI(`\\"card\\":\\"${card.variables.number}\\"`));
    const {
        images: titleSearch
    } = useGalleryRequest({maxWidth: 25, maxHeight: 25}, "art", "sort=timestamp", "order=desc", "sm=" + encodeURI(card.variables.name));


    function copyVariables() {
        navigator.clipboard.writeText(JSON.stringify(card.variables));
    }

    function pasteVariables() {
        navigator.clipboard.readText().then(text => {
            try {
                card.variables = JSON.parse(text);
            } catch (e) {
                console.error(e);
            }
        });
        variableEditorRef.current.jsonEditor.set(card.variables);
        onVariablesChanged(card.variables);
    }

    function renderVariables() {
        return renderTab("variables", "Variables",
            <div style={{minHeight: windowHeight - 115}}>
            {isLarge && <div style={{position: "absolute", top: 16, right: 16}}>
                <CCGTemplateChooser collection={card.collection} selected={card.template} onSelected={(templateName) => onTemplateSelected(card, templateName)}/>
             </div>}
            <Editor ref={variableEditorRef}
                    value={card.variables}
                    style={{marginTop: 50}}
                    theme="ace/theme/github" onChange={(v) => onVariablesChanged(card, v)} />
            {!isLarge && <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-between-items-grow"}/>
                <CCGTemplateChooser collection={card.collection} selected={card.template} onSelected={(templateName) => onTemplateSelected(card, templateName)}/>
                <div className={"create-card-flex-between-items-grow"}/>
            </div>}
            <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-between-items-grow"}/>
                <div onClick={() => copyVariables()}><span style={{fontSize: 42}}><MdContentCopy/></span></div>
                <div onClick={() => pasteVariables()}><span style={{fontSize: 42}}><MdContentPaste/></span></div>
                <div className={"create-card-flex-between-items-grow"}/>
            </div>
        </div>);
    }

    function applyCardImage(src) {
        onImageSelected(card, src);
    }

    function renderArt() {
        return renderTab("art", "Art", <>
            <div>
                {searchImages && searchImages.length > 0 && !searchImages[0].alt.startsWith("Waiting") && <RightColumnImageGrid images={searchImages} setSelectedImg={(image) => applyCardImage(image.src)} columns={3}/>}
                <ArtworkMiniBrowser onArtworkSelected={image => applyCardImage(image.src)} />
            </div>
        </>);
    }


    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175"}}>
                <div  style={{height: windowHeight - 80 - 25, overflow: "auto", paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
                    {content}
                </div>
            </Tab>
        );
    }

    return (<div className="create-card-flex-column" style={{height: windowHeight, backgroundColor: "rgba(13,13,13,0.74)", flexGrow: 1}}>
        <div className="create-card-flex-items">
            <Tabs activeKey={currentTab} defaultActiveKey="variables" id="uncontrolled-tab-example" onSelect={key => setActiveTab(key)}>
                {/*renderPrompt()*/}
                {renderVariables()}
                {renderArt()}
                {/*advancedEdit && renderTemplates()}
                {advancedEdit && renderLayers()*/}
            </Tabs>
        </div>
    </div>);
}

export default CardEditPane;