import {useEffect, useState} from "react";
import {db} from "../firebase/config";
import {get, onValue, ref} from "firebase/database";
import {artApiFetchAsync} from "./artapi";

export const useJobStatusData = ({node="", job = ""}={node: "", job: ""}) => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        async function fillJobs() {
            await artApiFetchAsync("jobs", `node=${node}`, `name=${job}`).then((jobs) => {
                console.log("Jobs: ", jobs);
                setJobs(jobs);
            });
        }

        fillJobs();
    }, [node]);

    return {jobs};
}

const useJobListData = (collection) => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const r = ref(db, collection);
        function fillResults(snap) {
            const jobs = [];
            snap.forEach(j => {
                jobs.push({
                    'key': j.key,
                    'data': j.val()
                })
            });
            setJobs(jobs);
        }

        get(r).then(fillResults).catch((err) => console.log(err));
        const unsub = onValue(r, fillResults);

        return () => unsub();
    }, [collection])

    return { jobs };
}

export default useJobListData;