import React from 'react';
import {Link} from "react-router-dom";
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import useRandomImage from "../../hooks/RandomImage";
//import MetaTags from 'react-meta-tags';
import useArtApi, {usePollingArtApi} from "../../hooks/artapi";
import {useJobStatusData} from "../../hooks/JobListData";
import {Helmet} from "react-helmet";

export default () => {
  const path = window.location.pathname;
  var pathSegments = path.split('/')
  var jobid = "";
  var node = "";
  if (pathSegments[pathSegments.length - 2] == "job") jobid = pathSegments[pathSegments.length - 1];
  else node = pathSegments[pathSegments.length - 1];
  const { jobs } = useJobStatusData({node: node, job: jobid});
  const job = jobs && jobs[0];
  if(job && job.name) jobid = job.name;

  const [selectedImg, setSelectedImg] = React.useState(null);


  const {response: jobData, error: imagesError} =
      job?.status !== "complete" ? usePollingArtApi(60 * 1000, "art", "group=" + encodeURI(jobid), "sort=timestamp", "order=desc")
          : useArtApi("art", {group: jobid, sort: "timestamp", order: "desc"});
  if(!node && jobData && jobData.length > 0) node = jobData?.worker;

  const [ randomHeader ] = useRandomImage("stable-diffusion");

  const images = [];
  if(jobData && jobData.length > 0) {
    images.pop();
    jobData.map(image => {
      images.unshift({
        src: image.url,
        alt: image.parameters.prompt ?? "",
        caption: "",
        width: image.width ?? 500,
        height: image.height ?? 500,
        prompt: image.parameters.prompt ?? "",
        id: image.id
      })
    });
  }

  const headerImage = images.length > 0 ? images[0].src : randomHeader;

  return (
      <div>

        <Helmet>
          <title>{"AI Art - " + jobid}</title>
          <meta id="meta-description" name="description" content={jobData?.parameters?.prompt}/>
          <meta id="og-title" property="og:title" content={"AI Art - " + job?.name}/>
          <meta id="og-image" property="og:image" content={headerImage}/>
        </Helmet>

        <section className='jumbotron breadcumb' style={{backgroundImage: `url(${headerImage})`}}>
          <div className='mainbreadcumb'>
            <div className='container-fluid'>
              <div className='row m-10-hor'>
                <div className='col-md-6'>
                  <h1>Job Status</h1>
                </div>
                <div className='col-md-6'>
                  <div className='list'>
                    <Link className='link' to="/home">Home</Link>
                    <span className='dash'>/</span>
                    <span>Job Status [{job?.name}] </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container-fluid black'>
          <div className='row m-10-hor'>

            <div className='col-md-5'>
              <div className='heading'>
                {job?.node?.toUpperCase()}
              </div>
              <div className='content'>
                {job?.status?.toUpperCase()}
              </div>
            </div>

            <div className='col-md-7'>
              <div className='content'>
                {jobData?.parameters?.prompt?.toUpperCase()}
              </div>
            </div>

          </div>
        </section>

        {images.length == 0 && <section className='container-fluid'>
            <div className='row m-10-hor'>
                <div className='col-md-12'>
                  {job?.status == "complete" && <div>Job resulted in no images.</div>}
                  {job?.status != "complete" && <div>Waiting for images...</div>}
                </div>
            </div>
        </section>}
        {images.length > 0 && <section className='container-fluid black_more'>
          <div className='row m-10-hor'>
            <div className='col-12'>
              <div className='AppContainer'>
                <ImageGallery images={images}/>
              </div>
            </div>

          </div>
        </section>}

        <Footer />
      </div>

  );
}
