import React, {useEffect} from "react";
import {artApiFetchAsync, artApiFetchAuthAsync} from "../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import {toast} from "react-toastify";

export function createPrompt(card) {
    let description = card.variables.imageDescription;
    description = description.replace(/^the image on the card (shows|depicts)?/gmi, "");

    const metadata = {
        'cardTitle': card.variables.name,
        'tags': [
            'card art'
        ]
    }
    if(card.collection) {
        metadata.tags.push(card.collection);
        if(card.collection === 'mtg') {
            metadata.tags.push('Magic the Gathering');
        }
    }
    if(card.id) metadata.id = card.id;
    if(card.deck) {
        metadata.deck = card.deck;
        metadata.tags.push(card.deck);
    }
    if(card.cardNumber) metadata.cardNumber = card.cardNumber;
    console.log("Metadata: ", metadata);

    return JSON.stringify(metadata) +"::.001 " + description;
}

export function copyImageDescription(card) {
    const description = createPrompt(card);

    navigator.clipboard.writeText(description);
    toast("Copied image description to clipboard.");
}

export default function useCardList(...queryParams) {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [images, setImages] = React.useState([{
        src: "/img/midjourney-square.png",
        alt: "Waiting for images...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    }]);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            if(isAuthenticated && !user) return;

            const fetchedImages = [];
            const token = isAuthenticated ? await getAccessTokenSilently() : null;
            const data = token ?
                await artApiFetchAuthAsync(token,'cards', ...queryParams) :
                await artApiFetchAsync('cards', ...queryParams);
            console.log("Cards: ", data);
            if(!data.error) {
                data.map(image => {
                    fetchedImages.push({
                        src: 'https://api.aiart.doubtech.com/card-image?id=' + image.id,
                        preview: `https://api.aiart.doubtech.com/card-image?id=${image.id}`,
                        alt: image.title,
                        id: image.id,
                        prompt: image.title,
                        width: 187,
                        height: 262,
                        caption: ""
                    });
                });
                setImages(fetchedImages);
                console.log("Fetched images: ", fetchedImages);
            } else {
                setError(data.error);
            }
        };
        fetchImages();
    }, [user]);

    return {images, error}
}
