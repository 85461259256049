import React, {useEffect, useState} from 'react';
import {ConfigProvider, theme, TreeSelect} from "antd";
import {artApiFetchAuthAsync} from "../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";


const AgentChooser = ({onAgentSelected}) => {
    const { getAccessTokenSilently, user } = useAuth0();

    const [ agents, setAgents ] = React.useState([]);
    const [ agent, setAgent ] = React.useState(null);
    const [ namespaces, setNamespaces ] = React.useState([]);

    useState(() => {
        async function init() {
            await fetchNamespaces();
            await fetchAgents();
        }

        init();
    }, []);

    async function fetchNamespaces() {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const namespaceResponse = await artApiFetchAuthAsync(token, "gpt/list-namespaces");
        if(namespaceResponse) {
            setNamespaces(namespaceResponse);
        }
    }

    async function fetchAgents() {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const agentResponse = await artApiFetchAuthAsync(token, "gpt/list-agents");
        if(agentResponse) {
            agentResponse.sort((a, b) => {
                if(a.namespace < b.namespace) return -1;
                if(a.namespace > b.namespace) return 1;
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            });
            let agents = {};
            for(let i = 0; i < agentResponse.length; i++) {
                if(!agents[agentResponse[i].namespace]) agents[agentResponse[i].namespace] = [];
                agents[agentResponse[i].namespace].push(agentResponse[i]);
            }
            setAgents(agents);
        }
    }

    const treeData = namespaces.map((namespace) => {
        const agentList = agents[namespace.namespace] || [];
        return {
            value: namespace.namespace,
            title: namespace.namespace,
            selectable: false,
            children: agentList.map((agent, index) => {
                return {
                    isLeaf: true,
                    value: namespace.namespace + ":" + index,
                    title: agent.name
                }
            })
        }
    });


    return (<ConfigProvider
                theme={{
                    algorithm: theme.darkAlgorithm,
                }}
            >
                <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Agent"
                    allowClear
                    treeData={treeData}
                    onChange={(value, data) => {
                        // Split value by first :
                        const parts = value.split(":");
                        const namespace = parts[0];
                        const index = parts[1];
                        const agent = agents[namespace][index];
                        console.log("Chose agent: ", value, data,agent);
                        onAgentSelected(agent)
                    }}
                />
            </ConfigProvider>);
}

export default AgentChooser;