import React, {useEffect} from 'react';
import ImageGallery from "../components/ImageGallery";
import {artApiFetchAsync, prepImages, useAuthenticatedArtApi,} from "../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import PromptTextBox from "../components/prompttextbox";
import SDJobSubmitter from "../components/sdjobsubmitter";
import MJJobSubmitter from "../components/mjjobsubmitter";
import {useCookies} from "react-cookie";
import PromptExample from "../components/promptexample";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import useInterval from "../components/interval";


const DreamHome = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [q, setQuery] = React.useState("");
    const model = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    const defaultImage = '/img/headers/dream.png';
    var headerImage = defaultImage;
    const [job, setJob] = React.useState(null);
    const [refreshRate, setRefreshRate] = React.useState(60 * 1000);
    const [endpoint, setEndpoint] = React.useState("");
    const [processing, setProcessing] = React.useState(false);
    const [images, setImages] = React.useState(null);
    const [activePrompt, setActivePrompt] = React.useState(undefined);
    const [savedState, setCookie, removeCookie] = useCookies(['dream']);
    const [activeService, setActiveService] = React.useState("sd");

    const [imageWidth, setImageWidth] = React.useState(512);
    const [imageHeight, setImageHeight] = React.useState(512);
    const [paramClassifier, setParamClassifier] = React.useState(7.5);
    const [numImages, setNumImages] = React.useState(4);
    const [samplingSteps, setSamplingSteps] = React.useState(50);
    const [samplingMethod, setSamplingMethod] = React.useState("k_lms");
    const [upscale, setUpscale] = React.useState(false);
    const [fixFaces, setFixFaces] = React.useState(false);
    const [jobImage, setJobImage] = React.useState(undefined);

    const services = {

    }

    const { response: prompts, error } = useAuthenticatedArtApi("prompts", {limit: 10});
    const { response: entitlements, error: entitlementError } = useAuthenticatedArtApi("entitlements-list");

    const processingImages = [{
        src: "/img/midjourney-square.png",
        alt: "Job is processing...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    }];

    useEffect(() => {
        fetchImages();
    }, [activePrompt, jobImage]);

    async function fetchImages() {
        if(!activePrompt) return;
        try {
            const images = await artApiFetchAsync("art", "limit=10", "sort=timestamp", "order=desc", "s=" + encodeURI(activePrompt));
            if(jobImage) {
                setImages(prepImages([jobImage, ...images]));
            } else {
                setImages(prepImages([images]));
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchJobData() {
        const info = await artApiFetchAsync("jobs-v2/job-status", "id=" + job.id);
        if("error" in info) {
            console.log("Error fetching job info: " + info.error);
            return;
        }
        setJob(info);
        console.log("Job info: ", info);
        const art = await artApiFetchAsync("jobs-v2/job-art", "id=" + job.id);
        if("error" in art) {
            console.log("Error fetching art: " + art.error);
        } else {
            setJobImage(art);
        }

    }

    useInterval(() => {
        if(job && job.status !== "complete") {
            fetchJobData();
        }
    }, 60 * 1000);

    useEffect(() => {
        setActiveService(savedState?.lastService ?? 'sd');
        setQuery(savedState?.lastPrompt ?? "");
    }, [savedState]);

    useEffect(() => {}, [activeService]);

    function updateActiveService(service) {
        console.log("Update active service to: " + service);
        setActiveService(service);
        setCookie("lastService", service);
    }

    async function submitJob(query=undefined) {
        if(query) setQuery(query);
        if(!q && !query) return;
        setActivePrompt(query);
        setJobImage(undefined);

        setCookie("lastPrompt", query);
        console.log("Current service: " + activeService, services[activeService].current);
        const job = await services[activeService](query);
        setJob(job);
    }

    function promptList() {
        if(!prompts || prompts.error) return [];
        return prompts;
    }

    function drawExamples() {
        return <>
            {promptList().map((prompt, index) => (<PromptExample key={index} prompt={prompt} onClick={() => submitJob(prompt)} />))}
        </>
    }

    function drawJobStatus() {
        return <>
            <div className='title'>
                <span>Processing your Request!</span><br/>
                <span style={{fontSize: 12}}>Status: {job.status ?? "queued"}</span>
            </div>
            <div className='desc'>
            {activePrompt && <blockquote>
                {'' + activePrompt}
            </blockquote>}
            {images && <ImageGallery images={images} /> }
            </div>
        </>
    }

    function drawWelcome() {
        return <>
            <div className='title'>
                <span>Submit a prompt to get started!</span>
            </div>
            <div className='desc'>
                Enter a prompt to create an image.  You can use the prompt textbox above or click on one of the prompts below.
                {drawExamples()}
            </div>
        </>
    }

    function content() {
        return <div className='content'>
            {job ? drawJobStatus() : drawWelcome() }
        </div>;
    }

    function handleChange(v) {
        console.log(v);
        setImageWidth(v);
    }

    return (
  <PageHeader image={headerImage} title={"Dream"}
              description={"Create beautiful images from a simple text prompt"}
              breadcrumb={[
                  ["Home", "/"],
                  ["Dream", "/dream"]
              ]} >

      <section className='container-fluid' id="blog">
          <div className='row m-10-hor'>
              <PromptTextBox className='m-10' placeholder="Enter a prompt to create an image" onChange={(v) => {console.log("changed to: " + v); setQuery(v)}} onClick={() => submitJob(q)}  style={{width: "100%"}} />

          </div>
          <div className='row m-10-hor mt-3'>
              <div className='col-md-8'>
                  <div className='left'>
                      {content()}
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='right'>
                      <SDJobSubmitter
                                      onClick={() => updateActiveService('sd')}
                                      selected={activeService === "sd"}
                                      setSubmitCallback={c => services['sd'] = c} />
                      <MJJobSubmitter
                                      onClick={() => updateActiveService('mj')}
                                      selected={activeService === "mj"}
                                      setSubmitCallback={c => services['mj'] = c}/>
                  </div>
              </div>
          </div>
      </section>

  </PageHeader>
);
}

export default DreamHome;