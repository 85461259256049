import React, {useEffect} from 'react';
import {artApiFetchAsync} from "../../hooks/artapi";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import SetCard from "../components/sets/SetCard";
import {Container} from "react-bootstrap";

import '../components/sets/setgallery.css';
import {useIndexDB} from "../providers/indexdb";

const SetsHome = () => {
    const [headerImage, setHeaderImage] = React.useState('/img/headers/sets.png');
    const [sets, setSets] = React.useState([]);
    const { getValue, setValue } = useIndexDB();

    async function fetchSets() {
        const cachedSets = await getValue("sets", []);
        setSets(cachedSets);

        const sets = await artApiFetchAsync("art-info/set-list");
        // Check for error
        if(sets.error) {
            console.log("Error fetching sets: ", sets.error);
        } else {
            // Get first object in sets
            const firstSet = sets[Object.keys(sets)[0]];
            setHeaderImage(firstSet.url);
            const preppedSets = Object.keys(sets).map((set, index) => sets[set]);
            setSets(preppedSets);
            setValue("sets", preppedSets);
            console.log("Sets: ", firstSet);
        }
    }

    useEffect(() => {
        fetchSets();
    }, []);

    useEffect(() => {}, [headerImage]);

    function content() {
        return <Container className="set-gallery">
            {sets.map((data, index) => <SetCard key={index} data={data} className={"set-card"} />)}
        </Container>
    }

    function rightColumn() {

    }

    return (<PageHeader image={headerImage} title={"Sets"}
                        description={"A collection of sets of prompts and images"}
                        breadcrumb={[
                            ["Home", "/"],
                            ["Sets", "/Sets"]
                        ]} >
            {content()}
        </PageHeader>
    );
}

export default SetsHome