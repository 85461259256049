// ImageUploader.js
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

function ImageUploader({ onImagesUpload }) {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleImageChange = (e) => {
        // Ensure that we have File objects
        if (Array.from(e.target.files).every(file => file instanceof File)) {
            setSelectedFiles(Array.from(e.target.files));
        } else {
            console.error("Selected items are not files.");
        }
    };

    const handleUpload = async () => {
        try {
            const base64Images = await Promise.all(selectedFiles.map(file => convertToBase64(file)));
            onImagesUpload(base64Images);
        } catch (error) {
            console.error("Error converting files to base64: ", error);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => reject(reader.error);
            reader.readAsDataURL(file);
        });
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>Select Images</Form.Label>
                <Form.Control
                    type="file"
                    accept="image/png, image/jpeg"
                    multiple
                    onChange={handleImageChange}
                />
            </Form.Group>
            <div className="d-flex flex-wrap mt-3">
                {selectedFiles.map((file, index) => (
                    <Card key={index} style={{ width: '100px', marginRight: '10px', marginBottom: '10px' }}>
                        <Card.Img variant="top" src={URL.createObjectURL(file)} alt={`preview-${index}`} />
                    </Card>
                ))}
            </div>
            <Button className="mt-3" onClick={handleUpload}>Upload</Button>
        </div>
    );
}

export default ImageUploader;
