import React, {useEffect} from 'react';
import CCGTemplate from "../../components/ccg/ccgtemplate";
import CardEditPane from "./cardeditpane";
import {MdClose, MdRefresh} from "react-icons/all";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {useCCGTemplates} from "../../../hooks/ccg/ccgdata";
import {loadImage} from "../../components/ccg/carddrawer";

const OverlayCardEditor = ({card, collection, overlayWidth, overlayHeight, onClose, onImageSelected, onVariablesChanged, onTemplateSelected, edit=true}) => {
    const localCard = {};
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const {templates} = useCCGTemplates(collection);

    if(!overlayWidth) overlayWidth = windowWidth;
    if(!overlayHeight) overlayHeight = windowHeight;

    console.log("Window size: ", windowWidth, windowHeight);
    useEffect(() => {}, [overlayWidth, overlayHeight]);

    function cardHeight() {
        return (overlayHeight - 50);
    }

    function cardWidth() {
        return cardHeight() * 2.5/3.5
    }

    async function findBestTemplate() {
        if(!templates) return null;
        if(!card) return null;
        if(!card.template) return null;
        let match = {
            matchedTemplate: null,
            matchedTemplates: [],
        }

        var templateList = Object.values(templates);
        for(var i = 0; i < templateList.length; i++) {
            const template = templateList[i];
            if(template.filters) {
                console.log(i + ": ", template.filters);
                let m = template.filters && template.filters.length > 0;

                for(var j = 0; j < template.filters.length && m; j++) {
                    const filter = template.filters[j];
                    if(filter.regex) {
                        const regex = new RegExp(filter.regex, "i");
                        if(filter.inverse) {
                            if (regex.test(card.variables[filter.variable])) {
                                m = false;
                            } else {
                                console.log(template.name + " Matched: ", template);
                            }
                        } else {
                            if (!regex.test(card.variables[filter.variable])) {
                                m = false;
                            } else {
                                console.log(template.name + " Matched: ", template);
                            }
                        }
                    }

                    if(filter.contains && Array.isArray(card.variables[filter.variable])) {
                        let found = false;
                        card.variables[filter.variable].forEach((v) => {
                            if(`${v}`.toLowerCase() === filter.contains.toLowerCase()) {
                                found = true;
                            }
                        });
                        if(filter.inverse) {
                            if(found) {
                                m = false;
                            }
                        } else {
                            if(!found) {
                                m = false;
                            }
                        }
                    }

                    if(m && filter.aspectratio) {
                        const ar = filter.aspectratio;
                        const img = await loadImage(card.image);
                        const imageAR = img.width / img.height;
                        if(ar.startsWith(">=")) {
                            if(imageAR < ar.substring(2)) {
                                m = false;
                            }
                        } else if(ar.startsWith("<=")) {
                            if(imageAR > ar.substring(2)) {
                                m = false;
                            }
                        } else if(ar.startsWith(">")) {
                            if(imageAR <= ar.substring(1)) {
                                m = false;
                            }
                        } else if(ar.startsWith("<")) {
                            if(imageAR >= ar.substring(1)) {
                                m = false;
                            }
                        } else if(ar.startsWith("=")) {
                            if(imageAR !== ar.substring(1)) {
                                m = false;
                            }
                        } else {
                            if(imageAR !== ar) {
                                m = false;
                            }
                        }
                        console.log(template.name + " AR Filter: ", imageAR, ar,m);
                    }
                }

                if(m) {
                    if (!match.matchedTemplate) match.matchedTemplate = template.name;
                    match.matchedTemplates.push(template.name);
                }
            }
        }

        console.log("Matched template: ", match);
        return match;
    }

    async function selectImage(card, image) {
        onImageSelected(card, image);
        const match = await findBestTemplate();
        console.log("Matched template: ", match.matchedTemplate);
        if(match.matchedTemplate) {
            console.log("Matched template: changing template to", match.matchedTemplate);
            changeTemplate(card, match.matchedTemplate);
        } else {
            localCard.redraw(card);
        }
    }

    function changeVariables(card, variables) {
        onVariablesChanged(card, variables);
        localCard.redraw(card);
    }

    function changeTemplate(card, template) {
        onTemplateSelected(card, template);
        localCard.redraw(card);
    }

    if(edit) {
        return card &&
            <div className={"create-card-flex-between-container"} style={{
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                padding: 25,
                backgroundColor: "rgba(0,0,0,0.87)"
            }}>
                <CCGTemplate cardData={card}
                             width={cardWidth()}
                             height={cardHeight()}
                             exposedMethods={localCard}
                             collection={collection}
                />
                {overlayWidth && overlayHeight &&
                    <CardEditPane styleName={"create-card-flex-between-grow"} card={card}
                                  windowWidth={overlayWidth - cardWidth()}
                                  windowHeight={overlayHeight}
                                  onImageSelected={(card, image) => selectImage(card, image)}
                                  onVariablesChanged={(card, variables) => changeVariables(card, variables)}
                                  onTemplateSelected={(card, template) => changeTemplate(card, template)}
                                  activeTab={card.image ? "variables" : "art"}/>}
                <div className={"create-card-flex-between-container"}
                     style={{position: "absolute", top: 8, left: 8, right: 8}}>
                    <MdRefresh className={"mdicon"} onClick={() => localCard.redraw(card)}/>
                    <div className={"create-card-flex-between-items-grow"}/>
                    <MdClose className={"mdicon"} onClick={() => {
                        onClose()
                    }}/>
                </div>
            </div>
    } else {
        return card &&
            <div className={"create-card-flex-between-container"} style={{
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                padding: 25,
                backgroundColor: "rgba(0,0,0,0.87)"
            }}>
                <div className={"create-card-flex-between-items-grow"}/>
                <CCGTemplate cardData={card}
                             width={cardWidth()}
                             height={cardHeight()}
                             exposedMethods={localCard}
                             collection={collection}
                />
                <div className={"create-card-flex-between-items-grow"}/>
                <div className={"create-card-flex-between-container"}
                     style={{position: "absolute", top: 8, left: 8, right: 8}}>
                    <MdRefresh className={"mdicon"} onClick={() => localCard.redraw(card)}/>
                    <div className={"create-card-flex-between-items-grow"}/>
                    <MdClose className={"mdicon"} onClick={() => {
                        onClose()
                    }}/>
                </div>
            </div>
    }
}

export default OverlayCardEditor;