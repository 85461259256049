import React, {useState} from 'react';
import Footer from '../components/footer';

import {Alert, Button, Card, Form} from 'react-bootstrap';
import {useAuth0} from "@auth0/auth0-react";
import Auth0LogoutButton from "../components/auth0logoutbutton";
import useProfileInfo from "../../hooks/profileinfo";
import UploadBlob from "../components/uploadblob";
import {artApiFetchAuthAsync} from "../../hooks/artapi";
import {useLoading} from "../components/loading";
import {CollapsibleCard, CollapsibleCardContent} from "../components/CollapsableCard";
import NewKeyForm from "../components/apikeys/newkeyform";
import KeyList from "../components/apikeys/keylist";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import ServiceList from "../components/apikeys/servicelist";
import TermsOfServiceModal from "../components/apikeys/termsofservice";
import * as PropTypes from "prop-types";

function StylizedButton(props) {
    return null;
}

StylizedButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node
};
export default function() {
    const {isAuthenticated, user: currentUser, getAccessTokenSilently} = useAuth0();
    const [error, setError] = useState("");
    const {profileInfo, setProfileInfo} = useProfileInfo();
    const [name, setName] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const {loading, setLoading} = useLoading();
    const [imageIteration, setImageIteration] = useState('');
    const keyListRef = React.useRef(null);
    const serviceListRef = React.useRef(null);
    const [modalShow, setModalShow] = useState(false);

    console.log("profileInfo: ", profileInfo);

    async function handleSubmit(event) {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const info = await artApiFetchAuthAsync(token,"profile", "set", `displayname=${encodeURIComponent(profileInfo.info.displayname)}`);
        setProfileInfo(info);
        setLoading(false);
    }

    async function onProfileUploaded(u) {
        console.log("New profile image url: " + u);
        setLoading(true);
        const token = await getAccessTokenSilently();
        const info = await artApiFetchAuthAsync(token,"profile", "set", `avatar=${encodeURIComponent(u)}`);
        setProfileInfo(info);
        setImageIteration(imageIteration + 1);
        setLoading(false);
    }

    function renderContent() {
        return (<div>
            <CollapsibleCard title="Edit Profile">
                <CollapsibleCardContent>
                    <p>Here you can view and update your profile information.</p>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {profileInfo && profileInfo.info.avatar && <img height="32" width="32" src={`https://api.aiart.doubtech.com/profile-image?user=${profileInfo.info.uid}&${imageIteration}`} alt="Upload" />}
                        {profileInfo && <UploadBlob types={['image/png', 'image/jpeg']} collection={"profile-images"} label="Change Profile Photo" onUploaded={u => onProfileUploaded(u)} />}
                    </div>
                    {profileInfo && <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={profileInfo.info.displayname}
                                onChange={event => {
                                    setProfileInfo({...profileInfo, info: {...profileInfo.info, displayname: event.target.value}});
                                }}
                            />
                        </Form.Group>
                        <div className={"create-card-flex-between-container"}>
                            <div className={"create-card-flex-between-items-grow"}/>
                            <Button type="submit">Update Profile</Button>
                        </div>
                    </Form>}
                </CollapsibleCardContent>
            </CollapsibleCard>

            <CollapsibleCard title="3rd Party Service API Tokens" className={"mt-5"} expand={true}>
                <CollapsibleCardContent>
                    <p>Control access to 3rd party services used to generate content. Adding services here will give you access to additional features. <a onClick={() => setModalShow(true)} style={{
                        color: "white",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}>Terms of Service</a></p>

                    <ServiceList ref={serviceListRef}/>
                </CollapsibleCardContent>
            </CollapsibleCard>

            <CollapsibleCard title="Server API Tokens" className={"mt-5"} expand={true}>
                <CollapsibleCardContent>
                    <p>Here you can view and manage your API keys for using AI apis.<br/>
                        <a href={"https://server.doubtech.com/docs"}>API Doc</a></p>

                    <KeyList ref={keyListRef} />

                    <NewKeyForm onCreate={() => {keyListRef.current.refresh()}} />
                </CollapsibleCardContent>
            </CollapsibleCard>
        </div>);
    }

    return (
        <PageHeader image={"/img/headers/portal.png"} title={"Profile"}
                    description={"Manage your profile"}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Profile", "/profile"]
                    ]} >
      <section className='container-fluid black_more'>
        <div className='row'>
          <div className='col-md-6'>
           <div className="text-side">
               {renderContent()}
           </div>
          </div>
          <div className='col-md-6'>
            <div className="form-side">
                <Card>
                    <Card.Header>
                        <div className={"create-card-flex-between-container"}>
                            <div><h4>Account</h4></div>
                            <div className={"create-card-flex-between-container"}/>
                            <Auth0LogoutButton />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className={"create-card-flex-between-container"}>
                            <div>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <h5>Email</h5>
                                <span>{currentUser && currentUser.email}</span>
                                <h5 className={"mt-2"}>Name</h5>
                                <span>{currentUser && currentUser.name}</span>
                            </div>
                            <div className={"create-card-flex-between-container"}/>
                            <div>
                                {currentUser && <img width={128} src={currentUser.picture} alt={currentUser.name} />}
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <Card className={"mt-5"}>
                    <Card.Header>
                        <div className={"create-card-flex-between-container"}>
                            <h5>Entitlements</h5>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {profileInfo && profileInfo.entitlements && <>
                            <ul>
                                {profileInfo.entitlements.map(entitlement => (<li key={entitlement}>{entitlement}</li>))}
                            </ul></>}
                    </Card.Body>
                </Card>
            </div>
          </div>
        </div>
      </section>

        <TermsOfServiceModal
            show={modalShow}
            handleClose={() => setModalShow(false)}
        />
    </PageHeader>
  );
}
