import {loadImage} from "./carddrawer";

export function calculateRows(pageHeight, card, dpi) {
    return Math.floor(pageHeight / (card.height * dpi));
}

export function calculateColumns(pageWidth, card, dpi) {
    return Math.floor(pageWidth / (card.width * dpi));
}


export default async function drawPrinterPage(selectedCards, canvas, width, height, dpi = 300, drawCards = true) {
    if(!canvas) return {filled: 0, total: -1};
    if(!selectedCards) return {filled: 0, total: -1};

    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.scale(canvas.width / width, canvas.height / height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);

    if(selectedCards.length === 0) {
        ctx.restore();
        return {filled: 0, total: -1};
    }

    const cols = calculateColumns(width, selectedCards[0], dpi);
    const rows = calculateRows(height, selectedCards[0], dpi);

    const remainingVSpace = height - rows * selectedCards[0].height * dpi;
    const remainingHSpace = width - cols * selectedCards[0].width * dpi;

    const spacingX = remainingHSpace / (cols + 1);
    const spacingY = remainingVSpace / (rows + 1);

    const cardWidth = selectedCards[0].width * dpi;
    const cardHeight= selectedCards[0].height * dpi;

    var x = spacingX;
    var y = spacingY;


    async function strokeRoundedRect(ctx, x, y, width, height, radius) {
        ctx.strokeStyle = "black";
        ctx.lineWidth = .01 * dpi;
        ctx.fillStyle = "black";
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    }

    async function drawLine(ctx, x1, y1, x2, y2, thinColor="cyan", thickColor = "black") {
        ctx.strokeStyle = thickColor;
        ctx.lineWidth = .01 * dpi;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();

        ctx.strokeStyle = thinColor;
        ctx.lineWidth = .01 * dpi / 3;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    }

    for (var i = 0; i < cols; i++) {
        if(drawCards) {
            await drawLine(ctx, x, 0, x, height);
            await drawLine(ctx, x + cardWidth, 0, x + cardWidth, height);
        }
        if(i > 0) {
            await drawLine(ctx, x - spacingX / 2, 0, x - spacingX / 2, height, "red", "black");
        }
        x += cardWidth + spacingX
    }

    for (var i = 0; i < rows; i++) {
        if(drawCards) {
            await drawLine(ctx, 0, y, width, y);
            await drawLine(ctx, 0, y + cardHeight, width, y + cardHeight);
        }
        if(i > 0) {
            await drawLine(ctx, 0,  y - spacingY / 2, width, y - spacingY / 2, "red", "black");
        }
        y += cardHeight + spacingY
    }

    x = spacingX;
    y = spacingY;

    if(!drawCards) {
        for(var i = 0; i < rows * cols; i++) {
            await strokeRoundedRect(ctx, x, y, 2.5 * dpi, 3.5 * dpi, 0.19685/2 * dpi);

            x += 2.5 * dpi + spacingX;
            if(x + 2.5 * dpi > width) {
                x = spacingX;
                y += 3.5 * dpi + spacingY;
            }
        }
    } else {

        for (var index = 0; index < selectedCards.length; index++) {
            const card = selectedCards[index]
            if(card.image && card.image.src) {
                ctx.fillStyle = "black";
                ctx.fillRect(x - 20, y - 20, card.width * dpi + 40, card.height * dpi + 40);


                if(card.image instanceof HTMLCanvasElement) {
                    ctx.drawImage(card.image, x, y, card.width * dpi, card.height * dpi);
                } else {
                    const img = await loadImage(card.image.src);
                    ctx.drawImage(img, x, y, card.width * dpi, card.height * dpi);
                }

                x += card.width * dpi + spacingX;
                if (x + card.width * dpi > width) {
                    x = spacingX;
                    y += card.height * dpi + spacingY;
                }
            }
        }
    }

    ctx.restore();

    return {rows: rows, cols: cols, filled: selectedCards.length, total: rows * cols};
}