import React, {useEffect} from 'react';
import {getPreviewUrl} from "../../../hooks/ImageData";
import {artApiFetchAsync} from "../../../hooks/artapi";
import useInterval from "../interval";
import {Form, InputGroup, ProgressBar} from "react-bootstrap";
import ImageGallery from "../ImageGallery";
import {useNavigate} from "react-router-dom";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";

import SelectIcon from '@mui/icons-material/SelectAll';
import {useImageSelection} from "../../providers/selection";

export function prepQueryParameters(queryParameters) {
    return Object.keys(queryParameters).map(
        (param) => {
            if(param.startsWith("q[]=")) return param;
            else return "q[]=" + param + "=" + encodeURI(queryParameters[param]);
        }
    )
}
export function prepFields(fields) {
    return Object.keys(fields).map(
        (param) => {
            const value = fields[param];
            // If value is an array return tag[]=value1&tag[]=value2...
            if(Array.isArray(value)) {
                return value.map(v => param + "[]=" + encodeURI(v)).join("&");
            }
            return param + "=" + encodeURI(fields[param])
        }
    );
}

export async function fetchRandomImage(queryParameters = {}, fields={sort: "timestamp", limit: 100, order: "desc", s:""}) {
    const images = await artApiFetchAsync("art", ...prepFields(fields), "ultrafast=true", ...prepQueryParameters(queryParameters));
    // Grab a random image url from images if "error" wasn't returned
    if(!images.error) {
        return images[Math.floor(Math.random() * images.length)].url;
    }
    return images;
}

export async function fetchImagesUltraFast(queryParameters = {}, fields={sort: "timestamp", limit: 100, order: "desc", s: ""}) {
    const parameters = [...prepFields(fields), "ultrafast=true", ...prepQueryParameters(queryParameters)]
    const images = await artApiFetchAsync("art", ...parameters);
    return images;
}

export async function fetchImages(queryParameters = {}, fields={sort: "timestamp", limit: 100, order: "desc", s: "", fast: false}) {
    const parameters = [...prepFields({
        sort: "timestamp",
        limit: 100,
        fast: false,
        ...fields
    }), ...prepQueryParameters(queryParameters)]
    const images = await artApiFetchAsync("art", ...parameters);
    return images;
}

const ArtGallery = ({showSearch=true, onImagesUpdated, fields={}, queryParameters = {}, allowUltrafast=true, refreshRate=60, sort="timestamp", limit=100, order="desc", search, waitingImage=[{
    src: "/img/midjourney-square.png",
    alt: "Waiting for images...",
    caption: "Waiting for images....",
    width: 500,
    height: 500
}], children}, showSearchBar=true) => {
    const query = new URLSearchParams(window.location.search);
    const [q, setQuery] = React.useState(search ?? query.get("q") ?? "");
    const [images, setImages] = React.useState(waitingImage);
    const [loading, setLoading] = React.useState(false);
    const [immediate, setImmediate] = React.useState(true);
    const [searching, setSearching] = React.useState(false);
    const navigate = useNavigate();
    const [imageIteration, setImageIteration] = React.useState(0);
    const [currentLimit, setLimit] = React.useState(limit);
    const [lastQueryParameters, setLastQueryParameters] = React.useState(JSON.stringify(queryParameters));
    const [lastFieldParameters, setLastFieldParameters] = React.useState(JSON.stringify(fields));
    const [selectionMode, setSelectionMode] = React.useState(false);
    const {addSelectedImage, removeSelectedImage} = useImageSelection();

    useEffect(() => {
        const paramStr = JSON.stringify(queryParameters);
        const fieldStr = JSON.stringify(fields);
        if(paramStr !== lastQueryParameters || fieldStr !== lastFieldParameters) {
            setImmediate(true);
            setImages(waitingImage);
            fetchAndUpdateImages(false);
            if(paramStr !== lastQueryParameters) setLastQueryParameters(paramStr);
            if(fieldStr !== lastFieldParameters) setLastFieldParameters(fieldStr);
        }
    }, [queryParameters, fields]);


    useEffect(() => {
        console.log("Images refreshed!");
    }, [imageIteration]);

    function loadImages(modelImages) {
        const preppedImages = [];
        modelImages.map(image => {
            if( image.url.startsWith("https://media.discordapp.net/attachment")) {
                image.url = image.url.replace("https://media.discordapp.net/attachment", "https://cdn.discordapp.com/attachments");
            }

            const imageData = {
                ...image,
                src: image.url,
                preview: image.preview ?? image.url,
                alt: image.alt ?? image.parameters.prompt ?? "",
                caption: image.title ?? "",
                width: image.width ?? 500,
                height: image.height ?? 500,
                prompt: image.parameters.prompt ?? "",
                id: image.id,
                source: image.source,
                set_name: image.set_name
            };

            image.preview = getPreviewUrl(image);
            preppedImages.push(imageData);
        });

        setImmediate(false);
        setLoading(false);
        // compare current images and preppedImages. If they are different apply changes.
        if(JSON.stringify(images) === JSON.stringify(preppedImages)) return;
        setImages(preppedImages);
        setImageIteration(imageIteration + 1);
        onImagesUpdated && onImagesUpdated(preppedImages);
    }

    async function fetchAndUpdateImages(fast) {
        window.fetching = true;
        let modelImages = [];
        if(allowUltrafast) {
            modelImages = await fetchImagesUltraFast(queryParameters, {
                sort: sort,
                limit: currentLimit,
                order: order,
                s: q, ...fields
            });

            if ("error" in modelImages) {
                console.log("Error fetching images: ", modelImages.error);
            } else {
                loadImages(modelImages);
            }
        }

        //const {response: modelImages, error: imagesError} = usePollingArtApi(60 * 1000, "art", "q[]=model=" + model, "limit=" + limit, "sort=timestamp", "order=desc", "s=" + encodeURI(q));
        modelImages = await fetchImages(queryParameters, {sort: sort, limit: currentLimit, order: order, s: q, fast: fast, ...fields})
        if("error" in modelImages) {
            console.log("Error fetching images: ", modelImages.error);
        } else {
            loadImages(modelImages);
        }
        window.fetching = false;
    }

    useInterval(() => {
        async function fetchImages() {
            if(window.fetching) return;
            await fetchAndUpdateImages(false);
        }

        if(!q || q.length === 0) fetchImages();
    }, refreshRate * 1000, immediate);




    useEffect(() => {
        async function startSearch() {
            await fetchAndUpdateImages(false);
            setSearching(false);
        }

        if(q && q.length > 1) {
            console.log("Searching for: ", q);
            const search = q;
            setSearching(true);
            if(window.searchIntervalId) clearInterval(window.searchIntervalId);
            const id = window.searchIntervalId = setInterval(() => {
                if(search === q) {
                    startSearch();
                    clearInterval(id);
                }
            }, 1000);
        }
    }, [q]);

    return <>
        {showSearch && <section className='container-fluid black_more'>
            <div className='row m-10-hor'>
                <div className='col-12'>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            value={q}
                            onChange={e => setQuery(e.target.value)}
                            onKeyPress={e => {
                                if (e.key === 'Enter' || e.charCode === 13) {
                                    // Add q= to the query string for the current page
                                    const url = new URL(window.location.href);
                                    url.searchParams.set("q", q);
                                    navigate(url.pathname + url.search);
                                }
                            }}
                        />
                    </InputGroup>
                    {searching && <ProgressBar
                        now={100}
                        animated={true}
                        striped={true}
                        variant="warning"
                        label={"Searching..."}
                    />}
                </div>
            </div>
        </section>}

        {children}

        <ImageGallery images={images} onSelectImage={(image, index) => {
            if(selectionMode) {
                addSelectedImage(image);
                return true;
            }
        }}/>

        <div style={{width: "100%", justifyContent: "center", display: "flex"}}>
            {loading && <ProgressBar label={"Loading..."} style={{minWidth: 109, minHeight: 36}} now={100}/>}
            {!loading && <div style={{margin: "0 auto"}} className="btn" onClick={() => {
                setLoading(true);
                if (images && images.length < currentLimit) {
                    fetchAndUpdateImages(false);
                    return;
                }
                setLimit(currentLimit + 100);
                fetchAndUpdateImages(false);
            }} text="Load more images">
                <span className="shine"></span>
                <span>Load More</span>
            </div>}
        </div>

        <SpeedDial
            sx={{ position: 'fixed', bottom: 16, left: 2,
                color: "#ff0000"}}
            FabProps={{
                sx: {
                    bgcolor: '#b29175',
                    '&:hover': {
                        bgcolor: '#646442',
                    }
                }
            }}
            icon={<SpeedDialIcon />}
            ariaLabel={"Selection Menu"}>
            <SpeedDialAction
                icon={<SelectIcon/>}
                tooltipTitle={selectionMode ? "Gallery Mode" : "Selection Mode"}
                onClick={() => {
                    setSelectionMode(!selectionMode);
                }}
                sx={{
                    color: selectionMode ? "#ccccff" : "#ffffff",
                    backgroundColor: selectionMode ? '#333333' : '#222222', // Replace 'desiredColor' with the color you want
                    '&:hover': {
                        backgroundColor: selectionMode ? '#555555' : '#444444'
                    }
                }}
            />
        </SpeedDial>
    </>
}

export default ArtGallery;