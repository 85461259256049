import React, {useEffect, useState} from 'react';
import {Form, InputGroup, ListGroup, Stack} from 'react-bootstrap';
import {toast} from "react-toastify";
import {useEndpoint} from "../../../hooks/api";
import Badge from "react-bootstrap/Badge";
import Checkpoints from "./checkpoints";

function TagInput({ tags, setTags }) {
    const [input, setInput] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let value = input.trim();

            if(!tags) tags = [];

            if (value && !tags.includes(value)) {
                setTags([...tags, value]);
            }

            setInput('');
        }
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    return (
        <>
        <InputGroup>
            <Form.Control
                type="text"
                value={input}
                onKeyDown={handleKeyDown}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Add a tag"
            />
        </InputGroup>
        <Stack direction={"horizontal"} gap={2}>
            {tags && tags.map((tag, index) => (
                <Badge key={index} pill bg="secondary" className="me-3" style={{cursor: "pointer"}} onClick={() => removeTag(tag)}>
                    {tag}
                </Badge>
            ))}
        </Stack>
        </>
    );
}

function PromptVariables({preset, onVariablesChanged, showPrompt=false}) {
    const [variables, setVariables] = useState({});
    const [variableValueIteration, setVariableValueIteration] = useState(0);
    const { fetchAuth: fetchPreset } = useEndpoint("stable-diffusion/preset-info");

    useEffect(() => {
        if(!preset) return;

        async function fetchPresetAsync() {
            const presetInfo = await fetchPreset({"id": preset.id});
            if ("error" in preset) {
                toast.error("Failed to fetch preset.\n" + preset.error);
            } else {
                setVariables(presetInfo.variables);
                setVariableValueIteration(variableValueIteration + 1);
            }
        }

        if(!preset.variables) {
            fetchPresetAsync();
        } else {
            setVariables(preset.variables);
            setVariableValueIteration(variableValueIteration + 1);
        }

    }, [preset]);

    return <>
        <Form.Group>
            <ListGroup>
                {Object.keys(variables).map((name, index) => {
                    if(name === "prompt" && !showPrompt) return null;

                    const variable = variables[name];
                    let type = "text"; // Default type
                    let inputElement = null;
                    const variableType = variable.type;

                    // Determine the type based on the variable name
                    if (variableType === "int" || variableType === "float") {
                        type = "number";
                    } else if (variableType === "list[string]") {
                        type = variableType; // Assuming you want to input lists as comma-separated values
                    } else if (variableType === "prompt") {
                        type = "textarea";
                    } else if (variableType === "private") {
                        type = "checkbox";
                    } else if (variableType === "image") {
                        type = variableType;
                    } else if (variableType === "checkpoint") {
                        type = variableType;
                    }

                    // Create the appropriate input element based on the type
                    if(type === "textarea") {
                        inputElement = <Form.Control as="textarea" rows={3} placeholder={variable.default} value={variable.value} onChange={(event) => {
                            variable.value = event.target.value;
                            setVariables({...variables});
                            onVariablesChanged(variables);
                        }} />;
                    } else if (type === "checkbox") {
                        inputElement = <Form.Check type={type} checked={variable.value} onChange={(event) => {
                            variable.value = event.target.checked;
                            setVariables({...variables});
                            onVariablesChanged(variables);
                        }}/>;
                    } else if (type === "list[string]") {
                        inputElement = <TagInput tags={variable.value} setTags={(tags) => {
                            variable.value = tags;
                            setVariables({...variables});
                            onVariablesChanged(variables);
                        }}/>;
                    } else if (type === "image") {
                        inputElement = <>
                            <Form.Control type={"text"} placeholder={variable.default ?? "image url"}
                                          value={variable.value} onChange={(event) => {
                                variable.value = event.target.value;
                                setVariables({...variables});
                                onVariablesChanged(variables);
                            }}/>
                            {variable.value && <img src={variable.value} width={"100%"}/>}
                        </>;
                    } else if (type === "checkpoint") {
                        inputElement = <Checkpoints checkpoint={variable.value} onSelect={(checkpoint) => {
                            variable.value = checkpoint;
                            setVariables({...variables});
                            onVariablesChanged(variables);
                        }} />
                    } else {
                        inputElement = <Form.Control type={type} placeholder={variable.default} value={variable.value} onChange={(event) => {
                            variable.value = event.target.value;
                            setVariables({...variables});
                            onVariablesChanged(variables);
                        }} />;
                    }

                    return <ListGroup.Item key={index}>
                        <Form.Label>{name}</Form.Label>
                        {inputElement}
                    </ListGroup.Item>;
                })}
            </ListGroup>
        </Form.Group>

    </>
}

export default PromptVariables;
