import React, {useEffect, useRef, useState} from 'react';
import './chat-style-overrides.scss';
import {ConfigProvider, theme, TreeSelect} from "antd";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import {artApiFetchAsync, artApiFetchAuthAsync, useAuthenticatedArtApi, useLocalData} from "../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import AgentChooser from "../../components/chat/agentchooser";
import AgentDataBuilderWindow from "../../components/chat/agentChatWindow";
import {useIndexDB} from "../../providers/indexdb";
import {JsonEditor} from "jsoneditor-react18";
import PromptInput from "../../components/gpt/promptinput";
import {toast} from "react-toastify";
import ReactJsonViewCompare from 'react-json-view-compare';
import {Button, Form, Overlay, Tooltip} from "react-bootstrap";
import {MdAdd, MdCopyAll} from "react-icons/all";
import {MdContentPaste, MdNewLabel} from "react-icons/md";
import {useEntitlement} from "../../../hooks/entitlements";
import {useEndpoint} from "../../../hooks/api";



const AgentDataBuilder = () => {
    const { isEntitled, isLoadingEntitlements } = useEntitlement("gpt");
    const [ agent, setAgent ] = React.useState(null);
    const { getValue, setValue } = useIndexDB();
    // Get entitlements and check for gpt
    const params = new URLSearchParams(window.location.search);
    const agentKey = params.get('key');
    const [content, setContent] = useState("{}");
    const [pendingContent, setPendingContent] = useState(undefined);
    const [newContent, setNewContent] = useState(undefined);
    const [pendingText, setPendingText] = useState(undefined);
    const editorRef = useRef();
    const [isAutoMergeEnabled, setAutoMergeEnabled] = useState(false);
    const target = useRef(null);
    const [show, setShow] = useState(false);  // Assuming the overlay should be shown by default
    const {fetch: fetchAgentInfo} = useEndpoint("gpt/agent-info");
    const {fetch: fetchAgentPrompt, fetchAuth: fetchAgentPromptAuth} = useEndpoint("gpt/agent");

    useEffect(() => {
        async function init() {
            const content = await getValue("data-builder::content", "{}");
            if(content) {
                try {
                    const parsedContent = JSON.parse(content);
                    setContent(JSON.stringify(parsedContent, null, 4));
                    if (editorRef && editorRef.current) editorRef.current.jsonEditor.set(parsedContent);
                } catch (e) {
                    console.log("Error parsing content: ", e);
                }
            }
        }

        init();
    }, []);

    const handleToggleChange = () => {
        setAutoMergeEnabled(prev => !prev);
    };
    const mergeJSON = (obj1, obj2) => {
        // Base case: if one of the items isn't an object, return the second item (overriding)
        if (typeof obj1 !== 'object' || obj1 === null) return obj2;
        if (typeof obj2 !== 'object' || obj2 === null) return obj1;

        // If both items are arrays, concatenate them
        if (Array.isArray(obj1) && Array.isArray(obj2)) return obj1.concat(obj2);

        // If one of them is an array and the other isn't, return the second item (overriding)
        if (Array.isArray(obj1) || Array.isArray(obj2)) return obj2;

        // If we've reached here, both items are objects. We need to merge their properties
        let result = {...obj1};  // Start with properties from the first object
        for (let key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                // If this key is in both objects, merge the two values
                if (obj1.hasOwnProperty(key)) {
                    result[key] = mergeJSON(obj1[key], obj2[key]);
                } else {
                    // Otherwise, just use the value from the second object
                    result[key] = obj2[key];
                }
            }
        }

        return result;
    };

    useEffect(() => {}, [content])

    useEffect(() => {
        async function init() {
            if(agentKey) {
                console.log("Loading agent from key: ", agentKey);
                const agentResponse = await fetchAgentInfo({key: agentKey});
                if(agentResponse && !agentResponse.error) {
                    setAgent(agentResponse);
                } else {
                    setAgent(undefined);
                }
                return;
            }


            const agent = await getValue("agent-chat::selected-agent", null);
            if(agent) {
                setAgent(agent);
            }
            console.log("Restoring agent from indexdb: ", agent);
        }

        init();
    }, []);

    useEffect(() => {}, [agent]);

    const parseJson = (str) => {
        try {
            return JSON.parse(str);
        } catch {
            return false;
        }
    };

    async function fetchPrompt(agent, promptValue) {
        if(!agent) return;
        if(!promptValue) return;
        setShow(true);

        try {
            const prompt = promptValue;
            let messageResponse;
            if (agentKey) {
                messageResponse = await fetchAgentPrompt({key: agentKey, agent: agent.name, namespace: agent.namespace, prompt: prompt});
            } else {
                messageResponse = await fetchAgentPromptAuth({agent: agent.name, namespace: agent.namespace, prompt: prompt});
            }
            console.log("Message response: ", messageResponse);
            if (messageResponse) {
                mergeContent(messageResponse.content);
            } else if (messageResponse.error) {
                toast.error(messageResponse.error);
            }
        } catch (e) {
            toast.error(e);
        }
        setShow(false);
    }

    function mergeContent(newContent) {
        const json = parseJson(newContent);
        if (json) {
            // Combine the new json with the old json
            try {
                const newJson = mergeJSON(getContent(), json);
                if(!isAutoMergeEnabled) {
                    setPendingContent(newJson);
                    setNewContent(json);
                } else {
                    applyPendingContent(newJson);
                }
            } catch (e) {
                toast.error("Error combining json: " + e);
                setPendingContent(json);
            }
            setPendingText(undefined);
        } else {
            setPendingText(newContent);
            setPendingContent(undefined);
            setNewContent(undefined);
        }
    }

    function submitPrompt(promptValue) {
        toast.info("Submitting prompt\n" + promptValue);
        fetchPrompt(agent, promptValue);
    }

    function onAgentSelected(agent) {
        setValue("agent-chat::selected-agent", agent);
        setAgent(agent);
    }

    function rightColumn() {
        return <>
        </>;
    }

    function drawContent() {
        return <></>
    }

    function getContent() {
        try {
            return JSON.parse(content);
        } catch {
            return {};
        }
    }

    function applyPendingContent(pendingContent) {
        setContent(JSON.stringify(pendingContent, null, 4));
        editorRef.current.jsonEditor.set(pendingContent);
        setPendingContent(undefined);
        setNewContent(undefined);
        setPendingText(undefined);
        setValue("data-builder::content", JSON.stringify(pendingContent));
    }

    function copyAgentContent() {
        navigator.clipboard.writeText(content);
        toast.info("Copied agent content to clipboard");
    }

    function pasteAgentContent() {
        navigator.clipboard.readText().then(clipText => {
            mergeContent(clipText);
            toast.info("Pasted agent content from clipboard");
        });
    }

    const leftMenu = [
        {icon: MdCopyAll, label: "", onClick: copyAgentContent, tooltip: "Copy Content"},
        {icon: MdContentPaste, label: "", onClick: pasteAgentContent, tooltip: "Paste Content"},
    ];
    return (
        <PageHeader image={"/img/headers/agent-banner-wide.png"}
                    title={"Agent Data Builder"}
                    description={"Use agents to construct json data."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["GPT", "/gptinfo"],
                        ["Data Builder", "/gpt/databuilder"]
                    ]}
                    menuleft={leftMenu}
                    menuright={[
                        {icon: MdAdd, label: "New Data", onClick: () => applyPendingContent({})}
                    ]}
        >
            {isEntitled !== undefined && !isEntitled && <div className={"alert alert-danger"}>You are not entitled to use this feature.</div>}
            {(!agentKey && isEntitled || agentKey && agent) && <>
                <h3>Prompt</h3>
                { /* A toggle to enable/disable automerge */ }
                <Form>
                    <Form.Check
                        type="switch"
                        id="auto-merge-switch"
                        label={`AutoMerge is ${isAutoMergeEnabled ? "Enabled" : "Disabled"}`}
                        checked={isAutoMergeEnabled}
                        onChange={handleToggleChange}
                    />
                </Form>
                {!agentKey && <AgentChooser onAgentSelected={onAgentSelected} />}
                <div>
                    {agent ? (
                        <div>
                            <div className={"mt-5"}>
                                <PromptInput ref={target} onSubmitted={submitPrompt} />
                                {show && <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "#000000cc", /* Semi-transparent white background */
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 10 /* Ensure it's on top */
                                }} >
                                    Generating...</div>
                                }
                            </div>
                            {(pendingText || pendingContent) && <div className={"mt-5"}>
                                <h3>New Data</h3>
                                {pendingContent && <div>
                                    <h4>New Content</h4>
                                    <JsonEditor  value={newContent} style={{ height: '200px' }}/>
                                    <h4>Merged Content</h4>
                                    <JsonEditor  value={pendingContent} style={{ height: '200px' }}/>

                                    <Button variant="primary" onClick={() => {
                                        applyPendingContent(pendingContent);
                                    }}>
                                        Apply
                                    </Button>
                                </div>}
                                {pendingText && <div className='col-md-8'><pre>{pendingText}</pre></div>}
                            </div>}
                            <div className={"mt-5"}>
                                <h3>Data</h3>
                            <JsonEditor
                                ref={editorRef}
                                value={getContent()}
                                style={{ height: '200px' }}
                            />
                            </div>
                        </div>
                    ) : (
                        <div className='col-md-8'>Select an agent to chat with to get started!</div>
                    )}
                </div>
            </>}
            {agentKey && agent === undefined && <div className={"alert alert-danger"}>Agent not found or is no longer accessible.</div>}

        </PageHeader>);
}

export default AgentDataBuilder;