import React from 'react';
import {createRoot} from 'react-dom/client';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import "bootstrap/dist/css/bootstrap.css";
import './assets/style.scss';
import './assets/custom-bootstrap.scss';
import './assets/custom-styles.scss';
import './assets/flex-styles.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";

require("prerender-node").set("prerenderToken", 'sq73cKD7HgyQ41Rug2aW')

const root = createRoot(document.getElementById('root'));
root.render(  <BrowserRouter>
    <App />
</BrowserRouter>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();