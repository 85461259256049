import React, {useEffect} from 'react';
import { Badge } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

function SetTag({ label }) {
    const navigate = useNavigate();
    function openTag() {
        navigate("/tags/" + label);
    }

    return (
        <Badge className="me-2" variant="secondary" style={{margin: 2, cursor: "pointer"}} onClick={() => openTag()}>
            {label}
        </Badge>
    );
}

export default SetTag;