import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {FormControl, InputGroup, Tab, Tabs} from "react-bootstrap";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {useLoading} from "../../components/loading";
import {useError} from "../../components/error";
import StyledButton from "../../components/styledbutton";
import CCGTemplate from "../../components/ccg/ccgtemplate";
import {artApiFetchAuthAsync, artApiPostAuthAsync} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {getLayerData, getTemplateData} from "../../../hooks/ccg/ccgdata";
import {MdAdd, MdCopyAll, MdDelete, MdEdit, MdImageSearch, MdPrint, MdRefresh, MdSave} from "react-icons/all";
import {MdContentCopy, MdContentPaste} from "react-icons/md";
import OverlayCardEditor from "./overlaycardeditor";
import {getProfile} from "../../../hooks/profileinfo";
import DeckPreview from "../../components/ccg/deckpreview";
import drawPrinterPage from "../../components/ccg/printdrawer";
import printJS from "print-js";
import ImageMosaic from "../../components/ImageGallery/components/ImageMosaic";
import useCardList, {copyImageDescription, createPrompt} from "../../components/ccg/carddata";
import PageHeader from "../../components/PageHeader/pageheadercontrol";

const CCGDeck = () => {
    const headerImage = '/img/headers/ccg.png'
    const {user, getAccessTokenSilently} = useAuth0();
    const [collection, setCollection] = React.useState("mtg");
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const {loading, setLoading} = useLoading();
    const {error, setError} = useError();
    var path = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    if(isNaN(path)) path = "";
    const [id, setId] = React.useState(path);
    const [activeTab, setActiveTab] = React.useState(id ? "deck" : "properties");
    const [templates, setTemplates] = React.useState({});
    const [selectedCard, setSelectedCard] = React.useState(null);
    const [selectedCardIndex, setSelectedCardIndex] = React.useState(null);
    const [owner, setOwner] = React.useState(null);
    const [currentCards, setCurrentCards] = React.useState([]);
    const printCanvas = React.useRef();
    const {images: backs} = useCardList("side=2", "order=desc");
    const {preview, setPreview} = React.useState(null);
    const [redrawCount, setRedrawCount] = React.useState(0);
    const [lastTemplate, setLastTemplate] = React.useState(null);
    const navigate = useNavigate();

    const [deck, setDeck] = React.useState({
        "cards": [],
        "name": "",
        "description": "",
        "id": "",
        "owner": "",
        "created": "",
        "updated": ""
    });

    useEffect(() => {
        console.log("Syncing deck...");
        async function sync() {

            const token = await getAccessTokenSilently();
            await getLayerData(token, collection);
            await getTemplateData(token, collection);
            const storedDeck = await artApiFetchAuthAsync(token, "/ccg/decks/deck", "id=" + id);
            const loadedTemplates = await getTemplateData(token, collection);
            setTemplates(loadedTemplates);

            console.log("Stored deck", storedDeck);
            applyDeckSettings({...storedDeck.data, id: id, owner: storedDeck.owner});
        }

        if(id) sync();
    }, [user]);
    
    function applyDeckSettings(deck) {
        console.log("Applying deck settings", deck);
        console.trace();
        setDeck(deck);
    }

    useEffect(() => {
        const p = preview ?? window.deckPreview;
        if(deck.preview != p) {
            deck.preview = p;
            //applyDeckSettings({...deck, preview: p});
        }
    }, [preview, redrawCount]);

    useEffect(() => {
        console.log("Updating deck...", deck);

        deck.cards.forEach((card, index) => {
            card.redraw(card);
        });

        async function updateProfile() {
            const token = getAccessTokenSilently();
            const owner = await getProfile(token, deck.owner);
            setOwner(owner);
        }
        updateProfile();
        renderDeck();
        setCurrentCards(deck.cards);
        console.log("Deck: ", deck);
    }, [deck]);


    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175"}}>
                <div  style={{maxHeight: windowHeight - 125, overflow: "auto", paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
                    {content}
                </div>
            </Tab>
        );
    }

    function createNewCard(variables) {
        if(!deck.cards) deck.cards = [];
        // Get first key from templates
        const template = lastTemplate ?? Object.keys(templates)[0];
        deck.cards.push({
            "variables": variables ?? {},
            "template": template,
            "collection": collection,
            'properties': {}
        });
        console.log('Templates: ', templates);
        console.log('Added card to deck: ', deck);
        applyDeckSettings({...deck});
    }

    function deleteCard(index) {
        console.log("Removing card at index: ", index);
        deck.cards = deck.cards.filter((_, i) => index != i)
        applyDeckSettings({...deck});
    }

    function pasteNewCard() {
        navigator.clipboard.readText().then(text => {
            try {
                createNewCard(JSON.parse(text));
            } catch (e) {
                toast.error("Invalid template.\n" + e);
            }
        });
    }

    function renderCreateCard() {
        return (<div style={{width: 250, height: 350}}>
            <div style={{width: 250, height: 350, backgroundColor: "#b29175", borderRadius: 16, padding: 10}}>
                <h4 style={{color: '#ffffff'}}>Create Card</h4>
                <div className={"centered"}>
                    <StyledButton label={"Paste Card Variables"} onClick={() => {pasteNewCard()}}/>
                </div>
                <div className={"centered"}>
                    <StyledButton label={"Create Empty Card"} onClick={() => {
                        createNewCard();
                    }}/>
                </div>
            </div>
        </div>)
    }

    function getPromptReadyCard(card) {
        card.collection = collection;
        card.deck = deck.name;
        card.cardNumber = card.variables.number;
        return card;
    }

    function copyPrompts() {
        const prompts = deck.cards.map(card => {
            createPrompt(getPromptReadyCard(card))
        });

        navigator.clipboard.writeText(JSON.stringify(prompts));
        toast("Copied prompts to clipboard.");
    }

    function getTabWidth() {
        const tab = document.querySelector(".nav-tabs");
        if(tab) return tab.clientWidth - 25 - 8;
        return windowWidth - 150;
    }

    function renderDeck() {
        const exposed = [];
        const tab = renderTab("deck", "Deck", (
            <div style={{position: "relative"}}>
                {deck && deck.cards && deck.cards.length > 5 && <div className={"create-card-flex-between-container"}>
                    <DeckPreview className={"create-card-flex-between-items-grow"} deck={deck}
                                 previewWidth={getTabWidth()} previewHeight={256} onRedrawn={preview => {
                                     if(setPreview) setPreview(preview);
                                     window.deckPreview = preview;
                                 }}
                                childCount={redrawCount}/>
                </div>}
                <div className={"img-grid"} style={{gridTemplateColumns: "1fr ".repeat((windowWidth - 100) / 260)}}>
                    {(!deck || !deck.cards || deck.cards.length === 0) && renderCreateCard()}
                    {deck.cards && deck.cards.map((card, index) => {
                        exposed.push(card);
                        return (
                            <div key={index} style={{width: 250, height: 400, position: "relative"}}>
                                <div style={{width: 250, height: 350, backgroundColor: "#b29175", borderRadius: 16}}>
                                    <div onClick={() => {
                                        setSelectedCard(card);
                                        setSelectedCardIndex(index)
                                    }}>
                                        <CCGTemplate cardData={card}
                                                     width={250}
                                                     height={350}
                                                     exposedMethods={card}
                                                     collection={collection}
                                                     onDrawCard={c => {
                                                         setTimeout(() => {setRedrawCount(redrawCount+1);}, 1000);
                                                     }}
                                        />
                                    </div>
                                    {deck.owner == user.email && <div style={{position: "absolute", left: 0, right: 0, bottom: 0}}>
                                        <div className={"create-card-flex-between-container"}>
                                            <MdRefresh className={"mdicon"} onClick={() => {
                                                card.redraw(card)
                                            }}/>
                                            <div className={"create-card-flex-between-items-grow"}/>
                                            <MdEdit className={"mdicon"} onClick={() => {
                                                setSelectedCard(card)
                                            }}/>
                                            <MdImageSearch className={"mdicon"} onClick={() => {
                                                copyImageDescription(getPromptReadyCard(card))
                                            }}/>
                                            <MdContentCopy className={"mdicon"} onClick={() => {
                                                navigator.clipboard.writeText(JSON.stringify(card.variables));
                                                setLastTemplate(card.template);
                                                toast("Copied card variables to clipboard.");
                                            }} />
                                            <MdContentPaste className={"mdicon"} onClick={() => {
                                                navigator.clipboard.readText().then(text => {
                                                    try {
                                                        card.variables = JSON.parse(text);
                                                        card.redraw(card);
                                                    } catch (e) {
                                                        toast.error("Invalid template.\n" + e);
                                                    }
                                                });
                                            }} />
                                            {/*<MdPhoto className={"mdicon"} onClick={() => {}}/>
                                        <MdGridView className={"mdicon"} onClick={() => {}}/>
                                        <MdContentPaste className={"mdicon"} onClick={() => {
                                            navigator.clipboard.readText().then(text => {
                                                try {
                                                    card.variables = JSON.parse(text);
                                                    card.redraw(card);
                                                } catch (e) {
                                                    toast.error("Invalid template.\n" + e);
                                                }
                                            });
                                        }}/>*/}
                                            <div className={"create-card-flex-between-items-grow"}/>
                                            <MdDelete className={"mdicon"} onClick={() => {
                                                deleteCard(index)
                                            }}/>
                                        </div>
                                        <div className={"create-card-flex-between-container"}>
                                            <div className={"create-card-flex-between-items-grow"}/>
                                            <span>{index + 1}/{deck.cards.length}</span>
                                            <div className={"create-card-flex-between-items-grow"}/>
                                        </div>
                                    </div>}
                                </div>
                            </div>);
                    })}
                    {/*deck.cards.length > 10 && renderCreateCard()*/}
                </div>
            </div>
        ));
        return tab;
    }

    function renderProperties() {
        return renderTab("properties", "Properties", (
            <div>
                <span>Deck Name</span>
                <InputGroup className="mt-3" size="lg">
                    <FormControl
                        style={{flexGrow: 1, backgroundColor: "#ffffff"}}
                        onChange={(e) => {applyDeckSettings({...deck, "name": e.target.value})}}
                        value={deck.name}
                        placeholder="Name"
                        aria-label="Name"
                        aria-describedby="basic-addon2"
                    />
                </InputGroup>
                <span className={"mt-5"}>Description</span>
                <InputGroup className="mt-3" size="lg">
                    <FormControl
                        style={{flexGrow: 1, backgroundColor: "#ffffff"}}
                        onChange={(e) => {applyDeckSettings({...deck, "description": e.target.value})}}
                        value={deck.description ?? ""}
                        as={"textarea"}
                        placeholder="Description"
                        aria-label="Description"
                        aria-describedby="basic-addon2"
                    />
                </InputGroup>

                <span className={"mt-5"}>Deck Back</span>
                <ImageMosaic
                    handleClick={(e, { index }) => {setDeckBack(backs[index])}}
                    images={backs}
                    minColumns={3} />
            </div>
        ));
    }

    function getPrintHeight() {
        return 3300;
    }

    function getPrintWidth() {
        return 2550;
    }

    function getWorkingHeight() {
        return windowHeight - 200;
    }

    function getWorkingWidth() {
        return getWorkingHeight() * (getPrintWidth() / getPrintHeight());
    }

    async function print() {
        toast("Preparing pages for printing...");
        let pageCards = [];
        let pages = [];

        const backs = [];
        for(let i = 0; i < deck.cards.length; i++) {
            backs.push({
                width: 2.5,
                height: 3.5,
                image: {
                    src: deck.back
                }
            });
        }

        for(let i = 0; i < deck.cards.length; i++) {
            var canvas = deck.cards[i].getCanvas();
            var w = canvas.width;
            var h = canvas.height;
            canvas.width = 2.5 * 300;
            canvas.height = 3.5 * 300;
            await deck.cards[i].redraw(deck.cards[i]);

            pageCards.push({
                width: 2.5,
                height: 3.5,
                image: {
                    src: canvas.toDataURL()
                }
            });
            if(pageCards.length == 9) {
                printCanvas.current.width = getPrintWidth();
                printCanvas.current.height = getPrintHeight();
                const page = await drawPrinterPage(pageCards, printCanvas.current, getPrintWidth(), getPrintHeight());
                pages.push(printCanvas.current.toDataURL("image/png"));
                const backPage = await drawPrinterPage(backs, printCanvas.current, getPrintWidth(), getPrintHeight());
                pages.push(printCanvas.current.toDataURL("image/png"));
                console.log("Created page " + pages.length, page, pageCards);
                pageCards = [];
            }
        }
        if(pageCards.length > 0) {
            const page = await drawPrinterPage(pageCards, printCanvas.current, getPrintWidth(), getPrintHeight());
            pages.push(printCanvas.current.toDataURL("image/png"));
            const backPage = await drawPrinterPage(backs, printCanvas.current, getPrintWidth(), getPrintHeight());
            pages.push(printCanvas.current.toDataURL("image/png"));
            console.log("Created page " + pages.length);
        }

        console.log("Printing " + pages.length + " pages");
        toast("Printing " + pages.length + " pages");

        const ctx = printCanvas.current.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, ctx.width, ctx.height);


        printJS({
            header: "Deck Name: " + deck.name,
            printable: [...pages],
            type: 'image',
            modalMessage: 'Preparing document...'
        });
    }

    async function save() {
        toast("Saving deck...");
        const token = await getAccessTokenSilently();
        let response = deck;
            var d = {...deck};
            delete d.created;
            delete d.updated;
            console.log("Saving: " + JSON.stringify(d));
            response = await artApiPostAuthAsync(token, "/ccg/decks/deck", JSON.stringify(d),
                "id=" + encodeURIComponent(deck.id)
            );
        if(response.id) {
            toast("Saved deck!");
            if (response.id != deck.id) {
                navigate("/ccg/deck/" + response.id);
            }
            //applyDeckSettingsresponse);
        }
    }

    function setDeckBack(back) {
        console.log("Set deck back: " + back);
        applyDeckSettings({...deck, "back": back.src});
    }

    return (
        <PageHeader image={headerImage} title={deck && deck.name ? deck.name : "Create Deck"}
                    description={"Create a new deck for your AI Art cards"}
                    preview={deck.preview}
                    breadcrumb={[
                        ["Home", "/"],
                        ["CCG", "/ccg"],
                        ["Create Deck", "/ccg/deck"]
                    ]}
                    menuleft={[
                        {icon: MdAdd, label: "New Card", onClick: createNewCard},
                        {icon: MdContentPaste, label: "Paste New Card", onClick: pasteNewCard},
                        {icon: MdCopyAll, label: "Copy Prompts", onClick: copyPrompts},
                    ]}

                    menuright={[
                        {icon: MdPrint, onClick: print},
                        {icon: MdSave, onClick: save},
                    ]}
        >
            <div>
                <span style={{position: "absolute", right: 0, marginTop: 8}}>
                    <strong>{deck.name} {owner && owner.nickname && `by ${owner.info.displayname}`}</strong>
                </span>
                <Tabs activeKey={activeTab} defaultActiveKey="variables" id="uncontrolled-tab-example" onSelect={key => setActiveTab(key)}>
                    {renderDeck()}
                    {renderProperties()}
                </Tabs>
            </div>

            <OverlayCardEditor card={selectedCard} collection={collection}
                                edit={deck.owner == user.email}
                               style={{zIndex: 2000}}
                               onClose={() => setSelectedCard(null)}
                               onImageSelected={(card, image) => {
                                   console.log("Selected image: ", image);
                                      card.image = image;
                                      if(card.redraw) card.redraw();
                                      deck.cards[selectedCardIndex] = card;
                                      applyDeckSettings({...deck});
                               }}
                               onTemplateSelected={(card, template) => {
                                      console.log("Selected template: ", template);
                                      card.template = template;
                                      if(card.redraw) card.redraw();
                                      deck.cards[selectedCardIndex] = card;
                                      applyDeckSettings({...deck});
                                      setLastTemplate(template);
                               }}
                               onVariablesChanged={(card, variables) => {
                                    console.log("Selected variables: ", variables);
                                    card.variables = variables;
                                    if(card.redraw) card.redraw();
                                    deck.cards[selectedCardIndex] = card;
                                    applyDeckSettings({...deck});
                               }}
            />
            <canvas ref={printCanvas} width={getWorkingWidth()} height={getWorkingHeight()} id="canvas" style={{border: "1px solid #000000", backgroundColor: "white", scale: .25, display: "none"}}></canvas>
        </PageHeader>
    );
}

export default CCGDeck;