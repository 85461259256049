import {Button, Dropdown, DropdownButton, FormControl, InputGroup} from "react-bootstrap";
import {IoMdRefresh, IoMdSend} from "react-icons/all";
import RightColumnImageGrid from "./rightcolumngallery";
import React from "react";
import useGalleryRequest from "../../../hooks/galleryapi";
import {toast} from "react-toastify";

const ArtworkMiniBrowser = ({onArtworkSelected, promptMode = "search"}) => {
    const [requestId, setRequestId] = React.useState(0);
    const PROMPT_MODE_SEARCH = "search";
    const PROMPT_MODE_DREAM = "dream";

    const [currentPromptMode, setPromptMode] = React.useState(promptMode);
    const [q, setQuery] = React.useState("");

    const {
        images: searchImages
    } = useGalleryRequest({maxWidth: 25, maxHeight: 25}, "art", "sort=timestamp", "order=desc", "s=" + encodeURI(q), "reqid=" + requestId);

    return (<div  style={{minHeight: 300}}>
        <InputGroup>
            <FormControl
                onChange={(e) => setQuery(e.target.value)}
                as="textarea"
                placeholder={promptMode === PROMPT_MODE_DREAM ? "Enter prompt" : "Search for artwork"}
                aria-label={promptMode === PROMPT_MODE_DREAM ? "Enter prompt" : "Search for artwork"}
                aria-describedby="basic-addon2"
            />
        </InputGroup>
        <InputGroup className="mb-3">
            <DropdownButton
                className="flex-grow-0"
                as={InputGroup.Append}
                variant="outline-secondary"
                title={currentPromptMode === PROMPT_MODE_DREAM ? "Dream" : "Search"}
                id="input-group-dropdown-2"
            >
                <Dropdown.Item href="#" onClick={() => setPromptMode(PROMPT_MODE_SEARCH)}>Search</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#" onClick={() => setPromptMode(PROMPT_MODE_DREAM)}>Dream</Dropdown.Item>
            </DropdownButton>
            <Button variant="outline-secondary" id="button-addon2"><IoMdSend/></Button>
            <Button variant="outline-secondary" onClick={() => {
                toast("Refreshing art...");
                setRequestId(requestId + 1);
            }} id="button-addon2"><IoMdRefresh/></Button>
        </InputGroup>

        {searchImages && <RightColumnImageGrid images={searchImages} setSelectedImg={(image) => onArtworkSelected(image)} columns={3}/>}
    </div>)
}

export default ArtworkMiniBrowser;
