import React, {useEffect} from 'react';
import {artApiFetchAsync} from "../../hooks/artapi";
import { Tab, Nav, Row, Col, ListGroup } from 'react-bootstrap';
import TagFilter from "./tagfilter";
import {Link} from "react-router-dom";


export const getTagColor = function (hue) {
    hue = 359 * hue; // 0-359
    const saturation = 30; // 0-100
    const value = 50; // 0-100

    const rgb = hsvToRgb(hue, saturation, value);
    const color = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;

    return color;
}

export const hsvToRgb = function(h, s, v) {
    const c = v / 100 * s / 100;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = v / 100 - c;

    let r = 0, g = 0, b = 0;

    if (h >= 0 && h < 60) {
        r = c; g = x; b = 0;
    } else if (h >= 60 && h < 120) {
        r = x; g = c; b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0; g = c; b = x;
    } else if (h >= 180 && h < 240) {
        r = 0; g = x; b = c;
    } else if (h >= 240 && h < 300) {
        r = x; g = 0; b = c;
    } else if (h >= 300 && h < 360) {
        r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return { r, g, b };
}


export default function TagList({id="", tags=undefined, ids=[], subtags=[], filter=true}) {
    const segments = window.location.pathname.split('/').splice(1);
    const tagNames = segments.length > 0 && segments[0] === "tags" ?
        segments.map(s => decodeURI(s)).filter(segment => segment !== '').slice(1) :
        [];
    const title = tagNames.join(' ');
    const tagNameArray = tagNames.map(s => 'tags[]=' + s);
    let tagPath = tagNames.join('/');
    if(tagPath.length > 0) tagPath += "/";
    const [filteredTags, setFilteredTags] = React.useState([]);
    const [baseTags, setBaseTags] = React.useState([]);
    const [letters, setLetters] = React.useState([]);

    useEffect(() => {
        async function fetchTags() {
            const iduri = ids.map(s => 'ids[]=' + s);
            const subtaguri = subtags.map(s => 'subtags[]=' + s);
            const requestTags = await artApiFetchAsync("tags", "id=" + id, ...iduri, ...subtaguri);
            setBaseTags(requestTags);
        }

        if(tags) {
            setBaseTags(tags);
        } else {
            fetchTags();
        }
    }, [tags, id]);

    useEffect(() => {
        const tags = filteredTags && filteredTags.length > 0 ? filteredTags : baseTags;
        const letters = new Set(tags?.map((tag) => tag.name[0]));
        setLetters(Array.from(letters).sort());
    }, [filteredTags, baseTags]);

    // Sort words by value
    //tags?.sort((a, b) => b.count - a.count);
    const counts = new Set(baseTags?.map((tag) => tag.count));
    const distinct = Array.from(counts);
    const normalized = {};
    for (let i = 0; i < distinct.length; i++) {
        normalized[distinct[i]] = i / (distinct.length - 1);
    }

    function getTagPath(tag) {
        if(tagPath && tagPath.includes(tag.name)) {
            const result = '/tags/' + tagPath.replace(tag.name + '/', '');
            console.log('tags: ' + result, tag);
            return result;
        }
        return `/tags/${tagPath}${tag.name.trim()}`;
    }
    const filterTagsByLetter = (letter) => {
        const tags = (filteredTags && filteredTags.length > 0 ? filteredTags : baseTags) ?? [];
        return tags.filter(tag => tag.name.startsWith(letter));
    };
    function drawTags(tags) {
        return (<div>
            {tags.map((tag, index) => (
                <span key={index}>
                {id ?
                    <Link to={getTagPath(tag)} className={"tag"}
                          style={{backgroundColor: getTagColor(normalized[tag.count])}}>{tag.name}</Link> :
                    <Link to={getTagPath(tag)} className={"tag"} title={tag.count}
                          style={{backgroundColor: getTagColor(normalized[tag.count])}}>{tag.name}</Link>
                }
                    <span> </span>
            </span>
            ))}
        </div>);
    }

    function drawGroupedTags() {
        const activeTags = (filteredTags && filteredTags.length > 0 ? filteredTags : baseTags) ?? [];
        return     <>
            {filter && <TagFilter tags={baseTags} onFilterChanged={filtered => setFilteredTags(filtered)} />}
            {activeTags.length > 100 ?
            <Tab.Container defaultActiveKey="a">
            <Row>
                <Col sm={2} style={{backgroundColor: "#33333"}}>
                    <Nav variant="pills" className="flex-column">
                        {letters.map(letter => (
                            <Nav.Item key={letter}>
                                <Nav.Link eventKey={letter}>{letter.toUpperCase()}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <Col sm={10}>
                    <Tab.Content>
                        {letters.map(letter => (
                            <Tab.Pane eventKey={letter} key={letter}>
                                {drawTags(filterTagsByLetter(letter))}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container> : drawTags(activeTags)}
        </>;
    }

    return drawGroupedTags();
}