import React, {useEffect} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {MdDelete, MdGridOn, MdMenu, MdMenuOpen, MdPreview, MdPrint,} from "react-icons/all";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import ImageMosaic from "../../components/ImageGallery/components/ImageMosaic";
import drawPrinterPage, {calculateColumns, calculateRows} from "../../components/ccg/printdrawer";
import StyledButton from "../../components/styledbutton";
import useCardList from "../../components/ccg/carddata";
import {loadImage} from "../../components/ccg/carddrawer";
import printJS from "print-js";
import {toast} from "react-toastify";
import {useLoading} from "../../components/loading";
import Lightbox from "../../components/ImageGallery/components/CoolLightbox";
import PageHeader from "../../components/PageHeader/pageheadercontrol";


const CCGCardPrinter = () => {
    const headerImage = '/img/headers/cardprinter.png'
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const {images: fronts} = useCardList("side=1", "order=desc");
    const {images: backs} = useCardList("side=2", "order=desc");
    const [selectedCards, setSelectedCards] = React.useState([]);
    const printCanvas = React.useRef(null);
    const [imageIteration, setImageIteration] = React.useState(0);
    const [completedPages, setCompletedPages] = React.useState([]);
    const [pageFull, setPageFull] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState("fronts");
    const [isLaserTemplate, setIsLaserTemplate] = React.useState(false);
    const {loading, setLoading} = useLoading();
    const toastId = React.useRef(null);

    const [isPrintExpanded, setIsPrintExpanded] = React.useState(false);
    const [previewIndex, setPreviewIndex] = React.useState(0);
    const [isPreviewOpen, setPreviewOpen] = React.useState(false);

    const [currentPagePreview, setCurrentPagePreview] = React.useState(null);
    const [isActivePagePreviewOpen, setIsActivePagePreviewOpen] = React.useState(false);


    async function setSelectedImg(image) {
        if(pageFull) {
            toast.warning("Page is full. Remove a card, save the page, or create a new page to add more images.")
            return;
        }

        await loadImage(image.src);
        const card = {
            image: image,
            width: 2.5,
            height: 3.5,
            dpi: 300,
            backingColor: "black"
        }
        setSelectedCards([...selectedCards, card]);
    }

    function createCard(image) {
        return {
            image: image,
            width: 2.5,
            height: 3.5,
            dpi: 300,
            backingColor: "black"
        }
    }

    async function createPage(selectedCards) {
        if(!printCanvas.current) return;

        await repaint(selectedCards, getPrintWidth(), getPrintHeight());
        const page = {
            selectedCards: [...selectedCards],
            image: printCanvas.current.toDataURL()
        }
        setCompletedPages([...completedPages, page]);
    }

    async function completePage() {
        await createPage(selectedCards);
        console.log("Clearing selected cards.");
        await repaint(selectedCards, getWorkingWidth(), getWorkingHeight());
        newPage();
    }

    function redraw() {
        setImageIteration(imageIteration + 1);
    }

    async function repaint(selectedCards, width, height) {
        if(!printCanvas.current) return {filled: 0, total: -1};

        printCanvas.current.width = width;
        printCanvas.current.height = height;
        return await drawPrinterPage(isLaserTemplate ?
            [{width: 2.5, height: 3.5}] : selectedCards,
            printCanvas.current, getPrintWidth(), getPrintHeight(), 300, !isLaserTemplate);

    }
    useEffect(() => {
        async function paintIteration() {
            console.log("Selected cards: ", selectedCards);
            const {filled, total} = await repaint(selectedCards, getWorkingWidth(), getWorkingHeight());
            console.log("Filled: ", filled, " Total: ", total);
            if (filled === total) {
                setPageFull(true);
            }
        }
        selectedCards && selectedCards.length > 0 && paintIteration();
    }, [imageIteration, selectedCards]);

    function getPrintHeight() {
        return 3300;
    }

    function getPrintWidth() {
        return 2550;
    }

    function getWorkingHeight() {
        return windowHeight - 200;
    }

    function getWorkingWidth() {
        return getWorkingHeight() * (getPrintWidth() / getPrintHeight());
    }


    function hasSelection() {
        return selectedCards && selectedCards.length > 0;
    }

    async function addCardBacks(image) {
        await loadImage(image.src);
        const cards = [];
        const card = createCard(image);
        const rows = calculateRows(getPrintHeight(), card, 300);
        const columns = calculateColumns(getPrintWidth(), card, 300);
        for(var i = 0; i < rows * columns; i++) {
            cards.push(card);
        }
        await drawPrinterPage(cards, printCanvas.current, getPrintWidth(), getPrintHeight());
        await createPage(cards);
        redraw();
        hideLoadingToast();
    }

    function showLoadingToast(message) {
        if(toastId.current) toast.dismiss(toastId.current);
        toastId.current = toast(message, {autoClose: 1500});
    }

    function hideLoadingToast() {
        toastId.current = null;
    }

    async function printPages(index=-1) {
        toast("Prepping print...");
        await drawPrinterPage(selectedCards, printCanvas.current, getPrintWidth(), getPrintHeight());
        if(index == -1) {
            printJS({
                printable: [printCanvas.current.toDataURL(), ...completedPages.map(page => page.image)],
                type: 'image',
                modalMessage: 'Preparing to print...',
            });
        } else {
            printJS({
                printable: [completedPages[index].image],
                type: 'image',
                modalMessage: 'Preparing to print...',
            });
        }
    }

    function clearPage() {
        const ctx = printCanvas.current.getContext("2d");
        ctx.save();
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, printCanvas.current.width, printCanvas.current.height);
    }

    function newPage() {
        clearPage();
        setIsLaserTemplate(false);
        setSelectedCards([]);
        setPageFull(false);
    }

    async function toggleLaserTemplate() {
        setIsLaserTemplate(!isLaserTemplate);
        await repaint(selectedCards, getWorkingWidth(), getWorkingHeight());
        redraw();
    }

    function removeCompletedPage(index) {
        setCompletedPages(completedPages.filter((_, i) => i !== index));
    }

    function onCompletedPageClicked(index) {
        setPreviewIndex(index);
        setPreviewOpen(true);
    }

    function previewCurrentPage() {
        setCurrentPagePreview([{src: printCanvas.current.toDataURL(), alt: "Current Preview", prop: ""}]);
        setIsActivePagePreviewOpen(true);
    }

    return (
        <PageHeader image={headerImage} title={"Print Cards"}
                    description={"Create PDFs for printing cards with a standard printer."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["CCG", "/ccg"],
                        ["Print Cards", "/ccg/card-printer"]
                    ]} >

        <section className='section'>
        <div className="flex-container black_more" style={{height: "100%"}}>

            <div className="create-card-flex-column" style={{height: windowHeight, backgroundColor: "#0d0d0d", flexGrow: 1, paddingTop: 25}}>
                <div className="create-card-flex-items" style={{paddingLeft: hasSelection() ? 25 : 0}}>
                    <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={key => setActiveTab(key)}>
                        <Tab eventKey={"fronts"} title={"Card Fronts"}>
                            <div  style={{height: getWorkingHeight(), overflow: "auto", paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
                                <ImageMosaic
                                    handleClick={(e, { index }) => {setSelectedImg(fronts[index])}}
                                    images={fronts}
                                    minColumns={3} />
                            </div>
                        </Tab>
                        <Tab eventKey={"backs"} title={"Card Backs"}>
                            <div  style={{height: getWorkingHeight(), overflow: "auto", paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
                                <ImageMosaic
                                    handleClick={(e, { index }) => {addCardBacks(backs[index])}}
                                    images={backs}
                                    minColumns={3} />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <div style={{width: getWorkingWidth() + 50, height: getWorkingHeight(), padding: 25}}>
                <div className='col-md-12'>
                    <div className='create-card-flex-between-container'>
                        <div className='create-card-flex-between-item'>
                            <MdPrint className='create-card-icon' onClick={() => {completePage();}}/>
                        </div>
                        <div className='create-card-flex-between-items-grow'/>
                        <div className='create-card-flex-between-item'>
                            <MdDelete className='create-card-icon' onClick={() => newPage()}/>
                        </div>
                        <div className='create-card-flex-between-items-grow'/>
                        <div className='create-card-flex-between-item'>
                            <MdPreview className='create-card-icon' onClick={() => previewCurrentPage()}/>
                        </div>
                        <div className='create-card-flex-between-item'>
                            <MdGridOn className='create-card-icon' onClick={() => toggleLaserTemplate()}/>
                        </div>
                    </div>
                    <canvas ref={printCanvas} width={getWorkingWidth()} height={getWorkingHeight()} id="canvas" style={{border: "1px solid #000000", backgroundColor: "white", scale: .25}}></canvas>
                </div>
            </div>
        </div>
        </section>

        {completedPages.length > 0 &&
            <div style={{position: "absolute", bottom: 0, flex: 1, padding: 20, backgroundColor: "rgba(0,0,0,0.75)", marginLeft: "auto", marginRight: "auto"}}>
                {isPrintExpanded && <div className={"create-card-flex-between-container"}>
                    {completedPages.map((page, index) => {
                        return <div key={index} className={"create-card-flex-between-items m-1"}>
                            <div className="flex-column-container">
                                <img src={page.image} width={125} height={125 * getPrintHeight()/getPrintWidth()} onClick={() => {onCompletedPageClicked(index);}}  />
                                <div className={"create-card-flex-between-container"}>
                                    <MdPreview className={"create-card-icon"} onClick={() => {onCompletedPageClicked(index)}}/>
                                    <MdDelete className={"create-card-icon"} onClick={() => {removeCompletedPage(index)}}/>
                                    <MdPrint className={"create-card-icon"} onClick={() => {printPages(index)}}/>
                                </div>
                            </div>
                        </div>
                    })}
                </div>}
                <div className={"create-card-flex-between-container mt-1"} style={{flexGrow: 1}}>
                    <div className={"create-card-flex-between-items-grow"}/>
                    <StyledButton onClick={() => {printPages()}} label={"Print"} />
                    <div style={{width: 100}}></div>
                    <div className={"create-card-flex-between-items-grow"}/>
                    {isPrintExpanded ?
                        <MdMenuOpen className={"create-card-icon"} onClick={() => {setIsPrintExpanded(false);}}/> :
                        <MdMenu className={"create-card-icon"} onClick={() => {setIsPrintExpanded(true);}}/>
                    }
                </div>
            </div>
        }
        <Lightbox
            currentImageIndex={previewIndex}
            setCurrentIndex={setPreviewIndex}
            isOpen={isPreviewOpen}
            onClose={() => setPreviewOpen(false)}
            images={completedPages.map((page, index) => ({src: page.image, alt: "Page " + (index + 1), prop: ""}))}

            renderHeader={() => (<> </>)}
            renderFooter={() => (<> </>)}
        />
        {isActivePagePreviewOpen && <Lightbox
            currentImageIndex={0}
            setCurrentIndex={0}
            isOpen={isActivePagePreviewOpen}
            onClose={() => setIsActivePagePreviewOpen(false)}
            images={currentPagePreview}

            renderHeader={() => (<> </>)}
            renderFooter={() => (<> </>)}
        />}
    </PageHeader>);
}

export default CCGCardPrinter;