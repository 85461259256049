import React, {useEffect, useState} from 'react';
import ReactWordcloud from "react-wordcloud";
import {Helmet} from "react-helmet";
import ArtGallery, {fetchRandomImage} from "./ArtGallery";
import {artApiFetchAsync} from "../../../hooks/artapi";
import ModelIcon from "../ModelIcon";
import Footer from "../footer";
import PageHeader from "../PageHeader/pageheadercontrol";
import ArtWordCloud from "./ArtWordCloud";

const ArtPage = ({
                    breadcrumbs=[],
                    bigHeader=true,
                    onImagesUpdated,
                     fields={},
                     queryParameters={},
                     allowUltrafast=true,
                     refreshRate=60,
                     title="Art",
                     contentDescription="AI Art",
                     model=undefined,
                     children}) => {
    const query = new URLSearchParams(window.location.search);
    const search = query.get("q");
    const [q, setQuery] = React.useState(search ?? "");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [lastQueryParameters, setLastQueryParameters] = useState("");

    const [words, setWords] = useState([]);
    const [headerImage, setHeaderImage] = useState("/img/headers/gallery.png");

    useEffect(() => {
        var paramStr = JSON.stringify(queryParameters);
        if(paramStr === lastQueryParameters) return;
        setLastQueryParameters(paramStr);
        async function fetch() {
            const image = await fetchRandomImage(queryParameters);
            // Grab a random image url from images if "error" wasn't returned
            if(image) {
                setHeaderImage(image);
            }

            const response = await artApiFetchAsync("words", "format=wordcloud");
            if (response.error) {
                console.log("Error: ", response.error);
            } else {
                setWords(response);
            }
        }

        fetch();
    }, [queryParameters]);


    return (bigHeader ? (
        <div>
            <Helmet>
                <meta property="og:title"
                      content={"AI Art | " + title + (q ? " | " + q.toUpperCase() : "")}/>
                <meta property="og:description"
                      content={contentDescription}/>
                <meta property="og:image" content={headerImage}/>
                <title>{"AI Art | " + title + (q ? " | " + q.toUpperCase() : "")}</title>
            </Helmet>

            <section className='jumbotron breadcumb h-vh' style={{backgroundImage: `url(${headerImage})`}}>
                <div className='homebread'>
                    <div className='container-fluid'>
                        <div className='row m-10-hor'>
                            <div className='col-md-10 mx-auto text-center '>
                                <div className='heading'>
                                    {title?.toUpperCase()}
                                </div>
                                {model && <ModelIcon model={model}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {children}

            <ArtGallery fields={fields}
                        queryParameters={queryParameters}
                        allowUltrafast={allowUltrafast}
                        refreshRate={refreshRate}
                        onImagesUpdated={onImagesUpdated}
            />
            <ArtWordCloud className={"mt-5"} data={words ? words : []}/>
            <Footer/>

        </div>
        ) : (
                <PageHeader image={headerImage} title={title}
                              description={contentDescription}
                              breadcrumb={[
                                  ["Home", "/"],
                                  ...breadcrumbs
                              ]} >
                    {children}

                    <ArtGallery fields={fields}
                                queryParameters={queryParameters}
                                allowUltrafast={allowUltrafast}
                                refreshRate={refreshRate}
                                onImagesUpdated={onImagesUpdated}
                    />
                    <div style={{ width: screenWidth - 80, height: "100%", overflow: "hidden" }}>
                    <ArtWordCloud className={"mt-5"} data={words ? words : []}/>
                    </div>
                </PageHeader>
            )
    );
}

export default ArtPage