export function StringData() {
    this.addInput("data", "string");
    this.addInput("path", "");
    this.addOutput("data", "string");
}

export function IntData() {
    this.addInput("data", "string");
    this.addInput("path", "");
    this.addOutput("data", "Number");
}

export function FloatData() {
    this.addInput("data", "string");
    this.addInput("path", "");
    this.addOutput("data", "float");
}

const DataNodes = [
    {"name": "String Data", "node": StringData},
    {"name": "Float Data", "node": FloatData},
    {"name": "Int Data", "node": IntData},
];

export default DataNodes;