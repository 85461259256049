import {artApiFetchAsync} from "../../../hooks/artapi";
import {toast} from "react-toastify";

const {LGraph, LiteGraph} = require("litegraph.js");

export async function fetchObjectInfo() {
    return await artApiFetchAsync("/comfyui/object-info");
}

class LiteGraphProcessor {
    constructor(graphJson, objectInfo) {
        this.graph = new LGraph();
        this.loadGraphData(graphJson);
        this.objectInfo = objectInfo;
    }

    /**
     * Populates the graph with the specified workflow data
     * @param {*} graphData A serialized graph object
     */
    loadGraphData(graphData) {


        let reset_invalid_values = false;
        if (!graphData) {
            throw new Error("No graph data provided");
        }

        const missingNodeTypes = [];
        for (let n of graphData.nodes) {
            // Patch T2IAdapterLoader to ControlNetLoader since they are the same node now
            if (n.type == "T2IAdapterLoader") n.type = "ControlNetLoader";

            // Find missing node types
            if (!(n.type in LiteGraph.registered_node_types)) {
                missingNodeTypes.push(n.type);
            }
        }

        this.graph.configure(graphData);

        for (const node of this.graph._nodes) {
            const size = node.computeSize();
            size[0] = Math.max(node.size[0], size[0]);
            size[1] = Math.max(node.size[1], size[1]);
            node.size = size;

            if (node.widgets) {
                // If you break something in the backend and want to patch workflows in the frontend
                // This is the place to do this
                for (let widget of node.widgets) {
                    if (node.type == "KSampler" || node.type == "KSamplerAdvanced") {
                        if (widget.name == "sampler_name") {
                            if (widget.value.startsWith("sample_")) {
                                widget.value = widget.value.slice(7);
                            }
                        }
                    }
                    if (node.type == "KSampler" || node.type == "KSamplerAdvanced" || node.type == "PrimitiveNode") {
                        if (widget.name == "control_after_generate") {
                            if (widget.value === true) {
                                widget.value = "randomize";
                            } else if (widget.value === false) {
                                widget.value = "fixed";
                            }
                        }
                    }
                    if (reset_invalid_values) {
                        if (widget.type == "combo") {
                            if (!widget.options.values.includes(widget.value) && widget.options.values.length > 0) {
                                widget.value = widget.options.values[0];
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Converts the current graph workflow for sending to the API
     * @returns The workflow and node links
     */
    async graphToPrompt() {
        console.log("Converting graph...")
        const workflow = this.graph.serialize();
        const output = {};
        // Process nodes in order of execution
        for (const node of this.graph.computeExecutionOrder(false)) {
            const n = workflow.nodes.find((n) => n.id === node.id);

            if (node.isVirtualNode) {
                // Don't serialize frontend only nodes but let them make changes
                if (node.applyToGraph) {
                    node.applyToGraph(workflow);
                }
                continue;
            }

            if (node.mode === 2) {
                // Don't serialize muted nodes
                continue;
            }

            const inputs = {};
            const widgets = node.widgets_values;
            console.log("Serializing node widgets", widgets);

            // Store all widget values
            if (widgets) {
                for (const i in widgets) {
                    const widget = widgets[i];

                    if (!widget.options || widget.options.serialize !== false) {
                        if(!node.widgets) {
                            const error = `Node ${node.type} has no widget names but has widget values, ${node}`;
                            toast.error(error);
                            console.log(error, node);
                            node.widgets = [];
                        }
                        if(node.widgets.length < i) node.widgets.push(undefined);
                        const  name = node.widgets[i];
                        let value = widget.serializeValue ? await widget.serializeValue(n, i) : widget;


                        if(name == "seed") {
                            console.log("Randomizing seed, was " + value + ".");
                            // Set value to random number between 0 and 549175884563292
                            value = Math.floor(Math.random() * 549175884563292);
                        }
                        console.log(`Serializing widget ${name}=${value}`);
                        inputs[name] = value;
                    }
                }
            }

            // Store all node links
            for (let i in node.inputs) {
                let parent = node.getInputNode(i);
                if (parent) {
                    let link = node.getInputLink(i);
                    while (parent && parent.isVirtualNode) {
                        link = parent.getInputLink(link.origin_slot);
                        if (link) {
                            parent = parent.getInputNode(link.origin_slot);
                        } else {
                            parent = null;
                        }
                    }

                    if (link) {
                        console.log("Node input name: " + node.inputs[i].name);
                        inputs[node.inputs[i].name] = [String(link.origin_id), parseInt(link.origin_slot)];
                    }
                }
            }

            output[String(node.id)] = {
                inputs,
                class_type: node.type,
            };
        }

        // Remove inputs connected to removed nodes

        for (const o in output) {
            for (const i in output[o].inputs) {
                if (Array.isArray(output[o].inputs[i])
                    && output[o].inputs[i].length === 2
                    && !output[output[o].inputs[i][0]]) {
                    delete output[o].inputs[i];
                }
            }
        }

        console.log("Result: ", output, workflow);

        return output;
    }
}

export default LiteGraphProcessor;