import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";
import {
    MdAdd, MdAddChart, MdAutoFixHigh,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdContentCopy,
    MdContentPaste,
    MdDelete, MdInsertChart,
    MdOpenWith,
    MdSend,
    MdShuffle,
    MdTimer
} from "react-icons/md";
import StyledButton from "../components/styledbutton";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";
import SDJobSubmitter from "../components/sdjobsubmitter";
import MJJobSubmitter from "../components/mjjobsubmitter";
import {CollapsibleCard, CollapsibleCardContent, CollapsibleCardTitle} from "../components/CollapsableCard";
import CreateDataSet from "../components/promptdesign/createdataset";
import EditDataSet from "../components/promptdesign/editdataset";
import {LabelDataForm} from "../components/promptdesign/promptdefinitionform";
import CategorizedSet from "../components/promptdesign/categorizedset";
import SavePrompt from "../components/promptdesign/saveprompt";
import {MdRefresh, MdSave} from "react-icons/all";
import FloatingForm from "../components/gpt/floatingform";
import useWindowDimensions from "../../hooks/WindowDimensions";
import PromptTextArea from "../components/promptdesign/VariableTextArea";
import {Dropdown, DropdownButton, Form, FormControl, Spinner} from "react-bootstrap";
import PageHeader from "../components/PageHeader/pageheadercontrol";
import ArtGallery from "../components/artgallery/ArtGallery";
import {useGptAgent} from "../../hooks/gptagent";
import PromptDesignCard, {parseTags} from "../components/promptdesign/promptdesigncard";
import {useEntitlements} from "../../hooks/entitlements";
import {useEndpoint} from "../../hooks/api";

const PromptDesign = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [headerImage, setHeaderImage] = React.useState('/img/headers/dreamscape.png');
    const [savedState, setCookie, removeCookie] = useCookies(['prompt-design']);
    const [promptList, setPromptList] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState(null);
    const [editData, setEditData] = React.useState(false);
    const [groupedDataSets, setGroupedDataSets] = React.useState({});
    const [savedPrompts, setSavedPrompts] = React.useState([]);
    const [savePrompt, setSavePrompt] = React.useState(undefined);
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const [promptOutput, setPromptOutput] = React.useState(null);
    const [disabledFields, setDisabledFields] = React.useState({});
    const {postPrompt: getGeneratedSetName} = useGptAgent("aiart", "Set Name Generator");
    const {isEntitled} = useEntitlements();
    const [dataSetsLoaded, setDataSetsLoaded] = React.useState(false);
    const {fetchAuth: fetchDatasets} = useEndpoint("prompt-data/list-datasets");
    const {fetchAuth: fetchGeneratedPrompt} = useEndpoint("gpt/agent");

    const services = {

    }

    const handleEditData = (visible) => {
        setEditData(visible);
    }

    const defaultPrompt = {
        'prompt': [''],
        'fields': {},
        'metadata': {
            'set': 'setName() :: timestamp()',
            'tags': [],
            'title': '',
            'caption': '',
            'alt': '',
        }
    };

    const defaultDataSets =
        {
            "countries": {
                    "name": "countries",
                    "category": "Built In",
                    "data": [
                        ["Afghanistan", "Afghani", "🇦🇫"],
                        ["Argentina", "Argentine", "🇦🇷"],
                        ["Australia", "Australian", "🇦🇹"],
                        ["Belgium", "Belgian", "🇧🇪"],
                        ["Brazil", "Brazilian", "🇧🇷"],
                        ["Canada", "Canadian", "🇨🇦"],
                        ["China", "Chinese", "🇨🇳"],
                        ["Egypt", "Egyptian", "🇪🇬"],
                        ["France", "French", "🇫🇷"],
                        ["Germany", "German", "🇩🇪"],
                        ["Greece", "Greek", "🇬🇷"],
                        ["Iceland", "Icelandic", "🇮🇸"],
                        ["India", "Indian", "🇮🇳"],
                        ["Ireland", "Irish", "🇮🇪"],
                        ["Italy", "Italian", "🇮🇹"],
                        ["Jamaica", "Jamaican", "🇯🇲"],
                        ["Japan", "Japanese", "🇯🇵"],
                        ["Mexico", "Mexican", "🇲🇽"],
                        ["New Zealand", "New Zealander", "🇳🇿"],
                        ["Nigeria", "Nigerian", "🇳🇬"],
                        ["Norway", "Norwegian", "🇳🇴"],
                        ["Pakistan", "Pakistani", "🇵🇰"],
                        ["Poland", "Polish", "🇵🇱"],
                        ["Russia", "Russian", "🇷🇺"],
                        ["Slovakia", "Slovakian", "🇸🇰"],
                        ["South Korea", "South Korean", "🇰🇷"],
                        ["Spain", "Spanish", "🇪🇸"],
                        ["Sweden", "Swedish", "🇸🇪"],
                        ["Turkey", "Turkish", "🇹🇷"],
                        ["United States", "American", "🇺🇸"]
                    ]
                }
        };
    const [promptDefinition, setPromptDefinition] = React.useState(defaultPrompt);
    const [dataSets, setDataSets] = React.useState(defaultDataSets);
    const [addDataSet, setAddDataSet] = React.useState(false);
    const [activeService, setActiveService] = React.useState("sd");
    const [generated, setGenerated] = React.useState(0);
    const [newField, setNewField] = React.useState('');
    const [newFieldType, setNewFieldType] = React.useState("List");
    const [newTag, setNewTag] = React.useState('');
    const [newFieldRow, setNewFieldRow] = React.useState('');
    const [generating, setGenerating] = React.useState(false);
    const [currentSetName, setCurrentSetName] = React.useState('');
    const [currentTimestamp, setCurrentTimestamp] = React.useState(new Date().toISOString());
    const [maxPrompts, setMaxPrompts] = React.useState(100);
    const [redrawIterator, setRedrawIterator] = React.useState(0);
    const {fetchAuth: fetchPromptFormulas} = useEndpoint("prompt-formulas/list");

    function redraw() {
        setRedrawIterator(redrawIterator + 1);
    }
    useEffect(() => {}, [redrawIterator]);

    function evaluateMaxPrompts() {
        if(activeService && activeService === "mj"
            && maxPrompts > 100) return 100 ;
        else if (isEntitled("large-jobs") && maxPrompts > 100) return 100;

        // If gpt( is found in prompt, limit to 10
        if(promptDefinition.prompt && promptDefinition.prompt.find(p => p.indexOf("gpt(") > -1)) {
            return Math.min(maxPrompts, 10);
        }

        return maxPrompts;
    }

    useEffect(() => {
        const effectiveMax = evaluateMaxPrompts();
        if(maxPrompts != effectiveMax) {
            setMaxPrompts(effectiveMax);
        }
        updatePromptList();
    }, [maxPrompts]);

    useEffect(() => {
        console.log("Active service: " + activeService);
        if(activeService === "mj") setMaxPrompts(100);
        else setMaxPrompts(1000);
    }, [activeService]);

    useEffect(() => {
        updatePromptList();
        setCookie("currentSetName", currentSetName);
    }, [currentSetName])

    useEffect(() => {
        updatePromptList();
        setCookie("disabledFields", disabledFields);
    }, [disabledFields])

    async function fetchSavedPrompts() {
        const response = await fetchPromptFormulas();

        if (response.error) {
            toast.error(response.error);
            return;
        } else {
            setSavedPrompts(response);
        }
    }

    useEffect(() => {
        if(savedState?.currentPrompt) {
            updatePromptDefinition(savedState.currentPrompt);
        }
        if(savedState?.currentSetName) {
            setCurrentSetName(savedState.currentSetName);
        }
        if(savedState?.disabledFields) {
            setDisabledFields(savedState.disabledFields);
        }
        setActiveService(savedState?.lastService ?? 'sd');

        async function init() {
            await getDatasets();
            await fetchSavedPrompts();
        }

        init();
    }, [savedState]);

    useEffect(() => {

    }, [generated]);

    function updatePromptDefinition(data) {
        setPromptDefinition(data);
        setGenerated(generated+1);
        setCookie("currentPrompt", data);
    }

    async function submitQuery(query=undefined) {
        return services[activeService](query);
    }

    function createAllCombinations(data) {
        var fields = {...data.fields};
        var appendFields = {...data.appendedFields};
        console.log("Data", data);

        const combinations = [];
        const fieldKeys = data?.fields ? Object.keys(data.fields) : [];
        const appendKeys = data?.appendedFields ? Object.keys(data.appendedFields) : [];

        fieldKeys.forEach((key) => {
            if (!Array.isArray(data.fields[key])) {
                var dataSet = data.fields[key];
                fields[key] = dataSets[dataSet]?.data?.map((d) => d.length == 1 ? d[0] : d)
                    .filter(data => !(getFieldDataKey("fields", key, data) in disabledFields));
            }
        });

        appendKeys.forEach((key) => {
            if (!Array.isArray(data.appendedFields[key])) {
                var dataSet = data.appendedFields[key];
                appendFields[key] = dataSets[dataSet]?.data?.map((d) => d.length == 1 ? d[0] : d)
                    .filter(data => !(getFieldDataKey("appendedFields", key, data) in disabledFields));;
            }
        });

        const keys = [...fieldKeys, ...appendKeys];

        const values = [...Object.values(fields), ...Object.values(appendFields)];
        const product = cartesianProduct(...values);

        for (const p of product) {
            const combination = {};
            for (let i = 0; i < p.length; i++) {
                combination[keys[i]] = p[i];
            }
            combinations.push(combination);
        }

        function cartesianProduct(...arrays) {
            if (arrays.length === 0) {
                return [[]];
            } else {
                let [firstArray, ...rest] = arrays;
                const restProduct = cartesianProduct(...rest);

                // if first array is not an array
                if (!Array.isArray(firstArray)) {
                    firstArray = [firstArray];
                }

                return firstArray?.flatMap((x) =>
                    restProduct.map((y) => [x, ...y])
                ) ?? [];
            }
        }

        return combinations;
    }

    async function processVariables(prompt, promptData, append = true) {
        if(!prompt) return prompt;
        prompt = prompt.replace("timestamp()", currentTimestamp);
        prompt = prompt.replace("setName()", currentSetName);

        if(append && promptDefinition.appendedFields) {
            prompt += " " + Object.keys(promptDefinition.appendedFields).map((field) => `{${field}}`).join(" ")
        }

        // Get keys from promptDefinition.fields
        Object.keys(promptData).forEach((field) => {
            if (Array.isArray(promptData[field])) {
                for(let i = 0; i < promptData[field].length; i++) {
                    prompt = prompt.replaceAll(`{${field}[${i}]}`, promptData[field][i]?.trim());
                }
            } else {
                prompt = prompt.replaceAll(`{${field}}`, promptData[field]?.trim())
            }
        });

        // Get index of gpt(
        /*const gptIndex = prompt.indexOf("gpt(");
        if(gptIndex > -1) {
            // Use stack to find matching closing )
            var stack = [];
            var i = gptIndex;
            while(i < prompt.length) {
                if(prompt[i] === "(") {
                    stack.push("(");
                } else if(prompt[i] === ")") {
                    stack.pop();
                    if(stack.length === 0) break;
                }
                i++;
            }
            prompt = await elaborate(prompt, gptIndex + 4, i);
        }*/

        return prompt;
    }

    async function processVariablesInJson(json, promptData, append) {
        if (typeof json !== 'object' || json === null) {
            // json is not an object (it could be a string, number, etc.), so just process it
            return await processVariables(json, promptData, append);
        }

        // json is an object, so iterate over its properties
        let result = Array.isArray(json) ? [] : {};
        for (let key in json) {
            result[key] = await processVariablesInJson(json[key], promptData, append);
        }

        return result;
    }

    function processInlinePromptVariables(prompt, metadata) {
        const tags = parseTags(prompt);

        // Create a copy of metadata
        let newMetadata = {...metadata};

        if(metadata.tags) {
            metadata.tags.forEach((tag) => {
                if (!tags.includes(tag)) {
                    tags.push(tag);
                }
            });
        }

        // Remove tags from prompt
        prompt = prompt.replace(/\{tag:([^}]+)\}/g, '').trim();

        return {prompt, tags};
    }

    async function updatePromptList() {
        let prompts = [];

        var count = 0;

        var combinedPrompts = createAllCombinations(promptDefinition);

        const max = evaluateMaxPrompts();
        if(combinedPrompts.length > max) {
            combinedPrompts = combinedPrompts.sort(() => Math.random() - Math.random()).slice(0, max);
        }

        if(promptDefinition && promptDefinition.prompt && Array.isArray(promptDefinition.prompt)) {
            for (const prompt of promptDefinition.prompt) {
                for (const promptData of combinedPrompts) {
                    var p = await processVariables(prompt, {...promptData});
                    var metadata = {...promptDefinition.metadata};

                    const result = processInlinePromptVariables(p, metadata);
                    p = result.prompt;

                    metadata = await processVariablesInJson(metadata, promptData, false);
                    metadata.tags = await processVariablesInJson(result.tags, promptData, false);
                    try {
                        const result = {prompt: p, data: JSON.stringify(metadata), metadata: metadata ?? {}};
                        prompts.push(result);
                    } catch (e) {
                        console.log("Error parsing metadata: ", e, metadata);
                        prompts.push({prompt: p, data: metadata, metadata: {}});
                    }
                }
            }
        }

        if(prompts.length > max) {
            prompts = prompts.sort(() => Math.random() - Math.random()).slice(0, max);
        }

        setPromptList(prompts);
    }

    useEffect(() => {
        updatePromptList();
    }, [promptDefinition, dataSets, generated]);

    function onPromptDefinitionChanged(data) {
        console.log("Set prompt definition to ", data);
        setCookie("currentPrompt", data)
        setPromptDefinition(data);
    }

    function toggleAddDataset() {
        setAddDataSet(!addDataSet);
    }

    async function getDatasets() {
        const messageResponse = await fetchDatasets("prompt-data/list-datasets");
        // if error in response, show error
        if("error" in messageResponse) {
            toast.error(`Failed to fetch datasets.\n${messageResponse.error}`);
        } else {
            // merge default datasets with user datasets
            const dataSets = {...messageResponse, ...defaultDataSets};
            const groupedDataSets = {};
            groupedDataSets["Built In"] = {...defaultDataSets};
            Object.values(dataSets).forEach((d) => {
                const category = d.category ?? "Uncategorized";
                if(!groupedDataSets[category]) {
                    groupedDataSets[category] = {};
                }
                groupedDataSets[category][d.name] = d;
            });
            setDataSets(dataSets);
            setGroupedDataSets(groupedDataSets);
        }
        setDataSetsLoaded(true);
    }

    function onCreateDataSet(data) {
        toast(`Created new data set: ${data.name}`);
        setAddDataSet(false);
        getDatasets();
    }

    function updateActiveService(service) {
        setActiveService(service);
        setCookie("lastService", service);
    }

    function getMJPrompts() {
        updatePromptList();
        return promptList.map(p => `${p.data}::.001 ${p.prompt}`);
    }

    function getSDPrompts() {
        updatePromptList();
        return promptList.map(p => `${p.data}::.001 ${p.prompt}`);
    }

    async function generateName(promptData) {
        const setName = await getGeneratedSetName(promptData,
            "Creating a new name...",
            content => "Created name: " + content);
        if(setName) setCurrentSetName(setName);
    }

    function generateSetName() {
        let promptData = "";
        promptDefinition.prompt.forEach((p) => {
            // if the prompts length + promptData's length is < 1000
            if(p.length + promptData.length < 1000) {
                promptData += p;
            }
        });
        generateName(promptData);
    }

    function drawDataSet(dataSet) {
        return (
            <li key={dataSet}>
                <div className={"item"}>
                    <div  className={"create-card-flex-between-container"}>
                        <div className={"create-card-flex-between-items-grow"} onClick={() => {
                            setSelectedData(dataSets[dataSet]);
                            setEditData(true);
                        }}>
                        {dataSet}
                        </div>
                        <div>
                            <MdInsertChart onClick={() => {
                                const variable = dataSet.toLowerCase().replace(" ", "-");
                                toast.info(`Added ${variable} to Fields.`)
                                promptDefinition.fields[variable] = dataSet;
                                updatePromptDefinition(promptDefinition);
                            }} />
                            &nbsp;
                            <MdAddChart onClick={() => {
                                const variable = dataSet.toLowerCase().replace(" ", "-");
                                toast.info(`Added ${variable} to Appended Fields.`)
                                promptDefinition.appendedFields[variable] = dataSet;
                                updatePromptDefinition(promptDefinition);
                            }}/>
                        </div>
                    </div>
                </div>
            </li>
        );
        /*return (<CollapsibleCard title={dataSet}>
            <CollapsibleCardContent>
                <ul>
                    {dataSets && dataSets[dataSet].data.map((data) => <li>{
                        Array.isArray(data) ? data.join(", ") : data
                    }</li>)}
                </ul>
                <StyledButton label={"Edit"} onClick={() => {
                    setSelectedData(dataSets[dataSet]);
                    setEditData(true);
                }}>Select</StyledButton>
            </CollapsibleCardContent>
        </CollapsibleCard>);*/
    }

    function rightColumn() {

        return <>
            {user && <div className={"mb-5"}>
                <h3>Dream</h3>
                <SDJobSubmitter getPrompt={getSDPrompts}
                                onClickHeader={() => updateActiveService('sd')}
                                selected={activeService === "sd"}
                                setSubmitCallback={c => services['sd'] = c} />
                <MJJobSubmitter getPrompt={getMJPrompts}
                                onClickHeader={() => updateActiveService('mj')}
                                selected={activeService === "mj"}
                                setSubmitCallback={c => services['mj'] = c}
                                useApplyVersion={false}
                                useApplyAspectRatio={false} />
            </div>}

            <div className={"mb-5"}>
                <h3>Set</h3>
                <div className={"create-card-flex-between-container vertical-align"}>
                    <Form.Control value={currentSetName} onChange={(event) => setCurrentSetName(event.target.value)} />
                    <MdShuffle className={"md-edit-icon"} onClick={() => generateSetName()}/>
                </div>

                {dataSetsLoaded && currentSetName && <CollapsibleCard title={"Images"}>
                    <div style={{height: 400, overflow: "scroll"}}>
                        <ArtGallery fields={{"set": currentSetName}} showSearch={false} />
                    </div>
                </CollapsibleCard>}
            </div>

            <div className={"mb-5"}>
                <h3>Maximum Generated</h3>
                <div className={"create-card-flex-between-container vertical-align"}>
                    <Form.Control value={maxPrompts} onChange={(event) => setMaxPrompts(event.target.value)} />
                </div>
            </div>

            <div className={"create-card-flex-between-container"}>
                <h3>Data Sets</h3>
                <MdAdd  className={"mdicon"} onClick={() => {toggleAddDataset()}}/>
            </div>

            {addDataSet && <CreateDataSet onCreated={(data) => onCreateDataSet(data)} onCancel={() => setAddDataSet(false)} />}

            {Object.keys(groupedDataSets).map((groupName, index) => {
                const group = groupedDataSets[groupName];
                return <CollapsibleCard key={index} title={groupName}>
                    <CollapsibleCardContent>
                        <div style={{margin: -32}}>
                            <ul className='item-list'>
                        {Object.keys(group).map((dataSet) => drawDataSet(dataSet))}
                            </ul>
                        </div>
                    </CollapsibleCardContent>
                </CollapsibleCard>
            })}

            {savedPrompts && savedPrompts.length > 0 && <h3 className={"mt-5"}>Saved Prompts</h3>}
            {savedPrompts && savedPrompts.length > 0 && <CategorizedSet dataset={savedPrompts} onDrawData={dataSet => {
                return <div key={dataSet.id} className={"create-card-flex-between-container"}>
                    <span>{dataSet.name}</span>
                    <MdOpenWith className={"mdicon"} onClick={() => {
                        updatePromptDefinition(JSON.parse(dataSet.prompt));
                    } } />
                </div>;
            }} />}
        </>;
    }
    const variables = {
        category: {
            subcategory: ['variable1', 'variable2', 'variable3']
        },
        category2: ['variable4', 'variable5']
    };

    function getFieldDataKey(fieldType, field, value) {
        return `${promptDefinition[fieldType][field]}::${value}`;
    }

    function drawVariableSelector(fieldType, field) {
        return promptDefinition[fieldType] && promptDefinition[fieldType][field] && dataSets[promptDefinition[fieldType][field]] && dataSets[promptDefinition[fieldType][field]].data.map((d, index) => {
            return <div key={index}>
                <span>&nbsp;
                    {getFieldDataKey(fieldType, field, d) in disabledFields ?
                        <MdCheckBoxOutlineBlank onClick={() => {
                            let newDisabledFields = {...disabledFields};
                            delete newDisabledFields[getFieldDataKey(fieldType, field, d)];
                            setDisabledFields(newDisabledFields);
                        }}/>
                        :
                        <MdCheckBox onClick={() => {
                            let newDisabledFields = {...disabledFields};
                            newDisabledFields[getFieldDataKey(fieldType, field, d)] = true;
                            setDisabledFields(newDisabledFields);
                        }}/>
                    }
                    &nbsp;{d}</span>
            </div>;
        })
    }

    function updateTimestamp() {
        setCurrentTimestamp(new Date().toISOString());
        updatePromptDefinition(promptDefinition);
    }

    function drawFields(title, fieldType) {
        return (
            <CollapsibleCard title={title}>
                <CollapsibleCardContent>
                    <span className={"wrapped-pre"}>
                        {promptDefinition && promptDefinition[fieldType] && Object.keys(promptDefinition[fieldType]).map((field, index) => (
                            <div key={index}>
                                <CollapsibleCard>
                                    <CollapsibleCardTitle>
                                        <div className={"create-card-flex-between-container"}>
                                            <strong>{field}</strong>&nbsp;
                                            <div className={"create-card-flex-between-items-grow"} />
                                            <MdDelete className={"mdicon"} onClick={() => {
                                                delete promptDefinition[fieldType][field];
                                                updatePromptDefinition(promptDefinition);
                                            }} />
                                        </div>
                                    </CollapsibleCardTitle>
                                    <CollapsibleCardContent>
                                        {Array.isArray(promptDefinition[fieldType][field]) ?
                                            <div>
                                                {promptDefinition[fieldType][field].map((item, itemIndex) => (
                                                    <div key={itemIndex}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <MdDelete className={"mdicon"} onClick={() => {
                                                            promptDefinition[fieldType][field].splice(itemIndex, 1);
                                                            updatePromptDefinition(promptDefinition);
                                                        }} />
                                                        <span>{item}</span>
                                                    </div>))}
                                                <div className={"create-card-flex-between-container"}>
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    <FormControl
                                                        type="text"
                                                        value={newFieldRow}
                                                        style={{fontSize: 12, maxWidth: 200}}
                                                        onChange={(e) => {
                                                            setNewFieldRow(e.target.value);
                                                            updatePromptDefinition(promptDefinition);
                                                        }}
                                                    />
                                                    <MdAdd className={"mdicon"} onClick={() => {
                                                        promptDefinition[fieldType][field].push(newFieldRow);
                                                        setNewFieldRow("");
                                                        updatePromptDefinition(promptDefinition);
                                                    }} />
                                                    <div className={"create-card-flex-between-items-grow"} />
                                                </div>
                                            </div> :
                                            <div>

                                                <FormControl
                                                    type="text"
                                                    value={promptDefinition[fieldType][field]}
                                                    style={{fontSize: 12}}
                                                    onChange={(e) => {
                                                        promptDefinition[fieldType][field] = e.target.value;
                                                        updatePromptDefinition(promptDefinition);
                                                    }}
                                                />
                                                {drawVariableSelector(fieldType, field)}
                                            </div>}
                                    </CollapsibleCardContent>
                                </CollapsibleCard>
                            </div>))}

                    </span>
                    <div className={"create-card-flex-between-container"}>
                        <div className={"create-card-flex-between-items-grow"} />

                        <DropdownButton id="dropdown-basic-button" title="Select an item" title={newFieldType}>
                            <Dropdown.Item onClick={() => setNewFieldType("List")}>List</Dropdown.Item>
                            <Dropdown.Item onClick={() => setNewFieldType("Variable")}>Variable</Dropdown.Item>
                        </DropdownButton>

                        <FormControl type="text"
                                     style={{ maxWidth: '200px' }}
                                     value={newField} onChange={e => setNewField(e.target.value)} />
                        <MdAdd className={"mdicon"} onClick={() => {
                            promptDefinition[fieldType][newField] = newFieldType === "List" ? [] : "";
                            setNewField("");
                            updatePromptDefinition(promptDefinition);
                        }} />
                    </div>
                </CollapsibleCardContent>
            </CollapsibleCard>
        );
    }

    function content() {
        return (
        <>
            <div>

                <h3>
                    <span className={"create-card-flex-between-container"}>
                        <span>Prompt Definition</span>
                        <span className={"create-card-flex-between-items-grow"}/>
                        <span>&nbsp;
                            <MdContentCopy className={"mdicon"} onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(promptDefinition));
                                toast("Copied prompt definition to clipboard.", {type: "success"}); }} />
                            <MdContentPaste className={"mdicon"} onClick={() => {
                                navigator.clipboard.readText().then((text) => {
                                    try {
                                        onPromptDefinitionChanged(JSON.parse(text));
                                        toast("Pasted prompt definition from clipboard.", {type: "success"});
                                    } catch (e) {
                                        toast.error("Failed to parse clipboard contents as JSON.");
                                    }
                                });
                            }} />
                            <MdSave className={"mdicon"} onClick={() => {
                                setSavePrompt(promptDefinition);
                            }}/>
                    </span>
                </span>
            </h3>

                <CollapsibleCard title={"Prompts"} subtitle={promptDefinition && promptDefinition.prompt && promptDefinition.prompt[0]} >
                    <CollapsibleCardContent>
                {promptDefinition && promptDefinition.prompt && promptDefinition.prompt.map((prompt, index) =>
                <div key={index} className={"mb-3"}>
                    <h6>
                        Prompt {index + 1}
                        &nbsp;&nbsp;
                        <MdDelete className={"mdicon"} onClick={() => {
                            promptDefinition.prompt.splice(index, 1);
                            updatePromptDefinition(promptDefinition);
                        }} />
                    </h6>
                    <PromptTextArea value={prompt} onChange={v => {
                        promptDefinition.prompt[index] = v;
                        updatePromptDefinition(promptDefinition);
                    }} />
                </div>)}
                <div className={"create-card-flex-between-container"}>
                    <div className={"create-card-flex-between-items-grow"} />
                    <MdAdd className={"mdicon"} onClick={() => {
                        promptDefinition.prompt.push("");
                        updatePromptDefinition(promptDefinition);
                    }} />
                </div>
                    </CollapsibleCardContent>
                </CollapsibleCard>
            </div>

            {drawFields("Fields", "fields")}
            {drawFields("Appended Fields", "appendedFields")}

            <CollapsibleCard title={"Tags"}>
                <CollapsibleCardContent>
                    <span className={"wrapped-pre"}>
                        {Array.isArray(promptDefinition?.metadata?.tags)  && promptDefinition.metadata.tags.map((tag, index) => (<span key={index} className={"tag"} onClick={
                            () => {
                                promptDefinition.metadata.tags.splice(index, 1);
                                updatePromptDefinition(promptDefinition);
                            }
                        }>{tag}</span> ))}
                    </span>

                    <div className={"mt-5 create-card-flex-between-container"}>
                        <div className={"create-card-flex-between-items-grow"} />
                        <FormControl type="text"
                                     style={{ fontSize: '8px', maxWidth: '100px' }}
                                     value={newTag} onChange={e => setNewTag(e.target.value)} />
                        <MdAdd className={"mdicon"} onClick={() => {
                            promptDefinition.metadata.tags.push(newTag.trim());
                            setNewTag("");
                            updatePromptDefinition(promptDefinition);
                        }} />
                    </div>
                </CollapsibleCardContent>
            </CollapsibleCard>

            <LabelDataForm data={promptDefinition} setData={data => {
                updatePromptDefinition(data);
            }} />

            {dataSetsLoaded ? <div className={"mt-5"}>
                <h3>Generated Prompts [{promptList.length}]{promptList && <span style={{fontSize: 10}}>&nbsp; <MdRefresh onClick={() => updatePromptDefinition(promptDefinition)} />
                    <br/><Link to={"/set/" + promptList[0]?.metadata?.set}>{promptList[0]?.metadata?.set}</Link> <MdTimer onClick={() => updateTimestamp()}/>
                    </span>}</h3>

                {promptList?.map((prompt, index) => {
                    return <PromptDesignCard key={index} prompt={prompt} onPromptUpdated={(promt) => {
                        promptList[index] = prompt;
                        console.log("Updated prompt: ", prompt);
                        setPromptList(promptList);
                        redraw();
                    }} onSubmitQuery={submitQuery}/>;
                })}
            </div> : <div>
                Waiting for datasets to load...
            </div>}
        </>);
    }

    async function generatePrompt(promptValue) {

        var generation = toast("Generating prompts...", {type: "info"});
        // make the toast dismissable and stay open until we're done
        toast.update(generation, {autoClose: false, closeButton: true, closeOnClick: false, draggable: false, hideProgressBar: true, pauseOnHover: false, progress: undefined, type: "info"});
        setPromptOutput("");
        setGenerating(true);
        const messageResponse = await fetchGeneratedPrompt({
            agent: "prompt-gen",
            namespace: "aiart",
            prompt: promptValue});
        setGenerating(false);

        if("error" in messageResponse)
        {
            toast.update(generation, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "error", render: "Error: " + messageResponse.error});
            return;
        }

        setPromptOutput("```json\n" + messageResponse.content + "\n```");
        try {
            promptDefinition.prompt = JSON.parse(messageResponse.content, (key, value) => {
                if (typeof value === 'string' && value.endsWith(',')) {
                    return value.slice(0, -1);
                }
                return value;
            });
        } catch (e) {
            console.error(e);
        }
        console.log("Updated Prompt Definition: ", promptDefinition);
        updatePromptDefinition(promptDefinition);
        setCookie("currentPrompt", promptDefinition)
        if(!promptDefinition.metadata) promptDefinition.metadata = {};
        if(!promptDefinition.metadata.set) promptDefinition.metadata.set = "setName() :: timestamp()";
        setGenerated(generated + 1);
        toast.update(generation, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "success"});
        await generateSetName();
    }

    return (
    <PageHeader image={headerImage} title={"Prompt Design"}
                description={"A tool to design and generate prompts for AI generated content."}
                breadcrumb={[
                    ["Home", "/"],
                    ["Prompt Design", "/prompt-design"]
                ]} >
        <div className='row'>
            <div className='col-md-8 flex-fill'>
                {content()}
            </div>
            <div className='col-md-4'>
                <div className='right'>
                    {rightColumn()}
                </div>
            </div>
        </div>

        <FloatingForm label={"Generate Prompts"} outputValue={promptOutput} height={300} promptRows={8} closeOnSubmit={true} onFormSubmit={(value) => {
            generatePrompt(value);
        }} isProcessing={generating} processingLabel={"Generating..."}/>

        {selectedData && <EditDataSet id={selectedData?.id} setShowModal={handleEditData} showModal={editData} defaultData={selectedData} onDataUpdated={() => getDatasets()} />}
        <SavePrompt showModal={savePrompt} onCancel={() => setSavePrompt(undefined)} onSave={() => {
            setSavePrompt(undefined);
            fetchSavedPrompts();
        } } />
    </PageHeader>);
}

export default PromptDesign;