import React, {useEffect, useRef} from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/theme-tomorrow_night'; // Import the dark theme
import './custom_highlight'; // Import the custom highlighting mode

const PromptTextArea = ({ language, value, onChange }) => {
    const editorRef = useRef();

    useEffect(() => {
        const editor = editorRef.current.editor;
        editor.setOptions({
            maxLines: Infinity,
            minLines: 2,
            wrap: true,
        });
        editor.renderer.updateFull();
    }, []);

    return (
        <AceEditor
            mode="custom_highlight"
            theme="tomorrow_night"
            value={value}
            onChange={onChange}
            name="syntax-highlighted-text-area"
            editorProps={{ $blockScrolling: true }}
            width="100%"
            ref={editorRef}
        />
    );
};

export default PromptTextArea;