import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import StyledButton from "../styledbutton";

const PromptInput = ({onSubmitted}) => {
    const [text, setText] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setText(''); // Clear the textarea
        onSubmitted(text);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Prompt</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
            </Form.Group>
            <StyledButton variant="primary" type="submit" label={"Submit"}/>
        </Form>
    );
}

export default PromptInput;
